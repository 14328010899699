import React, { useState, useEffect, useRef, lazy } from "react"
import FlashMessage from '../shared/FlashMessage'

const authenticationComponents = {
  login:          'Login',
  forgotPassword: 'ForgotPassword',
  editPassword:   'EditPassword'
}

let AuthComponent = () => <div/>

export default function Index({ appProps, pageName, formPath, title, subtitle, rememberable, authBg, forgotPasswordPath, editPasswordType }) {
  const [renderKey, setRenderKey] = useState(0)

  const flashMessageRef = useRef(null)

  useEffect(() => {
    AuthComponent = lazy( () => import(`./${ authenticationComponents[pageName] }`) )
    setTimeout( () => setRenderKey(Math.random()), 100)
  }, [])

  return (
    <>
      <div className="d-flex flex-column flex-root" id="kt_app_root" style={{ height: '100%' }}>
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
              <div className="w-lg-500px p-10">
                <AuthComponent
                  formPath={ formPath }
                  title={ title }
                  subtitle={ subtitle }
                  rememberable={ rememberable }
                  forgotPasswordPath={ forgotPasswordPath }
                  editPasswordType={ editPasswordType }
                  appProps={{ flashMessage: flashMessageRef && flashMessageRef.current }}
                />
              </div>
            </div>

            <div className="w-lg-500px d-flex flex-stack px-10 mx-auto">
              <div className="d-flex fw-semibold text-primary fs-base gap-5">
                <a href="" target="_blank">{ Website.translations.terms }</a>
                <a href="" target="_blank">{ Website.translations.contact }</a>
              </div>
            </div>
          </div>

          <div  className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
                style={{ backgroundImage: `url("${authBg}")` }}>
            <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
              <a href="" className="mb-0 mb-lg-12">
                <img alt="Logo" src={ appProps.logoDark } className="h-60px h-lg-75px" />
              </a>
              <h1 className="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7">
                { Website.translations.promoTitle }
              </h1>
              <div className="d-none d-lg-block text-white fs-base text-center">
                { Website.translations.promoSubtitle } { Website.translations.promoBody }
              </div>
            </div>
          </div>
        </div>
      </div>

      <FlashMessage ref={ flashMessageRef }/>
    </>
  )
}
