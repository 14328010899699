import React from 'react'
import FormInput from '../shared/FormInput'
import StockItems from './StockItems'

export default function Seasons({ seasons, stockItems, updateStockItems, seasonalRates, handleSeasonalRateChange, stockType, itemsPath }) {
  const renderSeason = season => {
    const rate = seasonalRates.find(rate => rate.seasonId === season.id)
    return (
      <div className='card m-b-30' key={ season.id }>
        <div className="card-header border-0 pt-9">
          <div className="card-title m-0">
            <h3>{Website.translations.season}: { season.title }</h3>
          </div>
        </div>
        <div className="card-body">
          <FormInput
            label={ Website.translations.columns.price }
            value={ rate.price }
            onChange={ e => handleSeasonalRateChange(rate.id, season.id, e) }
          />
          <div className='m-t-30'>
            <StockItems
              stockItems={ stockItems }
              stockFilterFunc={ item => item.seasonId === season.id && !item._destroy }
              updateStockItems={ updateStockItems }
              hideHeading={ false }
              seasonId={ season.id }
              stockType={ stockType }
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div>
        <h2>{ Website.translations.seasonalRates }</h2>
        <div>{ Website.translations.seasonalRatesDescription }</div>
        <hr />

        { seasons.length > 0 && seasons.map(season => renderSeason(season)) }
        { seasons.length === 0 &&
          <a href={ itemsPath }>
            { Website.translations.createSeasonToContinue }
          </a>
        }
      </div>
    </>
  )
}
