function rangeNumbers(from, to) {
  let result = []
  for (let i = from; i <= to; i++) {
    result.push(i)
  }
  return result
}

export function getPaginationOptions(currentPage, totalPages) {
  if (totalPages <= 7 ) {
    let pages = []
    for (let i = 0; i < totalPages; i++) {
      pages.push(i + 1)
    }
    return pages
  } else { // if the returned value is < 0 then it is '...' and not a page
    if ([1, 2].includes(currentPage)) {
      return [...rangeNumbers(1, 4), -1, totalPages]

    } else if (currentPage === 3) {
      return [...rangeNumbers(1, 5), -1, totalPages]

    } else if (currentPage === 4) {
      return [...rangeNumbers(1, 6), -1, totalPages]

    } else if ([totalPages - 1, totalPages].includes(currentPage)) {
      return [1, -1, ...rangeNumbers(totalPages - 3, totalPages)]

    } else if (currentPage === (totalPages - 2)) {
      return [1, -1, ...rangeNumbers(currentPage - 2, totalPages)]

    } else if (currentPage === (totalPages - 3)) {
      return [1, -1, ...rangeNumbers(currentPage - 2, totalPages)]

    } else {
      return [1, -1, ...rangeNumbers(currentPage - 2, currentPage + 2), -2, totalPages]
    }
  }
}

export function generateId() {
  return String(Math.random()).substring(2, 12)
}
