import React, { useState } from 'react'
import DynamicEntityIndex from '../shared/DynamicEntityIndex'
import CreateButton from '../shared/CreateButton'
import SubmitButton from '../shared/SubmitButton'
import Modal from '../shared/Modal'
import MerchantSubaccountCreateForm from './MerchantSubaccountCreateForm'

export default function MerchantSubaccounts({
  appProps, subaccounts, initialTotalPages, itemsPath, deletePath, disableDelete,
  canCreate, canEdit, selectedColumns, keyDetails
}) {
  const [showModal, setShowModal] = useState(false)
  const [subaccountFields, setSubaccountFields] = useState({
    mid: '',
    email: '',
    internalName: ''
  })
  const [settings, setSettings] = useState(keyDetails.map(item => ({ key: item.key, value: item.input_type === 'select' ? Object.keys(item.input_options)[0] : ''})))

  const { flashMessage } = appProps

  const actions = [
    { url: item => `${itemsPath}/${item.mid}`, icon: 'fa-eye' }
  ]

  if (canEdit) {
    actions.push({ url: item => `${itemsPath}/${item.mid}/edit`, icon: 'fa-pencil'})
  }

  const handleFieldChange = (key, e) => {
    setSubaccountFields(prevState => ({
      ...prevState,
      [key]: e.target.value
    }))
  }

  const handleSettingChange = (key, e) => {
    setSettings(prevState => {
      const index = settings.findIndex(setting => setting.key === key)
      return [
        ...prevState.slice(0, index),
        { ...prevState[index], value: e.target.value },
        ...prevState.slice(index + 1)
      ]
    })
  }

  const handleSubmit = _e => {
    const { mid, internalName, email } = subaccountFields

    const fd = new FormData()
    fd.append('merchant_subaccount[mid]', mid)
    fd.append('merchant_subaccount[internal_name]', internalName)
    fd.append('superadmin_email', email)
    settings.forEach(setting => {
      fd.append(`settings[${setting.key}]`, setting.value)
    })

    Rails.ajax({
      type: 'POST',
      url: itemsPath,
      dataType: 'json',
      data: fd,
      success: res => {
        if (res.redirect_url) {
          location.href = res.redirect_url
        }
      },
      error: res => {
        flashMessage.show(res.error, 'error')
      }
    })
  }

  function renderSubaccount(subaccount, columns) {
    return (
      <>
      { columns.map( column => {
        const key = column.key
        return (
          <div key={ column.key } className='item flex-1'>
            <div>
              { subaccount[key] || '-' }
            </div>
          </div>
        )
      })}
    </>
    )
  }

  return (
    <>
      { showModal &&
        <Modal
          visible={ showModal }
          closeModal={() => setShowModal(false)}
          mode="large"
        >
          <MerchantSubaccountCreateForm
            subaccountFields={ subaccountFields }
            handleFieldChange={ handleFieldChange }
            keySubaccountDetails={ keyDetails }
            subaccountSettings={ settings }
            handleSettingChange={ handleSettingChange }
          />

          <div className='flex-box content-end m-t-30'>
            <SubmitButton
              value={ Website.translations.save }
              className="btn btn-primary"
              onSubmit={ handleSubmit }
            />
          </div>
        </Modal>
      }

      <CreateButton
        pageTitle={ Website.translations.merchantSubaccounts }
        breadcrumbs={ appProps.breadcrumbs }
        canCreate={ canCreate }
        setShowModal={ setShowModal }
      />

      <DynamicEntityIndex
        appProps={ appProps }
        initialItems={ subaccounts }
        initialTotalPages={ initialTotalPages }
        selectAll={ true }
        disableCustomization={ true }
        disableDelete={ disableDelete }
        entity={ "merchant_subaccounts" }
        tabs={ [] }
        tableHeadings={ selectedColumns }
        itemRenderer={ renderSubaccount }
        itemsPath={ itemsPath }
        deletePath={ deletePath }
        noResourcesTitle={ Website.translations.noMerchantSubaccounts }
        actions={ actions }
        columnTranslations={ Website.translations.columns }
      />
    </>
  )
}
