import React, { useState, useEffect } from 'react'

import LanguageSelect from './translations/LanguageSelect'
import LanguageOperationSelect from './translations/LanguageOperationSelect'
import AddTranslationLanguageModal from './translations/AddTranslationLanguageModal'
import RemoveTranslationLanguageModal from './translations/RemoveTranslationLanguageModal'

export default function LanguageHandler({
  languages, usedLanguages, setUsedLanguages, selectedLanguage,
  setSelectedLanguage, translatableContent, disableAdd, disableRemove,
  extraAddLanguageCallback, extraRemoveLanguageCallback
}) {
  const [modalOpen, setModalOpen] = useState('')
  const closeModal = () => setModalOpen('')

  useEffect(closeModal, [usedLanguages.length])

  function addLanguage(locale) {
    let _usedLanguages = [...usedLanguages]
    const newLanguage = languages.filter( l => l.code === locale )[0]

    if (newLanguage) {
      _usedLanguages.push(newLanguage)

      setUsedLanguages(_usedLanguages)
      setSelectedLanguage(newLanguage.code)

      if (translatableContent.length) {
        translatableContent.map( content => {
          let updatedItems = {...content.items}

          if (!updatedItems[locale]) {
            updatedItems[locale] = content.initialValues

          } else {
            if (content.isArray) {
              updatedItems[locale].forEach( (item, index) => {
                if (item._destroy) {
                  delete(updatedItems[locale][index]._destroy)
                }
              })

            } else if (updatedItems[locale]._destroy) {
              delete(updatedItems[locale]._destroy)
            }
          }

          content.setItems(updatedItems)
        })
      }
    }
  }

  function removeLanguage(locale) {
    let _usedLanguages = [...usedLanguages]
    _usedLanguages = _usedLanguages.filter( l => l.code !== locale )

    setUsedLanguages(_usedLanguages)
    setSelectedLanguage(_usedLanguages[0].code)

    if (translatableContent.length) {
      translatableContent.map( content => {
        let updatedItems = {...content.items}

        if (content.isArray && updatedItems[locale]) {
          let items = []
          updatedItems[locale].forEach( (item, index) => {
            if (item && (item.id || item.existing)) {
              items.push({ ...item, _destroy: true })
            }
          })

          if (items.length) {
            updatedItems[locale] = items
          } else {
            delete(updatedItems[locale])
          }

        } else {
          if (updatedItems[locale] && (updatedItems[locale].id || updatedItems[locale].existing)) {
            updatedItems[locale] = { ...updatedItems[locale], _destroy: true }

          } else {
            delete(updatedItems[locale])
          }
        }

        content.setItems(updatedItems)
      })
    }
  }

  function getRemainingLanguages() {
    let _usedLocales = usedLanguages.map( l => l.code )
    return languages.filter( l => _usedLocales.indexOf(l.code) < 0 )
  }

  return (
    <div className='flex-box flex-wrap m-b-30'>
      <LanguageSelect
        usedLanguages={ usedLanguages }
        onChange={ event => setSelectedLanguage(event.target.value) }
        currentValue={ selectedLanguage }
      />

      { (!disableAdd || !disableRemove) &&
        <LanguageOperationSelect
          handleSelectOperation={ modalToOpen => setModalOpen(modalToOpen) }
          showAdd={ getRemainingLanguages().length > 0 && !disableAdd }
          showRemove={ usedLanguages.length > 1 && !disableRemove }
        />
      }

      { modalOpen == 'addLanguageModalOpen' &&
        <AddTranslationLanguageModal
          languagePool={ getRemainingLanguages() }
          isOpen={ true }
          onClose={ closeModal }
          handleLanguageSelected={ (locale) => {
            addLanguage(locale)
            if(extraAddLanguageCallback) {
              extraAddLanguageCallback(locale)
            }
          }}
        />
      }

      { modalOpen == 'removeLanguageModalOpen' &&
        <RemoveTranslationLanguageModal
          selectedLanguage={ usedLanguages.find( language => language.code === selectedLanguage ) }
          isOpen={ true }
          onClose={ closeModal }
          handleLanguageSelected={ (locale) => {
            removeLanguage(locale)
            if(extraRemoveLanguageCallback) {
              extraRemoveLanguageCallback(locale)
            }
          }}
        />
      }
    </div>
  )
}
