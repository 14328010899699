import React, { useState, useEffect } from 'react'
import Dropzone from 'react-dropzone'
import GlobalLoader from './GlobalLoader'
import Modal from './Modal'
import Tabs from './Tabs'
import { generateId } from './utils'

const mimeTypes = {
  image: ['image/*'],
  audio: ['audio/mpeg'],
  video: ['video/mp4', 'video/quicktime'],
  pdf: ['application/pdf'],
  powerpoint: ['application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'],
  doc: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  excel: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  csv: ['text/csv'],
  zip: ['application/zip', 'application/octet-stream', 'application/x-zip-compressed', 'multipart/x-zip']
}

const mediaIcons = {
  audio: 'fa-light fa-file-audio',
  video: 'fa-light fa-file-video',
  pdf: 'fa-light fa-file-pdf',
  powerpoint: 'fa-light fa-file-powerpoint',
  doc: 'fa-light fa-file-word',
  excel: 'fa-light fa-file-excel',
  csv: 'fa-light fa-file-csv',
  zip: 'fa-light fa-file-zipper',
  other: 'fa-light fa-file'
}

const tabs = [
  { key: 'browse', title: Website.translations.media.files },
  { key: 'upload', title: Website.translations.media.upload }
]

export default function MediaGallery({
  mediaTypes, files, setFiles, onUploadMedia, extraMediaSettings, multiple,
  mediaPath, mediaUploader, chooseMediaTitle, mediaSourceId, disableAssign,
  disableRemove, triggerMediaModal, setTriggerMediaModal
}) {
  const [showModal, setShowModal] = useState(false)
  const [currentTab, setCurrentTab] = useState(tabs[0].key)
  const [selectedFiles, setSelectedFiles] = useState(files)
  const [mediaFiles, setMediaFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [previewFile, setPreviewFile] = useState(null)

  const disableEdit = !!files.find( file => !file.mediaId && !file.isExternal ) || disableAssign

  useEffect( () => {
    if (triggerMediaModal && setTriggerMediaModal) {
      setShowModal(true)
    } else if (setTriggerMediaModal) {
      setTriggerMediaModal(false)
    }
  }, [triggerMediaModal])

  useEffect( () => {
    setSelectedFiles(showModal ? files : [])

    if (showModal && mediaFiles.length === 0) {
      fetchMedia()
    }
  }, [showModal])

  function getNoMediaIcon() {
    if (mediaTypes.length === 1) {
      const type = mediaTypes[0]

      if (type === 'image') {
        return 'fa-image-slash'
      } else if (type === 'audio') {
        return 'fa-music-slash'
      } else if (type === 'video') {
        return 'fa-video-slash'
      } else if (type === 'pdf') {
        return 'fa-file-slash'
      } else if (type === 'zip') {
        return 'fa-file-slash'
      }
    }

    return 'fa-file-slash'
  }

  function fetchMedia(page = 1) {
    setLoading(true)

    Rails.ajax({
      type: 'GET',
      url: `${ mediaPath }?page=${ page }&types=${ JSON.stringify(mediaTypes) }`,
      dataType: 'json',
      success: result => {
        setMediaFiles([...mediaFiles, ...result.media])
        setCurrentPage(page)
        setTotalPages(result.totalPages)
      },
      error: result => {},
      complete: result => {
        setLoading(false)
      }
    })
  }

  function onDropFiles(droppedFiles) {
    droppedFiles.forEach( file => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const newFile = {
          ...(extraMediaSettings || {}),
          blob: file,
          source: reader.result,
          uid: generateId(),
          onUpload: onUploadMedia,
          mediaSourceId: mediaSourceId || null,
          mediaType: file.type,
          selected: true
        }

        setSelectedFiles( prevState => !!multiple ? [...prevState, newFile] : [newFile] )
        setCurrentTab('browse')
      }
    })
  }

  function mergedFiles() {
    let updatedFiles = [...selectedFiles]
    mediaFiles.forEach( file => {
      if (!updatedFiles.find( updatedFile => updatedFile.mediaId && updatedFile.mediaId === file.mediaId )) {
        updatedFiles.push(file)
      }
    })

    return updatedFiles
  }

  function selectMedia(file) {
    if (!file.id && !file.selected) {
      setSelectedFiles( prevState => !!multiple ? [...prevState, { ...file, selected: true }] : [{ ...file, selected: true }] )
    }

    setPreviewFile(file)
  }

  function deselectMedia(file) {
    setSelectedFiles( prevState => prevState.filter( mediaFile => (file.mediaId && file.mediaId !== mediaFile.mediaId) || (file.uid && file.uid !== mediaFile.uid) ) )
    setPreviewFile(null)
  }

  function getMediaType(type) {
    switch (true) {
      case /^.*image.*$/.test(type):
        return 'image'

      case /^.*audio.*$/.test(type):
        return 'audio'

      case /^.*video.*$/.test(type):
        return 'video'

      case /^.*csv.*$/.test(type):
        return 'csv'
    }

    switch (type) {
      case 'application/pdf':
        return 'pdf'

      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return 'powerpoint'

      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'doc'

      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'excel'

      case 'application/zip':
        return 'zip'

      default:
        return 'other'
    }
  }

  function loader() {
    return (
      <div className='spinner-wrapper' style={{ position: 'absolute', zIndex: 99997 }}>
        <div className='spinner'>
          <div className='double-bounce1 background-color-scheme'/>
          <div className='double-bounce2 background-color-scheme'/>
        </div>
      </div>
    )
  }

  function filePreview(file) {
    const mediaType = getMediaType(file.mediaType)

    if (mediaType === 'image') {
      return (
        <img src={ file.source } alt={ file.fileName }/>
      )
    }

    return (
      <div className='file-preview-wrapper flex-box flex-column content-space-around items-center'>
        <i className={ `${ mediaIcons[mediaType] } file-preview-type` }/>
        <div className='file-preview-name'>
          { file.fileName && `${ file.fileName.slice(0, 10) }${ file.fileName.length > 10 ? '...' : '' }` }
        </div>
      </div>
    )
  }

  function save() {
    setFiles(selectedFiles)
    closeModal()

    const filesToUpload = selectedFiles.filter( file => file.blob )
    if (filesToUpload.length) {
      mediaUploader.pushFilesToUpload(filesToUpload)
    }
  }

  function closeModal() {
    if (triggerMediaModal && setTriggerMediaModal) {
      setTriggerMediaModal(false)
    }
    setShowModal(false)
  }

  return (
    <div className='media-gallery flex-box items-center flex-wrap'>
      { triggerMediaModal === undefined &&
        <>
          { !multiple &&
            <div className='single-media'>
              { files.length > 0 && files[0].source ?
                <div className='media-preview flex-box content-center'>
                  { !files[0].mediaId && !files[0].isExternal && loader() }
                  { filePreview(files[0]) }
                </div>
              :
                <div className='no-media-wrapper flex-box content-center flex-column items-center'>
                  <div className='no-media-title'>
                    { Website.translations.media[mediaTypes.length === 1 ? `no_${ mediaTypes[0] }` : 'no_files'] || Website.translations.media.no_files }
                  </div>
                  <i className={ `no-media-icon fa-light ${ getNoMediaIcon() }` }/>
                </div>
              }

              { !disableEdit &&
                <div
                  className='btn btn-primary m-t-10 flex-box items-center'
                  onClick={ () => setShowModal(true) }
                >
                  { chooseMediaTitle || Website.translations.media.chooseMedia }
                  <i className='fa-light fa-plus m-l-5'/>
                </div>
              }
            </div>
          }

          { multiple &&
            <div className='flex-box flex-column'>
              <div>
                { chooseMediaTitle || Website.translations.media.chooseMedia }
              </div>

              <div className='multiple-media flex-box flex-wrap'>
                { files.length > 0 && files.map( file => {
                  if (file.source) {
                    return (
                      <div key={ file.mediaId || file.uid || generateId() } className='media-preview flex-box content-center'>
                        { !file.mediaId && !file.isExternal && loader() }
                        { filePreview(file) }
                      </div>
                    )
                  }
                })}

                { files.length === 0 &&
                  <div className='flex-box flex-column'>
                    <div className='no-media-wrapper flex-box content-center flex-column items-center'>
                      <div className='no-media-title'>
                        { Website.translations.media[mediaTypes.length === 1 ? `no_${ mediaTypes[0] }` : 'no_files'] || Website.translations.media.no_files }
                      </div>
                      <i className={ `no-media-icon fa-light ${ getNoMediaIcon() }` }/>
                    </div>

                    { !disableEdit &&
                      <div className='btn btn-primary m-t-10 flex-box items-center' style={{ alignSelf: 'flex-start' }} onClick={ () => setShowModal(true) }>
                        { Website.translations.media.assign }
                        <i className='fa-light fa-plus m-l-5'/>
                      </div>
                    }
                  </div>
                }

                { files.length > 0 && !disableEdit &&
                  <div className='media-preview'>
                    <div className='drop-item flex-box items-center content-center' onClick={ () => setShowModal(true) }/>
                  </div>
                }
              </div>
            </div>
          }
        </>
      }

      { showModal &&
        <Modal
          visible={ true }
          mode={ 'large' }
          closeModal={ closeModal }
          action={ save }
          actionText={ Website.translations.media.setSelected }
        >
          <Tabs
            tabs={ tabs }
            currentTab={ currentTab }
            setCurrentTab={ setCurrentTab }
          />

          <div className='flex-box tab-content'>
            <div className='gallery-content flex-box content-start flex-column'>
              { currentTab === 'browse' &&
                <>
                  <div className='media flex-box flex-wrap'>
                    { mergedFiles().map( file => {
                      const isSelected = !!(file.id || file.selected)

                      return (
                        <div
                          key={ file.mediaId || file.uid || generateId() }
                          className={ `media-preview ${ isSelected ? 'selected' : 'selectable' } flex-box content-center` }
                          onClick={ () => selectMedia(file) }
                        >
                          { isSelected &&
                            <i
                              className={ `select-icon ${ disableRemove ? '' : 'deletable' } background-color-scheme` }
                              onClick={ () => disableRemove ? {} : deselectMedia(file) }
                            />
                          }
                          <div style={{ 'pointerEvents': 'none' }}/>
                          { filePreview(file) }
                        </div>
                      )
                    })}
                  </div>

                  { currentPage < totalPages &&
                    <div className='flex-box items-center content-center'>
                      <button
                        type='button'
                        className='btn flat flex-box'
                        onClick={ () => fetchMedia(currentPage + 1) }
                      >
                        { Website.translations.media.loadMore }
                      </button>
                    </div>
                  }
                </>
              }

              { currentTab === 'upload' &&
                <div className='flex-box items-center content-center'>
                  <Dropzone
                    onDrop={ acceptedFiles => onDropFiles(acceptedFiles) }
                    accept={ mediaTypes.map( mediaType => mimeTypes[mediaType] ).flat() }
                    multiple={ !!multiple }
                  >
                    { ({ getRootProps, getInputProps }) => (
                      <div { ...getRootProps() } className='dropzone-inner flex-box items-center content-center'>
                        <input { ...getInputProps() } />
                        <i className='fa-light fa-rectangle-history-circle-plus'></i>
                      </div>
                    )}
                  </Dropzone>
                </div>
              }
            </div>

            { previewFile &&
              <div className='media-info-wrapper'>
                <div className='media-info flex-box flex-column'>
                  <div className='media-preview center flex-box'>
                    <div style={{ 'pointerEvents': 'none' }}/>
                    { filePreview(previewFile) }
                  </div>

                  <div className='filename m-b-10'>
                    { previewFile.fileName }
                  </div>

                  <div className='filesize m-b-10'>
                    { previewFile.size }
                  </div>

                  <div className='filesource-wrapper'>
                    <div className='filesource'>
                      { previewFile.source }
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </Modal>
      }

      { loading && <GlobalLoader/> }
    </div>
  )
}
