import React, { useState } from "react"

export default function Login({
  appProps, formPath, title, subtitle, rememberable, forgotPasswordPath
}) {
  const [email, setEmail]           = useState('')
  const [password, setPassword]     = useState('')
  const [rememberMe, setRememberMe] = useState(false)

  const { flashMessage } = appProps

  function handleSubmit() {
    let fd = new FormData()
    fd.append('email', email)
    fd.append('password', password)
    if (rememberMe) {
      fd.append('remember_me', rememberMe)
    }

    Rails.ajax({
      type: 'POST',
      url: formPath,
      dataType: 'json',
      data: fd,
      success: res => {
        if (res.redirect_url) {
          location.href = res.redirect_url
        }
      },
      error: res => {
        flashMessage.show(res.error, 'error')
      }
    })
  }

  return (
    <div id='kt_sign_in_form' className="form w-100">
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3">{ title }</h1>
        <div className="text-gray-500 fw-semibold fs-6">{ subtitle }</div>
      </div>
      <div className="fv-row mb-8">
        <label htmlFor="email">{ Website.translations.email }</label>
        <input type='email' className='form-control bg-transparent' onChange={ (e)=> setEmail(e.target.value) } />
      </div>
      <div className="fv-row mb-3">
        <label htmlFor="password">{ Website.translations.password }</label>
        <input type='password' className='form-control bg-transparent' placeholder={ Website.translations.password } onChange={ (e)=> setPassword(e.target.value) }/>
      </div>
      <div className="flex-root flex-center mt-10 mb-5">
        { rememberable &&
          <div className="field">
            <input type='checkbox' onChange={() => setRememberMe(prevState => !prevState)} />
            <label style={{ marginLeft: 5 }}>{ Website.translations.rememberMe }</label>
          </div>
        }
      </div>
      <div className="d-grid mb-10">
        <button type="submit" id="kt_sign_in_submit" className= "btn btn-primary" onClick={ ()=> handleSubmit() }>
          { Website.translations.signInButton }
        </button>
      </div>
      <div className="text-gray-500 text-center fw-semibold fs-6">
        <a href={ forgotPasswordPath } className="link-primary">{ Website.translations.forgotPassword }</a>
      </div>
    </div>
  )
}
