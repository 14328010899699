import React from 'react'
import CreateButton from '../shared/CreateButton'
import ReceiptDetails from './ReceiptDetails'

export default function Receipt({ appProps, receipt, regeneratePath }) {

  return (
    <>
      <CreateButton
        pageTitle={ `${ Website.translations.receipt } ${ receipt.uid }`}
        breadcrumbs={ appProps.breadcrumbs }
      />

      <ReceiptDetails
        appProps={ appProps }
        receipt={ receipt }
        regeneratePath={ regeneratePath }
      />
    </>
  )
}