import React from 'react'
import DynamicEntityIndex from '../shared/DynamicEntityIndex'
import CreateButton from '../shared/CreateButton'

const tabs = [
  { key: 'Order', title: Website.translations.orders },
  { key: 'CustomSpaceOrder', title: Website.translations.customSpaceOrders },
]

export default function Orders({
  appProps, orders, initialTotalPages, itemsPath, exportPath, deletePath, disableDelete, canEdit, selectedColumns, orderCount, showTabs, displayedTabs
}) {
  const tabs = displayedTabs

  const actions = [
    {
      url: item => {
        const adminShowPath = `/admin/${ appProps.currentLocale }/mids/${ item.subaccountMid }/orders/${ item.apiUid }`
        const letmeinShowPath = `/letmein/${ appProps.currentLocale }/meraccs/${ item.accountApiUid }/mids/${ item.subaccountMid }/orders/${ item.apiUid }`

        const showPath = appProps.merchantsPath ? letmeinShowPath : adminShowPath
        return showPath
      },
      icon: 'fa-eye'
    }
  ]

  function renderOrder(order, columns) {
    return (
      <>
      { columns.map( column => {
        const key = column.key
        let output
        if (key === 'total') {
          output = order[key]
        } else if (key === 'status') {
          output = Website.translations[order[key]]
        } else {
          output = order[key] || '-'
        }

        return (
          <div key={ key } className='item flex-1'>
            <div>
              { output }
            </div>
          </div>
        )
      })}
    </>
    )
  }

  return (
    <>
      <CreateButton
        pageTitle={ Website.translations.orders }
        breadcrumbs={ appProps.breadcrumbs }
        count={ orderCount }
      />

      <DynamicEntityIndex
        appProps={ appProps }
        initialItems={ orders }
        initialTotalPages={ initialTotalPages }
        selectAll={ true }
        disableCustomization={ true }
        disableDelete={ disableDelete }
        entity={ "orders" }
        tabs={ showTabs ? tabs : [] }
        tableHeadings={ selectedColumns }
        itemRenderer={ renderOrder }
        exportPath={ exportPath }
        itemsPath={ itemsPath }
        deletePath={ deletePath }
        noResourcesTitle={ Website.translations.noOrders }
        actions={ actions }
        columnTranslations={ Website.translations.columns }
        isExportable={ true }
        enableDateFilter={ true }
      />
    </>
  )
}
