import React from 'react'

import SubmitButton from './SubmitButton'
import GlobalLoader from './GlobalLoader'

export default class SaveFixedButton extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showSaveButton: false,
      dataSaved: false,
      saving: false,
      errorsInSaving: null
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.dataSaved && this.state.dataSaved !== prevState.dataSaved) {
      setTimeout(() => {
        this.setState({showSaveButton: false, dataSaved: false, saving: false})
      }, 2000)
    }
  }

  getSaveableStatus() {
    return this.state.showSaveButton
  }

  setSavingStatus(status = true) {
    this.setState({ saving: status })
  }

  setSuccessStatus() {
    this.setState({showSaveButton: false, dataSaved: true, saving: false})
  }

  setErrorStatusWithMessage(message) {
    this.setState({saving: false, errorsInSaving: message})
    setTimeout( () => {
      this.setState({saving: false, errorsInSaving: null})
    }, 4000)
  }

  setSaveableStatus() {
    this.setState({showSaveButton: true, errorsInSaving: null})
  }

  render () {
  	const { entity, onSave } = this.props

    return (
      <React.Fragment>
        { this.state.showSaveButton &&
          <div className='global-save-button-wrapper'>
            <div>{Website.translations.unsavedChanges}</div>
            { this.state.saving ?
                <div>
                  { Website.translations.saving }
                </div>
              :
              <SubmitButton
                value={Website.translations.save}
                className='btn btn-dark'
                onSubmit={onSave}
              />
            }
          </div>
        }

        { this.state.saving &&
          <GlobalLoader/>
        }

        {this.state.dataSaved &&
          <div className='global-save-button-wrapper saved'>
            {Website.translations.saved}
          </div>
        }

        {this.state.errorsInSaving &&
          <div className='global-save-button-wrapper saving-error'>
            <div>{`${entity} ${Website.translations.couldNotBeSaved}:`}</div>&nbsp;
            <div>{this.state.errorsInSaving}</div>
          </div>
        }
      </React.Fragment>
    )
  }
}
