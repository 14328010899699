import React, { useState } from 'react'
import DynamicEntityIndex from '../shared/DynamicEntityIndex'
import Modal from '../shared/Modal'
import LanguageHandler from '../shared/LanguageHandler'
import FormInput from '../shared/FormInput'
import CreateButton from '../shared/CreateButton'

export default function Tickets({
  appProps, tickets, initialTotalPages, itemsPath, exportPath, deletePath,
  disableDelete, canCreate, canEdit, selectedColumns, languages, stockType, spaces
}) {
  const [showModal, setShowModal]               = useState(false)
  const [usedLanguages, setUsedLanguages]       = useState([languages.find(language => language.code === appProps.currentLocale )])
  const [selectedLanguage, setSelectedLanguage] = useState(usedLanguages[0].code)
  const [translations, setTranslations]         = useState({ [selectedLanguage]: { title: '' }})
  const [selectedSpace, setSelectedSpace]       = useState(spaces[0])

  const { flashMessage } = appProps

  const actions = []
  if (canEdit) {
    actions.push({ url: item => `${itemsPath}/${item.apiUid}/edit`, icon: 'fa-pencil' })
  }

  const handleTitleChange = e => {
    const newTranslation = { [selectedLanguage]: { title: e.target.value } }
    setTranslations(prevState => ({ ...prevState, ...newTranslation }))
  }

  const extraRemoveLanguageCallback = locale => {
    setTranslations(prevState => {
      const nextState = { ...prevState }
      delete nextState[locale]
      return nextState
    })
  }

  const handleSubmit = e => {
    e.preventDefault()

    const fd = new FormData()
    Object.keys(translations).forEach((locale, index) => {
      fd.append(`ticket[translations_attributes][${ index }][title]`, translations[locale].title || '')
      fd.append(`ticket[translations_attributes][${ index }][locale]`, locale)
    })

    if (stockType == 'Stock::CustomSpace') {
      fd.append('ticket[space_id]', selectedSpace.id)
    }

    Rails.ajax({
      type: 'POST',
      url: itemsPath,
      dataType: 'json',
      data: fd,
      success: res => {
        if (res.redirect_url) {
          location.href = res.redirect_url
        }
      },
      error: res => {
        flashMessage.show(res.error, 'error')
      }
    })
  }

  function renderTicket(ticket, columns) {
    return (
      <>
        { columns.map( column => {
          const key = column.key
          if (column.key === 'missingLocales') {
              return (
                <div key={ column.label } className='item flex-1'>
                  <div className='flex-box'>
                    { ticket.usedLocales.length > 0 ? ticket.usedLocales.join(', ') : '-' }
                    { ticket.missingLocales.length > 0 &&
                      <div className='tooltip-button'>
                        <i className='fa-light fa-circle-exclamation'/>
                        <div className='tooltip'>
                          { Website.translations.missingLocales }: { ticket.missingLocales.join(', ') }
                        </div>
                      </div>
                    }
                  </div>
                </div>
              )

          } else {
            return (
              <div key={ column.label } className='item flex-1'>
                { ticket[key] || '-' }
              </div>
            )
          }
        })}
    </>
    )
  }

  return (
    <>
      { showModal &&
        <Modal
          visible={ showModal }
          closeModal={ () => setShowModal(false) }
          mode={ `${ stockType === 'Stock::CustomSpace' ? 'medium' : 'small' }` }
        >
          <LanguageHandler
            languages={ languages }
            usedLanguages={ usedLanguages }
            setUsedLanguages={ setUsedLanguages }
            selectedLanguage={ selectedLanguage }
            setSelectedLanguage={ setSelectedLanguage }
            translatableContent={ [] }
            extraRemoveLanguageCallback={ extraRemoveLanguageCallback }
          />
          <form onSubmit={handleSubmit}>
            <FormInput
              label={ Website.translations.columns.title }
              value={translations[selectedLanguage]?.title || ''}
              onChange={handleTitleChange}
            />

            { stockType === 'Stock::CustomSpace' &&
              <FormInput
                classes='m-b-20'
                label={ Website.translations.space }
                value={ selectedSpace.id }
                onChange={ e => setSelectedSpace(spaces.find(space => space.id == e.target.value)) }
                type='select'
                multiple={ false }
                selectWithDefault={ false }
                selectEntities={ spaces }
                keyAttr='id'
                valueAttr='id'
                outputAttr='title'
              />
            }

            <div className='flex-box content-end'>
              <input type="submit" className='btn btn-primary' value={ Website.translations.save } />
            </div>
          </form>
        </Modal>
      }

      <CreateButton
        pageTitle={ Website.translations.tickets }
        breadcrumbs={ appProps.breadcrumbs }
        count={ tickets.length }
        canCreate={ canCreate }
        setShowModal={ setShowModal }
      />
      <DynamicEntityIndex
        appProps={ appProps }
        initialItems={ tickets }
        initialTotalPages={ initialTotalPages }
        selectAll={ true }
        disableCustomization={ true }
        disableDelete={ disableDelete }
        entity={ "tickets" }
        tabs={ [] }
        tableHeadings={ selectedColumns }
        itemRenderer={ renderTicket }
        exportPath={ exportPath }
        itemsPath={ itemsPath }
        deletePath={ deletePath }
        noResourcesTitle={ Website.translations.noTickets }
        actions={ actions }
        columnTranslations={ Website.translations.columns }
        isExportable={ true }
      />
    </>
  )
}
