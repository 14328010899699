import React from "react"

export default function Breadcrumbs({ breadcrumbs }) {
  return (
    <>
    { breadcrumbs?.length > 0 &&
      <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
        { breadcrumbs.map((breadcrumb, index) => (
          <React.Fragment key={index}>
              { 
                (index !== breadcrumbs.length - 1) 
                  ? <>
                      <li className="breadcrumb-item text-muted">
                        <a href={ breadcrumb.url } className="text-muted text-hover-primary">
                          { breadcrumb.title }
                        </a>
                      </li>
                      <li className="breadcrumb-item">
                        <span className="bullet bg-gray-400 w-5px h-2px"></span>
                      </li>
                    </>
                  : <li className="breadcrumb-item">
                      { breadcrumb.title }
                    </li>
              }
          </React.Fragment>
        ))}
      </ul>
    }
    </>
  )
}  