import React from "react"

export default class SubmitButton extends React.Component {
  render () {
  	const { type, value, disabled, className, onSubmit } = this.props

    return (
      <>
        <button
          type={ type || "button" }
          disabled={ disabled }
          className={ className }
          onClick={ onSubmit }
        >
          { value }
        </button>
      </>
    )
  }
}