import React, { useState } from 'react'

export default function ReceiptDetails({ appProps, receipt, regeneratePath }){
  const [currentReceipt, setCurrentReceipt] = useState(receipt)

  const regenerateReceipt = _e => {
    const uid = currentReceipt.uid

    const fd = new FormData()
    fd.append('uid', uid)

    Rails.ajax({
      type: 'POST',
      url: regeneratePath,
      dataType: 'json',
      data: fd,
      success: res => { 
        setCurrentReceipt(res.receipt)
      }
    })
  }

  return (
    <div>
      <div className='card m-b-30'>
        <div className="card-header border-0">
          <div className="flex-box items-center content-space-between" style={{ width: '100%' }}>
            <div className="card-title m-0">
              { currentReceipt.entityInvoiceId === null ? Website.translations.receiptDetails : Website.translations.creditReceiptDetails }
            </div>
            <div>
              <a className='btn btn-primary' href={ currentReceipt.iviewUrl } target="_blank" rel="noopener">
                { Website.translations.iview }
              </a>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="flex-box flex-column">
            <div className="flex-box items-center content-space-between fw-bold">
              <div className="flex-1">{ Website.translations.columns.entityNumber }</div>
              <div className="flex-1">{ Website.translations.columns.seriesNumber }</div>
            </div>
            <div className="flex-box items-center content-space-between m-b-10">
              <div className="flex-1">{ currentReceipt.entityNumber }</div>
              <div className="flex-1">{ currentReceipt.seriesNumber }</div>
            </div>
            <div className="flex-box items-center content-space-between fw-bold">
              <div className="flex-1">{ Website.translations.columns.issueDate }</div>
              <div className="flex-1">{ Website.translations.columns.invoiceType }</div>
            </div>
            <div className="flex-box items-center content-space-between m-b-10">
              <div className="flex-1">{ currentReceipt.issueDate }</div>
              <div className="flex-1">{ currentReceipt.invoiceType }</div>
            </div>
            <div className="flex-box items-center content-space-between fw-bold">
              <div className="flex-1">{ Website.translations.columns.totalPrice }</div>
              <div className="flex-1">{ Website.translations.icode }</div>
            </div>
            <div className="flex-box items-center content-space-between m-b-10">
              <div className="flex-1">{ currentReceipt.total }</div>
              <div className="flex-1">{ currentReceipt.icode }</div>
            </div>
            <div className="flex-box items-center content-space-between fw-bold">
              <div className="flex-1">{ Website.translations.mark }</div>
              <div className="flex-1">{ Website.translations.authenticationCode }</div>
            </div>
            <div className="flex-box items-center content-space-between m-b-10">
              <div className="flex-1">{ currentReceipt.mark }</div>
              <div className="flex-1">{ currentReceipt.authenticationCode }</div>
            </div>
          </div>
        </div>
      </div>

      { currentReceipt.hasError && 
        <div className='card m-b-30'>
          <div className="card-header border-0">
            <div className="flex-box items-center content-space-between" style={{ width: '100%' }}>
              <div className="card-title m-0">
                { Website.translations.receiptErrors }
              </div>
              <div>
                <button 
                  className='btn btn-primary'
                  onClick={ regenerateReceipt }
                >
                  { Website.translations.regenerateReceipt }
                </button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="flex-box flex-column">
              <div className="flex-box items-center content-space-between fw-bold">
                <div className="flex-1">{ Website.translations.errorCode }</div>
                <div className="flex-1">{ Website.translations.errorMessage }</div>
              </div>
              <div className="flex-box items-center content-space-between">
                <div className="flex-1">{ currentReceipt.errorCode }</div>
                <div className="flex-1">{ currentReceipt.errorMessage }</div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}