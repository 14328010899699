import React from 'react'
import { MenuItem, Select } from '@material-ui/core'

export default function LanguageOperationSelect({ handleSelectOperation, showAdd, showRemove }) {
  return (
    <div className='input-field'>
      <Select
        MenuProps={{ style: { zIndex: 99999 } }}
        onChange={ event => handleSelectOperation(event.target.value) }
        value='empty'
      >
        <MenuItem value='empty'>
          <i className='fa-solid fa-gear m-r-5'/>
          { Website.translations.languages }
        </MenuItem>

        { showAdd &&
          <MenuItem value='addLanguageModalOpen'>
            { Website.translations.addLanguage }
          </MenuItem>
        }

        { showRemove &&
          <MenuItem value='removeLanguageModalOpen'>
            { Website.translations.removeLanguage }
          </MenuItem>
        }
      </Select>
    </div>
  )
}