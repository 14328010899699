import React, { useState } from 'react'
import Modal from '../Modal'
import { Checkbox } from '@material-ui/core'

export default function RemoveTranslationLanguageModal({
  selectedLanguage, isOpen, onClose, handleLanguageSelected
}){
  const [consent, setConsent] = useState(false)

  return(
    <Modal
      mode='small'
      visible={ isOpen }
      closeModal={ onClose }
      cancelAction={ onClose }
      cancelActionText={ Website.translations.close }
      action={ () => handleLanguageSelected(selectedLanguage.code) }
      actionText={ Website.translations.removeAction }
      disableAction={ !consent || !selectedLanguage }
    >
      <div className='flex-box'>
        { selectedLanguage.presentation }
      </div>

      <div>
        <Checkbox checked={ consent } onClick={ () => setConsent(!consent) }/>
        { Website.translations.languageConfirmDelete }
      </div>
    </Modal>
  )
}