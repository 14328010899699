import React, { useState } from 'react'
import Modal from '../shared/Modal'
import FormInput from '../shared/FormInput'

export default function Tags({ tags, usedTags, updateTags, addNewTag }) {
  const [showModal, setShowModal] = useState(false)
  const [title, setTitle]         = useState('')

  const handleCreateTag = () => {
    setShowModal(false)
    const newTagUid = tags.reduce((acc, curr) => curr.apiUid < 0 ? acc - 1 : acc, -1)
    const newTag = {
      apiUid: newTagUid,
      title: title
    }

    addNewTag(newTag)
    setTitle('')
  }

  return (
    <>
      { showModal &&
        <Modal
          visible={ showModal }
          closeModal={ () => setShowModal(false) }
          mode="medium"
        >
          <h3>{ Website.translations.createTag }</h3>
          
          <div className='m-t-40 m-b-40'>
            <FormInput
              label={ Website.translations.columns.title }
              value={ title }
              onChange={ e => setTitle(e.target.value) }
            />
          </div>
          <div className='flex-box items-center content-end'>
            <button className="btn btn-primary" onClick={ handleCreateTag }>
              { Website.translations.save }
            </button>
          </div>
        </Modal>
      }

      <div className='flex-box items-center content-space-between m-b-30'>
        <h3>{ Website.translations.tags }</h3>
        <button className="btn btn-primary" onClick={ () => setShowModal(true) } >
          { Website.translations.createNewTag }
        </button>
      </div>

      { tags.length > 0 &&
        <div className='card'>
            <FormInput
              label={ Website.translations.tags }
              value={ usedTags }
              onChange={ updateTags }
              type='select'
              multiple={ true }
              selectEntities={ tags }
              keyAttr='apiUid'
              valueAttr='apiUid'
              outputAttr="title"
            />
        </div>
      }
    </>
  )
}
