import React, { useState } from 'react'
import DynamicEntityIndex from '../shared/DynamicEntityIndex'
import Modal from '../shared/Modal'
import FormInput from '../shared/FormInput'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers'

export default function StockItems({ appProps, stockItems, stockFilterFunc, updateStockItems, hideHeading, seasonId, stockType }) {
  const [showEditModal, setShowEditModal]     = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [itemToBeDeleted, setItemToBeDeleted] = useState(null)
  const [fields, setFields]                   = useState({
    id:       null,
    stockable: {
      id: null,
      slotFrom: moment('2000-01-01 12:00','YYYY-MM-DD h:mm a'),
      slotTo:   moment('2000-01-01 12:00','YYYY-MM-DD h:mm a'),
      quantity: 0,
      type: null
    },
    _destroy: null
  })

  const timeSlotColumns = [
    {
        "key":   "quantity",
        "label": "quantity",
        "type":  "string",
    },
    {
        "key":   "slotFrom",
        "label": "slotFrom",
        "type":  "string",
    },
    {
        "key":   "slotTo",
        "label": "slotTo",
        "type":  "string",
    }
  ]

  const simpleQuantityColumns = [
    {
      "key":   "quantity",
      "label": "quantity",
      "type":  "string",
    },
  ]

  const tableHeadings = stockType === 'Stock::TimeSlot' ? timeSlotColumns : simpleQuantityColumns

  const actions = [
    { onClick: editActionOnClick,   icon: 'fa-pencil' },
    { onClick: deleteActionOnClick, icon: 'fa-trash' }
  ]

  function editActionOnClick(e, item) {
    const { stockable } = item
    e.preventDefault()

    setFields({
      id: item.id,
      stockable: stockable
    })
    setShowEditModal(true)
  }

  function deleteActionOnClick(e, item) {
    e.preventDefault()

    setShowDeleteModal(true)
    setItemToBeDeleted(item)
  }

  const handleDelete = _e => {
    const { id, stockable } = itemToBeDeleted

    const index = stockItems.findIndex(item => item.id === id)
    updateStockItems([
      ...stockItems.slice(0, index),
      {
        id: id,
        stockable: stockable,
        _destroy: true,
        seasonId: seasonId
      },
      ...stockItems.slice(index + 1)
    ])
    setFields({
      id: null,
      stockable: {
        id: null,
        slotFrom: moment('2000-01-01 12:00','YYYY-MM-DD h:mm a'),
        slotTo: moment('2000-01-01 12:00','YYYY-MM-DD h:mm a'),
        quantity: 0,
        type: null
      },
      _destroy: null
    })
    setShowDeleteModal(false)
  }

  function renderStockItem(item, columns) {
    return (
      <>
      { columns.map( column => {
        const key = column.key
        const output = ['slotFrom', 'slotTo'].includes(key) ? moment(item.stockable[key]).utc().format('h:mm a') : item.stockable[key]
        return (
          <div key={ key } className='item flex-1'>
            { output }
          </div>
        )
      })}
      </>
    )
  }

  const handleSave = () => {
    const { id, stockable, _destroy } = fields
    const { slotFrom, slotTo, quantity, type } = stockable
    if (id) {
      const index = stockItems.findIndex(item => item.id === id)
      updateStockItems([
        ...stockItems.slice(0, index),
        {
          id: id,
          stockable: {
            id: stockable.id,
            slotFrom: slotFrom,
            slotTo: slotTo,
            quantity: quantity,
            type: type
          },
          seasonId: seasonId,
          _destroy: _destroy
        },
        ...stockItems.slice(index + 1)
      ])
    } else {
      updateStockItems([
        ...stockItems,
        {
          id: stockItems.reduce((acc, curr) => curr.id <= acc ? curr.id - 1 : acc, -1),
          stockable: {
            slotFrom: slotFrom,
            slotTo: slotTo,
            quantity: quantity,
            type: stockType
          },
          seasonId: seasonId
        }
      ])
    }

    setShowEditModal(false)
    setFields({
      id: null,
      stockable: {
        id: null,
        slotFrom: moment('2000-01-01 12:00','YYYY-MM-DD h:mm a'),
        slotTo: moment('2000-01-01 12:00','YYYY-MM-DD h:mm a'),
        quantity: 0,
        type: null
      },
      _destroy: null
    })
  }

  const handleQuantityChange = e => {
    const { value } = e.target
    if (/^\d*$/.test(value)) {
      setFields(prevState => ({ ...prevState, stockable: { ...prevState.stockable, quantity: +value } }))
    }
  }

  const renderDeleteModal = () => (
    <Modal
      visible={ showDeleteModal }
      closeModal={() => setShowDeleteModal(false)}
      mode="large"
    >
      <div>
        <strong>
          { Website.translations.areYouSure }
        </strong>
      </div>
      <div>
        <button className='btn' onClick={() => setShowDeleteModal(false)} >
          { Website.translations.goBack}
        </button>
        <button className='btn btn-primary' onClick={ handleDelete } >
          { Website.translations.continue}
        </button>
      </div>
    </Modal>
  )

  const renderEditModal = () => (
    <Modal
      visible={ showEditModal }
      closeModal={ () => setShowEditModal(false) }
      mode="medium"
    >
      { stockType === 'Stock::TimeSlot' &&
        <div className='flex-box flex-column'>
          <label className='fs-6 fw-semibold form-label mt-3'>
            { Website.translations.timeSlot }
          </label>
          <div>
            <MuiPickersUtilsProvider
              utils={ MomentUtils }
              locale={ 'en' }
            >
              <TimePicker
                label={ Website.translations.from }
                timezone="Europe/Athens"
                value={ moment(fields.stockable.slotFrom).utc() }
                onChange={ time => setFields(prevState => ({ ...prevState, stockable: { ...prevState.stockable, slotFrom: time } }))}
              />
              <TimePicker
                label={ Website.translations.to }
                timezone="Europe/Athens"
                value={ moment(fields.stockable.slotTo).utc() }
                onChange={ time => setFields(prevState => ({ ...prevState, stockable: { ...prevState.stockable, slotTo: time } }))}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
      }
      <div className={ `${ stockType === 'Stock::TimeSlot' ? 'm-t-40' : '' } m-b-40` }>
        <FormInput
          label={ Website.translations.columns.quantity }
          value={ fields.stockable.quantity }
          onChange={ handleQuantityChange }
        />
      </div>
      <div className='flex-box items-center content-end'>
        { (stockType !== 'Stock::TimeSlot' || (fields.stockable.slotFrom && fields.stockable.slotTo)) &&
          <button className="btn btn-primary" onClick={ handleSave }>
            { Website.translations.ok }
          </button>
        }
      </div>
    </Modal>
  )

  return (
    <>
      { showDeleteModal && renderDeleteModal() }
      { showEditModal && renderEditModal() }

      <div className='flex-box items-center content-space-between m-b-30'>
        { !hideHeading &&
           <h3>{ Website.translations.stock }</h3>
        }
        { (stockType === 'Stock::TimeSlot' || stockItems.filter(stockFilterFunc).length === 0) &&
          <button className="btn btn-primary" onClick={ () => setShowEditModal(true) } >
            { Website.translations.createStockItem }
          </button>
        }
      </div>

      <DynamicEntityIndex
        appProps={ appProps }
        initialItems={ stockItems.filter(stockFilterFunc) }
        initialTotalPages={ 1 }
        disableDelete={ true }
        disableCustomization={ true }
        disableSearch={ true }
        tabs={ [] }
        tableHeadings={ tableHeadings }
        itemRenderer={ renderStockItem }
        noResourcesTitle={ Website.translations.noStockItems }
        actions={ actions }
        columnTranslations={ Website.translations.columns }
      />
    </>
  )
}
