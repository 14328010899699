import React, { useEffect } from 'react'

import moment from 'moment'

export default function CustomSpaceTokenizedShow({
  appProps, order, merchantLogo, additionalInformation, showOnReceipt, additionalPrintingInformation
}) {
  const { lineItems } = order

  const printOrder = () => {
    window.print()
    return false
  }

  useEffect(() => {
    setTimeout(() => printOrder(), 1000)
  }, [])

  const additionalInfo = () => {
    if (additionalInformation && additionalInformation[0] && showOnReceipt){
      return additionalInformation.find(t => t.locale === appProps.currentLocale)?.title
    }

    return null
  }

  const additionalPrintingInfo = () => {
    if (additionalPrintingInformation){
      return additionalPrintingInformation.find(t => t.locale === appProps.currentLocale)?.title
    }

    return null
  }

  const renderQrCode = qrCode => (
    <div className="flex-box flex-column">
      <div>
        <img style={{ width: '100%', height: 100, objectFit: 'contain' }} src={ `data:image/png;base64,${ qrCode.title }` } />
      </div>
      <div style={{ textAlign: 'center' }}>
        { qrCode.readableBarcode }
      </div>
    </div>
  )

  const renderLineItems = () => (
    lineItems.map((lineItem, index) => {
      return (
        <div
          className='flex-box flex-column simple-ticket-wrapper'
          key={ index }
        >
          { merchantLogo &&
            <>
              <div className='ticket-logotype'>
                <img src={ merchantLogo } />
              </div>
              {
                additionalInfo() &&
                <div className='ticket-information-item'>
                  { additionalInfo() }
                </div>
              }
            </>
          }

          <div className='ticket-title'>
            { lineItem.sellableTitle }
          </div>

          <div className="flex-box flex-column ticket-information">
            { (lineItem.selectedTimeFrom && lineItem.selectedTimeTo)
              ? <div className='ticket-information-item'>
                  { `${ moment(lineItem.selectedDate).format("DD/MM/YYYY") }, ${ lineItem.selectedTimeFrom } - ${ lineItem.selectedTimeTo }` }
                </div>
              : <div className='ticket-information-item'>
                  { `${ moment(lineItem.selectedDate).format("DD/MM/YYYY") }` }
                </div>
            }

            <div className='ticket-information-item'>
              { lineItem.optionTitle || Website.translations.regular }
            </div>

            <div className='ticket-information-item flex-box content-center items-center' style={{ gap: '5px' }}>
              <div>{`${Website.translations.row}: ${lineItem.seatDetails.row + 1} ${Website.translations.seat}: ${lineItem.seatDetails.seat + 1} | ${lineItem.zoneTitle}`}</div>
            </div>

            <div className='ticket-information-item'>
              { lineItem.unitPrice }
            </div>

            <div className='ticket-information-item'>
              { `${ Website.translations.columns.number }: ${ order.number }` }
            </div>

            { lineItem.qrCode &&
              renderQrCode(lineItem.qrCode)
            }
          </div>

          { additionalPrintingInfo() &&
            <div className='ticket-information-item' style={{ marginTop: 30, fontSize: 17 }}>
              { additionalPrintingInfo() }
            </div>
          }
        </div>
      )
    })
  )

  return (
    <div className='tokenized-link-wrapper flex-box flex-column items-center'>
      <div className='ticket-container flex-box flex-column items-center flex-1'>
        <div className='ticket-screen-title flex-box flex-column items-center'>
          <div>{ Website.translations.yourTickets }</div>
        </div>
        <div className='flex-box flex-wrap items-center tickets-list' style={{ width: '100%' }}>
          { renderLineItems() }
        </div>
      </div>

      <div
        className="print-tickets-wrapper pointer flex-box items-center content-center"
        onClick={ ()=> printOrder() }
      >
        <i className='fa fa-cloud-arrow-down' />
      </div>
    </div>
  )

}
