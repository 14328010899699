import React, { useState } from 'react'
import DynamicEntityIndex from '../shared/DynamicEntityIndex'
import CreateButton from '../shared/CreateButton'
import Modal from '../shared/Modal'
import LanguageHandler from '../shared/LanguageHandler'
import FormInput from '../shared/FormInput'
import SubmitButton from '../shared/SubmitButton'

export default function Locations({
  appProps, initialLocations, initialTotalPages, itemsPath, deletePath,
  disableDelete, canCreate, canEdit, selectedColumns, languages
}) {
  const [locations, setLocations]                 = useState(initialLocations)
  const [showModal, setShowModal]                 = useState(false)
  const [usedLanguages, setUsedLanguages]         = useState([languages.find(language => language.code === appProps.currentLocale )])
  const [selectedLanguage, setSelectedLanguage]   = useState(usedLanguages[0].code)
  const [translations, setTranslations]           = useState({ [selectedLanguage]: { title: '' }})

  const { flashMessage } = appProps

  const actions = []
  if (canEdit) {
    actions.push({ url: item => `${itemsPath}/${item.apiUid}/edit`, icon: 'fa-pencil' })
  }

  const handleTitleChange = e => {
    const newTranslation = { [selectedLanguage]: { title: e.target.value } }
    setTranslations(prevState => ({ ...prevState, ...newTranslation }))
  }

  const extraRemoveLanguageCallback = locale => {
    setTranslations(prevState => {
      const nextState = { ...prevState }
      delete nextState[locale]
      return nextState
    })
  }

  const handleSubmit = e => {
    e.preventDefault()

    const fd = new FormData()
    Object.keys(translations).forEach((locale, index) => {
      fd.append(`location[translations_attributes][${ index }][title]`, translations[locale].title || '')
      fd.append(`location[translations_attributes][${ index }][locale]`, locale)
    })

    Rails.ajax({
      type: 'POST',
      url: itemsPath,
      dataType: 'json',
      data: fd,
      success: res => {
        if (res.redirect_url) {
          location.href = res.redirect_url
        }
      },
      error: res => {
        flashMessage.show(res.error, 'error')
      }
    })
  }

  function renderLocation(location, columns) {
    return (
      <>
        { columns.map( column => {
          const key = column.key
          return (
            <div key={ key } className='item flex-1'>
              <div>
                { location[key] || '-' }
              </div>
            </div>
          )
        })}
      </>
    )
  }

  return (
    <>
      { showModal &&
        <Modal
          visible={ showModal }
          closeModal={() => setShowModal(false)}
          mode="small"
        >
          <LanguageHandler
            languages={ languages }
            usedLanguages={ usedLanguages }
            setUsedLanguages={ setUsedLanguages }
            selectedLanguage={ selectedLanguage }
            setSelectedLanguage={ setSelectedLanguage }
            translatableContent={ [] }
            extraRemoveLanguageCallback={ extraRemoveLanguageCallback }
          />
          <FormInput
            label={ Website.translations.columns.title }
            value={translations[selectedLanguage]?.title || ''}
            onChange={handleTitleChange}
          />
          <div className='flex-box content-end'>
            <SubmitButton
              value={ Website.translations.save }
              className='btn btn-primary'
              onSubmit={ handleSubmit }
            />
          </div>
        </Modal>
      }

      <CreateButton
        pageTitle={ Website.translations.locations }
        breadcrumbs={ appProps.breadcrumbs } 
        canCreate={ canCreate }
        setShowModal={ setShowModal }
      />

      <DynamicEntityIndex
        appProps={ appProps }
        initialItems={ locations }
        setInitialItems={ setLocations }
        initialTotalPages={ initialTotalPages }
        selectAll={ true }
        disableDelete={ disableDelete }
        disableCustomization={ true }
        entity={ "locations" }
        tabs={ [] }
        tableHeadings={ selectedColumns }
        itemRenderer={ renderLocation }
        itemsPath={ itemsPath }
        deletePath={ deletePath }
        noResourcesTitle={ Website.translations.noLocations }
        actions={ actions }
        columnTranslations={ Website.translations.columns }
      />
    </>
  )
}
