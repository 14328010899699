import React, { useState } from 'react'
import DynamicEntityIndex from '../shared/DynamicEntityIndex'
import CreateButton from '../shared/CreateButton'
import Modal from '../shared/Modal'
import FormInput from '../shared/FormInput'
import SubmitButton from '../shared/SubmitButton'

export default function Index({
  appProps, initialTaxes, initialTotalPages, itemsPath, deletePath,
  disableDelete, canCreate, canEdit, selectedColumns
}) {
  const [taxes, setTaxes]                     = useState(initialTaxes)
  const [showModal, setShowModal]             = useState(false)
  const [title, setTitle]                     = useState('')
  const [rate, setRate]                       = useState('')
  const [includedInPrice, setIncludedInPrice] = useState(false)

  const { flashMessage } = appProps

  const actions = []
  if (canEdit) {
    actions.push({ url: item => `${itemsPath}/${item.apiUid}/edit`, icon: 'fa-pencil' })
  }

  const handleSubmit = _e => {
    const fd = new FormData()
    fd.append('tax[title]', title)
    fd.append('tax[rate]', rate)
    fd.append('tax[included_in_price]', includedInPrice)

    Rails.ajax({
      type: 'POST',
      url: itemsPath,
      dataType: 'json',
      data: fd,
      success: res => {
        flashMessage.show(res.success, 'success')
        setShowModal(false)
        setTaxes(prevState => [ ...prevState, res.tax ])
        setTitle('')
        setRate('')
        setIncludedInPrice(false)
      },
      error: res => {
        flashMessage.show(res.error, 'error')
      }
    })
  }

  const handleRateChange = e => {
    let { value } = e.target
    const regex =  /^(0(\.\d{0,2})?|1(\.0{0,2})?)?$/
    if (!regex.test(+value)) {
      return
    }
    setRate(value)
  }

  const renderTax = (tax, columns) => (
    <>
      { columns.map( column => {
        const key = column.key
        let output = tax[key]
        if (key === 'includedInPrice') {
          output = output ? Website.translations.included : Website.translations.notIncluded
        }
        return (
          <div key={ key } className='item flex-1'>
            <div>
              { output }
            </div>
          </div>
        )
      })}
    </>
  )

  return (
    <>
      { showModal &&
        <Modal
          visible={ showModal }
          closeModal={() => setShowModal(false)}
          mode="medium"
        >
          <FormInput
            label={ Website.translations.columns.title }
            value={ title }
            onChange={ e => setTitle(e.target.value) }
          />
          <FormInput
            label={ Website.translations.columns.rate}
            value={ rate }
            onChange={ handleRateChange }
          />
          <div className='flex-box flex-row gap-4'>
            <label className='fs-6 fw-semibold form-label mt-3'>
              { Website.translations.columns.includedInPrice }
            </label>
            <input
              type="checkbox"
              checked={ includedInPrice }
              onChange={ _e => setIncludedInPrice(prevState => !prevState ) }
            />
          </div>
          <div className='flex-box content-end'>
            <SubmitButton
              value={ Website.translations.save }
              className='btn btn-primary'
              onSubmit={ handleSubmit }
            />
          </div>
        </Modal>
      }

      <CreateButton
        pageTitle={ Website.translations.tax }
        breadcrumbs={ appProps.breadcrumbs }
        canCreate={ canCreate }
        setShowModal={ setShowModal }
      />

      <DynamicEntityIndex
        appProps={ appProps }
        initialItems={ taxes }
        setInitialItems={ setTaxes }
        initialTotalPages={ initialTotalPages }
        selectAll={ true }
        disableDelete={ disableDelete }
        disableCustomization={ true }
        entity={ "taxes" }
        tabs={ [] }
        tableHeadings={ selectedColumns }
        itemRenderer={ renderTax }
        itemsPath={ itemsPath }
        deletePath={ deletePath }
        noResourcesTitle={ Website.translations.noTaxes }
        actions={ actions }
        columnTranslations={ Website.translations.columns }
      />
    </>
  )
}
