import React from "react"
import Breadcrumbs from "./Breadcrumbs"

export default function CreateButton({ pageTitle, breadcrumbs, count, setShowModal, canCreate, bulkImport, setShowBulkModal}) {
  return (
    <>
      <div className={`flex-box items-center m-b-20 ${pageTitle ? 'content-space-between' : 'content-end' }`}>
        { pageTitle &&
          <div className='flex-box flex-column'>
            <div className='flex-box items-end'>
              <h1 className="page-heading text-dark fw-bold fs-3">
                { pageTitle }
              </h1>
              { count !== undefined &&
                <h2 className='m-l-10'>({ count })</h2>
              }
            </div>
            <Breadcrumbs 
              breadcrumbs={ breadcrumbs }
            />
          </div>
        }

        { canCreate &&
          <div className='flex-box items-center content-center'>
            { bulkImport &&
              <button
                className='pointer btn btn-primary m-r-20'
                onClick={ _e => { setShowBulkModal(true) } }
              >
                { Website.translations.bulkCreate }
              </button>
            }

            <a className="pointer btn btn-primary" onClick={ () => setShowModal(true) } >
              { Website.translations.create }
            </a>
          </div>
        }
      </div>
    </>
  )
}
