import React, { useState } from 'react'
import DynamicEntityIndex from '../shared/DynamicEntityIndex'
import Modal from '../shared/Modal'

export default function MerchantAccount({
  appProps, account, subaccounts, initialTotalPages, itemsPath, createPath,
  deletePath, disableDelete, canCreate, selectedColumns
}) {
  const [showModal, setShowModal] = useState(false)
  const [mid, setMid] = useState('')

  const { flashMessage } = appProps

  const handleSubmit = e => {
    e.preventDefault()

    const fd = new FormData()
    fd.append('merchant_subaccount[mid]', mid)

    Rails.ajax({
      type: 'POST',
      url: createPath,
      dataType: 'json',
      data: fd,
      success: res => {
        if (res.redirect_url) {
          location.href = res.redirect_url
        }
      },
      error: res => {
        flashMessage.show(res.error, 'error')
      }
    })
  }

  function renderSubaccount(subaccount, columns) {
    return (
      <>
      { columns.map( column => {
        const key = column.key
        return (
          <div key={ column.key } className='item flex-1'>
            { key === 'mid' ? (
              <a href={`${itemsPath}/merchant_ids/${subaccount.mid}`}>
                { subaccount[key] || '-' }
              </a>
            ) : (
              <div>
                { subaccount[key] || '-' }
              </div>
            )}
          </div>
        )
      })}
    </>
    )
  }

  return (
    <>
      { showModal &&
        <Modal
          visible={ showModal }
          closeModal={() => setShowModal(false)}
          mode="medium"
        >
          <form onSubmit={handleSubmit}>
            <div className='flex-box flex-column'>
              <label className='fs-6 fw-semibold form-label mt-3'>
                { Website.translations.columns.mid }:
              </label>
              <input
                type="text"
                className='form-control form-control-solid'
                value={mid}
                onChange={e => setMid(e.target.value)}
              />
            </div>
            <div className='flex-box content-end m-t-30'>
              <input className='btn btn-primary' type="submit" value={ Website.translations.save } />
            </div>
          </form>
        </Modal>
      }

      <CreateButton
        pageTitle={ Website.translations.merchantAccount }
      />

      <div className='card'>
        <h3>Merchant IDs</h3>

        { canCreate &&
          <button onClick={() => setShowModal(true)} >
            <b>
              { Website.translations.create }
            </b>
          </button>
        }

        <DynamicEntityIndex
          initialItems={ subaccounts }
          initialTotalPages={ initialTotalPages }
          selectAll={ true }
          disableCustomization={ true }
          disableDelete={ disableDelete }
          entity={ "merchant_subaccounts" }
          tabs={ [] }
          tableHeadings={ selectedColumns }
          itemRenderer={ renderSubaccount }
          itemsPath={ itemsPath }
          deletePath={ deletePath }
          noResourcesTitle={ Website.translations.noMerchantIds }
          columnTranslations={ Website.translations.columns }
        />
      </div>
    </>
  )
}
