import React, { useState } from 'react'
import SaveFixedButton from '../shared/SaveFixedButton'
import FormInput from '../shared/FormInput'
import CreateButton from '../shared/CreateButton'

export default function EditApiIntegration({ appProps, apiIntegration, updatePath }) {
  const formatSettings = settings => settings.map(setting => ['true', 'false'].includes(setting.value) ? { ...setting, value: setting.value === 'true' } : setting)

  const [settings, setSettings]           = useState(formatSettings(apiIntegration.settings))
  const [saveButtonRef, setSaveButtonRef] = useState(null)

  const handleChange = (e, key) => {
    saveButtonRef.setSaveableStatus()
    setSettings(prevState => {
      const index = settings.findIndex(setting => setting.key === key)
      return [
        ...prevState.slice(0, index),
        { ...prevState[index], value: e.target.value },
        ...prevState.slice(index + 1)
      ]
    })
  }

  const save = () => {
    const fd = new FormData()
    settings.forEach(setting => {
      fd.append(`settings[${setting.key}]`, setting.value)
    })

    saveButtonRef.setSavingStatus()

    Rails.ajax({
      type: 'PATCH',
      url: updatePath,
      dataType: 'json',
      data: fd,
      success: res => {
        saveButtonRef.setSuccessStatus()
        setSettings(formatSettings(res.settings))
      },
      error: res => {
        saveButtonRef.setErrorStatusWithMessage(res.error)
      }
    })
  }

  const renderSetting = (setting, index) => {
    let { key, value } = setting
    let label = key.replaceAll('_', ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
    if (label.startsWith('Invoicing Software ')) {
      label = label.replace('Invoicing Software ', '')
    }
    let type = key === 'password' || key === 'invoicing_software_api_key' ? 'password' : null
    if (['invoicing_software_production_mode', 'production_mode'].includes(key)) {
      type = 'toggle'
    }

    let onChange = type === 'toggle' ? _e => prev: e => handleChange(e, key)
    if (type === 'toggle') {
      onChange = _e => {
        saveButtonRef.setSaveableStatus()
        setSettings(prevState => {
          const index = settings.findIndex(setting => setting.key === key)
          return [
            ...prevState.slice(0, index),
            { ...prevState[index], value: !prevState[index].value },
            ...prevState.slice(index + 1)
          ]
        })
      }
    } else {
      onChange = e => handleChange(e, key)
    }

    return (
      <FormInput
        key={ index }
        label={ label }
        type={ type }
        value={ value || '' }
        onChange={ onChange }
      />
    )
  }

  return (
    <>
      <CreateButton
        pageTitle={ apiIntegration.apiTitle }
        breadcrumbs={ appProps.breadcrumbs }
      />

      <div className='card'>
        <div className="card-header border-0">
          <div className="card-title m-0">
            { Website.translations.settings }
          </div>
        </div>
        <div className='card-body'>
          { settings.map(renderSetting) }
        </div>
      </div>

      <SaveFixedButton
        onSave={ save }
        entity={ Website.translations.apiIntegration }
        ref={ ref => setSaveButtonRef(ref) }
      />
    </>
  )
}
