export function generateId() {
  return String(Math.random()).substring(2, 12)
}

export function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}
