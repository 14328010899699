import React, { useState, forwardRef, useImperativeHandle } from 'react'

const modes = {
  'success': 'fa-check',
  'error': 'fa-hexagon-exclamation',
  'notice': 'fa-flag'
}

function generateId() {
  return String(Math.random()).substring(2, 12)
}

const FlashMessage = forwardRef( (props, ref) => {
  const [messages, setMessages] = useState([])

  useImperativeHandle(ref, () => ({ show }))

  function show(flashMessage, flashMode = 'success', visibilityDuration = 3000) {
    if (flashMessage) {
      const messageId = generateId()
      setMessages( prevState => ([ ...prevState, { id: messageId, title: flashMessage, mode: flashMode, visibilityDuration: visibilityDuration } ]) )
      startTimeout(messageId, visibilityDuration)
    }
  }

  function startTimeout(messageId, visibilityDuration = 3000) {
    setTimeout( () => {
      setMessages( prevState => {
        let updatedMessages = [...prevState]
        const messageIndex = updatedMessages.findIndex( message => message.id === messageId )
        if (messageIndex >= 0) {
          updatedMessages[messageIndex] = { ...updatedMessages[messageIndex], fadeOut: true }
        }

        return updatedMessages
      })

      setTimeout( () => {
        setMessages( prevState => prevState.filter( message => message.id !== messageId ) )
      }, 1000)
    }, visibilityDuration)
  }

  return (
    <>
      { messages.map( (message, index) => (
        <div
          key={ index }
          className={ `flash-container ${ message.mode } ${ message.fadeOut ? 'animated fadeOutRight' : 'animated fadeInRight' }` }
          style={{ top: 40 * index }}
        >
          <div className='flex-box items-center content-center'>
            <i className={ `fa-light ${ modes[message.mode] }` }/>
            <div style={{ marginLeft: 20 }}>
              { message.title }
            </div>
          </div>
        </div>
      ))}
    </>
  )
})

export default FlashMessage
