import React, { useState, useEffect, useRef, useCallback } from 'react'
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates'
import Pagination from './Pagination'
import EntityLoader from './EntityLoader'
import DeleteSelected from './DeleteSelected'
import EmptyGrid from './EmptyGrid'
import Tabs from './Tabs'
import DropMenu from './DropMenu'
import { Select, MenuItem, Box, Chip, Checkbox } from '@material-ui/core'
// import Toggle from 'react-toggle'
import ModalAlert from './ModalAlert'
import moment from 'moment'
import debounce from 'lodash.debounce'

export default function DynamicEntityIndex({
  appProps, initialItems, initialTotalPages, selectAll, entity, tabs, tableHeadings,
  itemRenderer, deletePath, itemsPath, exportPath, icon, noResourcesTitle, tags,
  disableDelete, fetchOnStart, initialTags, disableSelectTags, customDeleteAlert, setInitialItems, listColumns,
  disableCustomization, actions, dropMenuItems, columnTranslations, disableSearch,
  urlParams, disableReload, isExportable, onDeleteError, enableDateFilter, enableFutureDates
}) {
  const [currentTab, setCurrentTab] = useState((tabs && tabs.length) ? tabs[0].key : null)
  const [selectedItems, setSelectedItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [items, _setItems] = useState(initialItems)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(initialTotalPages)
  const [currentTags, setCurrentTags] = useState(initialTags || [])
  const [showCustomize, _setShowCustomize] = useState(false)
  const [currentColumns, setCurrentColumns] = useState(tableHeadings || [])
  const [currentSortable, setCurrentSortable] = useState(null)
  const [sortingMode, setSortingMode] = useState(null)
  const [tempColumns, setTempColumns] = useState([])
  const [selectedDropMenuItem, setSelectedDropMenuItem] = useState(null)
  const [showExportAlert, setShowExportAlert] = useState(false)
  const [dateRange, setDateRange] = useState({
    start_date: moment().subtract(240, 'days'),
    end_date: moment(new Date())
  })
  const [focusedInput, setFocusedInput] = useState(null)
  const smallDevice = window.matchMedia('(max-width: 480px)').matches

  const searchValueRef = useRef(searchValue)
  const mergedColumnTranslations = { ...Website.translations.columns, ...(columnTranslations || {}) }

  useEffect( () => {
    if (fetchOnStart) {
      getItems()
    }
  }, [dateRange])

  useEffect( () => {
    if (enableDateFilter) {
      getItems()
    }
  }, [dateRange])

  useEffect( () => {
    _setItems(initialItems)
  }, [initialItems])

  useEffect( ()=> {
    document.addEventListener('keyup', event => {
      if (event.key === 'Escape') {
        setShowCustomize(false)
      }
    })
  }, [])

  function setItems(state) {
    _setItems(state)
    if (setInitialItems) {
      setInitialItems(state)
    }
  }

  const debouncedSearch = useCallback(
    debounce(getItems, 700),
    [
      currentTab,
      currentTags,
      currentSortable,
      sortingMode
    ]
  )

  function changeInput(event) {
    const value = event.target.value
    setSearchValue(value)
    searchValueRef.current = value

    if (value.length > 2) {
      debouncedSearch()
    } else {
      debouncedSearch.cancel()
      if (!value.length) {
        getItems()
      }
    }
  }

  function onSelect(checked, itemId) {
    if (checked) {
      setSelectedItems(selectAll ? [...selectedItems, itemId] : [itemId])
    } else {
      setSelectedItems(selectAll ? selectedItems.filter( item => item !== itemId ) : [])
    }
  }

  function onDelete() {
    setItems(items.filter( item => !selectedItems.includes(item.id) ))
    setSelectedItems([])
  }

  function onSelectTag(tag) {
    let updatedTags = [...currentTags]

    if (!!updatedTags.find( updatedTag => updatedTag === tag )) {
      updatedTags = updatedTags.filter( updatedTag => updatedTag !== tag )
    } else {
      updatedTags.push(tag)
    }

    setCurrentTags(updatedTags)
    getItems(currentTab, 1, updatedTags)
  }

  function handleColumnSelect(columnKey, checked) {
    let updatedColumns = [...tempColumns]
    let checkedColumn = updatedColumns.find(column => column.key === columnKey)

    if (checkedColumn && !checked) {
      updatedColumns = updatedColumns.filter(column => column.key !== columnKey)
    } else {
      updatedColumns = [...updatedColumns, listColumns.find(column => column.key === columnKey)]
    }
    updatedColumns = [...listColumns.filter(value => updatedColumns.find(selectedValue => selectedValue.key === value.key))]

    setTempColumns(updatedColumns)
  }

  function setShowCustomize(value) {
    if (value) {
      _setShowCustomize(true)
      setTempColumns([...currentColumns])
    } else {
      _setShowCustomize(false)
      setTempColumns([])
    }
  }

  function saveColumnSelect() {
    let updatedColumns = [...tempColumns]
    setCurrentColumns(updatedColumns)
    setShowCustomize(false)
    getItems(currentTab, 1, currentTags, updatedColumns)
  }

  function handleSort(columnType) {
    if (!loading) {
      let selectedSortable = columnType
      let mode = 'asc'

      if (!currentSortable || (currentSortable !== columnType)) {
        setCurrentSortable(columnType)
        setSortingMode('asc')

      } else if (currentSortable === columnType) {
        if (sortingMode === 'desc') {
          setCurrentSortable(null)
          setSortingMode(null)
          selectedSortable = null
          mode = null

        } else {
          setSortingMode('desc')
          mode = 'desc'
        }
      }

      getItems(currentTab, currentPage, currentTags, null, selectedSortable, mode)
    }
  }

  function sortingIcon(columnType) {
    if (columnType !== currentSortable) {
      return 'fa-solid fa-sort'
    } else if (columnType === currentSortable && sortingMode === 'asc') {
      return 'fa-duotone fa-sort-up active-icon'
    } else if (columnType === currentSortable && sortingMode === 'desc') {
      return 'fa-duotone fa-sort-down active-icon'
    }
  }

  function onDateChange({ startDate, endDate }) {
    if(startDate <= endDate) {
      setDateRange({
        ...dateRange,
        end_date: moment(endDate),
        start_date: moment(startDate)
      })
    }
  }

  function renderColumns() {
    return (
      <>
        <div className={`quick-drop-menu-wrapper-overlay ${ showCustomize ? 'opened' : 'closed' }`} onClick={ () => setShowCustomize(false) }></div>
        <div className={`drop-menu ${ showCustomize ? 'opened animated' : 'closed animated' }`}>
          <div className="drop-item flex-box items-center content-end">
            <button
              type='button'
              className='btn flex-box'
              onClick={ saveColumnSelect }
            >
              { Website.translations.save }
            </button>
          </div>
          <div className='drop-items flex-box items-center flex-column' style={{ maxHeight: "300px", overflow: "scroll" }}>
            { listColumns.map( (column, index) => (
              <div key={ index } className="drop-item flex-box m-t-20">
                <input
                  type='checkbox'
                  className={ column.required ? 'disabled' : '' }
                  checked={ !!tempColumns.find( option => option.key === column.key ) }
                  onChange={ event => handleColumnSelect(column.key, event.target.checked) }
                  disabled={ column.required }
                />
                <div className="switch-text flex-box items-center flex-1">
                  <div className='label-title'>
                    { mergedColumnTranslations[column.label] }
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    )
  }

  function renderDateRangeFilter() {
    return (
      <div className='p-r-10 p-l-10'>
        <DateRangePicker
          displayFormat={() => "DD MMM YYYY"}
          endDate={ dateRange.end_date }
          endDateId="endDate"
          focusedInput={ focusedInput }
          onDatesChange={ onDateChange }
          onFocusChange={ setFocusedInput }
          orientation={ smallDevice ? 'vertical' : 'horizontal' }
          readOnly={ true }
          required={ true }
          noBorder={ true }
          startDate={ dateRange.start_date }
          startDateId="startDate"
          isOutsideRange={ (date) => date > new Date() - 1 ? (!enableFutureDates ? true : false) : false }
        />
      </div>
    )
  }

  function getItems(tab = currentTab, page = 1, selectedTags = currentTags, selectedColumns = null, selectedSortable = currentSortable, mode = sortingMode) {
    setLoading(true)
    let url = `${ itemsPath }?page=${ page }&filter_status=${ tab }&date_range=${ JSON.stringify(dateRange) }&search=${ searchValueRef.current.trim() }&sort=${ selectedSortable }&mode=${ mode }&tags=${ JSON.stringify(selectedTags) }`
    if (urlParams) {
      url += `&${ urlParams }`
    }

    if (selectedColumns) {
      url += `&columns=${ JSON.stringify(selectedColumns) }`
    }

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: res => {
        setLoading(false)
        setCurrentTab(tab)
        setItems(res.items)
        setTotalPages(res.totalPages)
        setCurrentPage(page)
        res.selectedColumns && setCurrentColumns(res.selectedColumns)
      }
    })
  }

  function exportTable() {
    setShowExportAlert(false)
    let formData = new FormData()
    formData.append('columns', JSON.stringify(currentColumns))
    formData.append('search', JSON.stringify(searchValue.trim()))
    formData.append('sort', JSON.stringify(currentSortable))
    formData.append('mode', JSON.stringify(sortingMode))
    formData.append('date_range', JSON.stringify(dateRange))
    if (currentTab) {
      formData.append('filter_status', JSON.stringify(currentTab))
    }

    Rails.ajax({
      type: 'POST',
      url: exportPath,
      dataType: 'json',
      data: formData,
      success: res => {
      }
    })
  }

  return (
    <div className='card'>
      <div className='card-content entity-index'>
        { tabs && tabs.length > 0 &&
          <Tabs
            tabs={ tabs }
            currentTab={ currentTab }
            setCurrentTab={ tab => { if (tab !== currentTab ) getItems(tab) } }
          />
        }

        <div className='flex-box flex-wrap items-center content-space-between entity-index-heading'>
          { !disableSearch &&
            <div>
              <div className='flex-box items-center' style={{ position: 'relative' }}>
                <button
                  type='button'
                  className='search-btn'
                  style={{ position: 'absolute', left: 10 }}
                  onClick={ () => {} }
                >
                  <i className='fa-solid fa-magnifying-glass'/>
                </button>
                <input
                  type='text'
                  className='form-control form-control-solid w-250px ps-14'
                  placeholder={ 'Search' }
                  value={ searchValue }
                  onChange={ changeInput }
                />
              </div>
            </div>
          }

          <div className='flex-1 flex-box items-center content-end'>
            { enableDateFilter && renderDateRangeFilter() }

            { isExportable && items.length !== 0 &&
              <div className='flex-box items-center content-end'>
                <div
                  id='create-csv-button'
                  className='flex-box items-center'
                  style={{ cursor: 'pointer'}}
                  onClick={ () => { if (!loading) setShowExportAlert(true) }}
                >
                  <i className="fa-light fa-file-csv m-r-5" />
                  { Website.translations.exportToCsv }
                </div>
              </div>
            }
            { selectedItems.length > 0 &&
              <div className='m-r-15'>
                <DeleteSelected
                  appProps={ appProps }
                  entity={ entity }
                  selectedItems={ selectedItems }
                  deletePath={ deletePath }
                  onDelete={ onDelete }
                />
              </div>
            }
          </div>
        </div>

        { !disableSelectTags && tags && tags.length > 0 &&
          <div className='flex-box items-center content-space-between'>
            <div className='flex-box flex-wrap'>
              { tags.map( tag => {
                const isSelected = !!currentTags.find( currentTag => currentTag === tag.key )

                return (
                  <div
                    key={ tag.key }
                    className={ `filter-tag flex-box items-center ${ isSelected ? 'selected' : '' }` }
                    onClick={ () => onSelectTag(tag.key) }
                  >
                    { tag.icon &&
                      <i className={ tag.icon } style={{ marginRight: 5 }}/>
                    }
                    { tag.title }
                  </div>
                )
              })}
            </div>
          </div>
        }

        <div className='m-t-20' style={{ minHeight: 200 }}>
          { loading && <EntityLoader /> }

          <div className='table-wrapper flex-box'>
            { !disableDelete &&
              <div className='table-left flex-box flex-column'>
                { !loading && items.length > 0 &&
                  <div className='heading delete-all-wrapper'>
                    <label>
                      <button type='button'>
                        <input
                          type='checkbox'
                          onChange={ event => setSelectedItems(event.target.checked ? items.map( item => item.id ) : []) }
                          style={ !(selectAll && !disableDelete) ? { visibility: 'hidden' } : {} }
                          disabled={ !(selectAll && !disableDelete) }
                        />
                        <span/>
                      </button>
                    </label>
                  </div>
                }

                { !loading && items.length > 0 &&
                  <div className='items flex-box flex-column'>
                    { items.map( item => (
                      <div key={ item.id } className='item-sticky flex-1'>
                        <label>
                          <input
                            type='checkbox'
                            checked={ !!selectedItems.find( selectedItem => selectedItem === item.id ) }
                            onChange={ event => onSelect(event.target.checked, item.id) }
                            style={ !(!disableDelete && !item.disableDelete) ? { visibility: 'hidden' } : {} }
                            disabled={ !(!disableDelete && !item.disableDelete) }
                          />
                          <span/>
                        </label>
                      </div>
                    ))}
                  </div>
                }
              </div>
            }

            { !loading && items.length > 0 &&
              <div className='table-middle'>
                <div className='headers flex-box'>
                  { currentColumns.map( (tableHeading, index) => (
                    <div key={ index } className='item flex-1 bold'>
                      { mergedColumnTranslations[tableHeading.label] }
                      { tableHeading.sortable &&
                        <i
                          className={ `m-l-10 ${ sortingIcon(tableHeading.key) }` }
                          style={{ cursor: 'pointer' }}
                          onClick={ () => handleSort(tableHeading.key) }
                        />
                      }
                    </div>
                  ))}
                </div>
                <div className='rows flex-box flex-column'>
                  { items.map( item => (
                    <div key={ item.id } className='table-row flex-box'>
                      { itemRenderer(item, currentColumns) }
                    </div>
                  ))}
                </div>
              </div>
            }

            <div className='table-right'>
              <div className='heading'/>
              { !loading && items.length > 0 &&
                <div className='items flex-box flex-column'>
                  { items.map( item => (
                    <div key={ item.id } className='item-sticky flex-box content-space-between' style={{ position: 'relative' }}>
                      { actions && actions.map( (action, index) => {
                        if (action.dropMenu) {
                          return (
                            <DropMenu
                              key={ index }
                              item={ item }
                              dropMenuItems={ dropMenuItems }
                              selectedItem={ selectedDropMenuItem }
                              setSelectedItem={ setSelectedDropMenuItem }
                            />
                          )

                        } else if (!action.hidden || (action.hidden && !action.hidden(item))) {
                          return (
                            <a
                              key={ index }
                              className='link flex-1'
                              href={ action.url ? action.url(item) : '' }
                              target={ action.target || '_self' }
                              onClick={ event => action.onClick ? action.onClick(event, item) : {} }
                            >
                              <i className={ `fa-solid ${ action.icon }` }/>
                            </a>
                          )

                        } else {
                          return null
                        }
                      })}
                    </div>
                  ))}
                </div>
              }
            </div>
          </div>

          { !loading && items.length === 0 &&
            <EmptyGrid
              iconName={ icon }
              noResourcesTitle={ noResourcesTitle }
              onReload={ fetchOnStart ? getItems : null }
              disableReload={ disableReload }
            />
          }

        </div>

        { totalPages > 1 && !loading && items.length > 0 &&
          <div className='row'>
            <Pagination
              currentPage={ currentPage }
              totalPages={ totalPages }
              changePage={ page => getItems(currentTab, page) }
            />
          </div>
        }

      </div>

      { showExportAlert &&
        <ModalAlert
          alert={ Website.translations.exportAlert }
          onSelect={ exportTable }
          onClose={ () => setShowExportAlert(false) }
        />
      }

    </div>
  )
}
