import React, { useState } from 'react'
import Modal from '../shared/Modal'
import FormInput from '../shared/FormInput'
import VerticalDraggableItems from '../shared/VerticalDraggableItems'

export default function Options({
  options, handleOptionChange, handleOptionTranslationChange, handleAddNewOption, handleDeleteOption,
  handleOptionSeasonPriceChange, seasons, seasonsPath, selectedLanguage, translations, usedLanguages, saveButtonRef, setOptions, setStockItems, stockType
}) {
  const [modalOptionIndex, setModalOptionIndex] = useState(null)

  const renderSeason = season => {
    const rate = options[modalOptionIndex].ticketOptionSeasonPrices.find(rate => rate.season.id === season.id)
    return (
      <div key={ season.id }>
        <div>
          <h3>{ season.title }</h3>
          <FormInput
            label={ Website.translations.columns.price }
            value={ rate?.price || '' }
            onChange={ e => handleOptionSeasonPriceChange(modalOptionIndex, season.id, e) }
          />
        </div>
      </div>
    )
  }
  
  const handleDragItems = items => { 
    saveButtonRef.setSaveableStatus() 

    setOptions(items) 

    if (stockType === 'Stock::CustomSpace') {
      const newOrder = items.map(item => options.findIndex(option => option.id === item.id))
      setStockItems(prevState => prevState.map(item => {
        return {
          ...item,
          stockable: {
            ...item.stockable,
            zoneOptionPrices: item.stockable.zoneOptionPrices.map(el => {
              return newOrder.map(newIdx => el[newIdx])
            })
          }
        }
      }))
    }
  }

  const renderOption = (draggableAttributes, draggableListeners, option, index) => {
    return (
      <div
        key={index}
        className="card m-b-30"
      >
        <div className='flex-box' style={{ gap: '10px '}}>
          <div className='m-r-20'>
            <i className='fa-light fa-grip-dots' style={{ cursor: 'grab' }} { ...draggableAttributes } { ...draggableListeners } />
          </div>
          <div style={{ width: '100%' }}>
            <FormInput
              label={ Website.translations.columns.title }
              value={ translations[index]?.[selectedLanguage]?.title || '' }
              onChange={ e => handleOptionTranslationChange('title', selectedLanguage, index, e) }
            />

            <FormInput
              label={ Website.translations.subtitle }
              value={ translations[index]?.[selectedLanguage]?.subtitle || '' }
              onChange={ e => handleOptionTranslationChange('subtitle', selectedLanguage, index, e) }
            />

            <FormInput
              label={ Website.translations.columns.price }
              value={ option.price }
              onChange={ e => handleOptionChange('price', index, e) }
            />

            <div className='flex-box items-center content-end m-t-20'>
              { stockType !== 'Stock::CustomSpace' &&
                <button className='flex-box items-center btn btn-primary m-r-10' onClick={ () => setModalOptionIndex(index) }>
                  { Website.translations.seasonalRates }
                  <i className='fa fa-calendar m-l-5' />
                </button>
              }

              <button className='flex-box items-center btn btn-primary' onClick={ e => handleDeleteOption(e, index) }>
                { Website.translations.deleteTicketOption }
                <i className='fa fa-trash m-l-5' />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      { modalOptionIndex !== null &&
        <Modal
          visible={ modalOptionIndex !== null }
          closeModal={ () => setModalOptionIndex(null) }
          mode="large"
        >
          { seasons.length > 0 && seasons.map(season => renderSeason(season)) }
          { seasons.length === 0 &&
            <a href={ seasonsPath }>
              { Website.translations.createSeasonToContinue }
            </a>
          }
          <hr />
          <button className="btn" onClick={ () => setModalOptionIndex(null) }>
            { Website.translations.closeAction }
          </button>
        </Modal>
      }
      <div className='flex-box items-center content-space-between m-b-20'>
        <h2>{ Website.translations.options }</h2>
        <button className='btn btn-primary' onClick={ e => handleAddNewOption(e, usedLanguages) }>
          { Website.translations.addNew }
        </button>
      </div>

      { options.length > 0 &&
        <>
          <VerticalDraggableItems
            items={ options.filter(option => !option._destroy) }
            itemRenderer={ renderOption }
            setItems={ items => handleDragItems(items) }
            showDragOverlay={ false }
          />
        </>
      }
    </>
  )
}