import React, { useState, useEffect } from "react"

export default function Header({ appProps }) {
  return (
    <>
      <div id="kt_app_header" className="app-header">
        <div className="app-container container-xxl d-flex align-items-stretch justify-content-end" id="kt_app_header_container">
          { appProps.currentAdministrator &&
            <div className='flex-box align-items-center' style={{ justifySelf: 'end', height: '100%' }}>
              <div>{ appProps.currentAdministrator }</div>
            </div>
          }
        </div>
      </div>
    </>
  )
}
