import React, { useState, useEffect } from 'react'
import Modal from '../shared/Modal'
import TextEditor from '../shared/TextEditor'
import FormInput from '../shared/FormInput'
import MediaGallery from '../shared/MediaGallery'

export default function General({
  taxes, locations, locationGroups, usedLocation, usedLocationGroup,
  handleLocationChange, handleLocationGroupChange, fields, translations,
  setTranslations, newTaxes, setNewTaxes, handleChange, saveButtonRef,
  selectedLanguage, mediaPath, images, setImages, mediaUploader, stockType
}) {
  const [showModal, setShowModal] = useState(false)
  const [renderKey, setRenderKey] = useState(1)

  useEffect(() => {
    setRenderKey(Math.random())
  }, [selectedLanguage])

  const handleTranslationChange = (field, change) => {
    const updatedTranslations = { ...translations }

    // For TextEditor component, change is a string containing HTML
    // For FormInput, change is a React event
    if (typeof change === 'string') {
      updatedTranslations[selectedLanguage][field] = change
    } else {
      updatedTranslations[selectedLanguage][field] = change.target.value
    }

    setTranslations(updatedTranslations)
    saveButtonRef.setSaveableStatus()
  }

  const renderTaxes = () => {
    let output
    if (taxes.length > 0 || newTaxes.length > 0) {
      output = <div className='m-b-20'>
                <FormInput
                  label={ Website.translations.tax }
                  value={ fields.tax || '-' }
                  onChange={ e => handleChange('tax', e) }
                  type='select'
                  multiple={ false }
                  selectWithDefault={ true }
                  selectEntities={ [...taxes, ...newTaxes] }
                  keyAttr='id'
                  valueAttr='id'
                  outputAttr='title'
                />
              </div>
    } else {
      output = <div className='flex-box flex-column'>
                <label className='fs-6 fw-semibold form-label mt-3'>
                  { Website.translations.tax }
                </label>
                <div className='flex-box items-center content-start m-b-20'>
                  { Website.translations.noTaxYet }
                </div>
              </div>
    }
    return (
      <>
        { output }
        <div className='flex-box items-center content-start m-b-20'>
          <button className='btn btn-primary' onClick={ () => { setNewTaxes(prevState => [...prevState, { id: -prevState.length - 1, title: '', rate: 0, includedInPrice: false }]); setShowModal(true) } }>
            { Website.translations.create }
          </button>
        </div>
      </>
    )
  }

  const handleSaveNewTax = () => {
    handleChange('tax', { target: { value: newTaxes[newTaxes.length - 1].id } })
    setShowModal(false)
  }

  const handleCloseModal = () => {
    setNewTaxes(prevState => prevState.slice(0, prevState.length - 1))
    setShowModal(false)
  }

  const handleRateChange = e => {
    let { value } = e.target
    const regex =  /^(0(\.\d{0,2})?|1(\.0{0,2})?)?$/
    if (!regex.test(+value)) {
      return
    }

    setNewTaxes(prevState => {
      return [
        ...prevState.slice(0, prevState.length - 1),
        { ...prevState[prevState.length - 1], rate: value }
      ]
    })
  }

  const handleTitleChange = e => {
    setNewTaxes(prevState => {
      return [
         ...prevState.slice(0, prevState.length - 1),
        { ...prevState[prevState.length - 1], title: e.target.value }
      ]
    })
  }

  const handleIncludedInPriceChange = _e => {
    setNewTaxes(prevState => {
      return [
        ...prevState.slice(0, prevState.length - 1),
        {
          ...prevState[prevState.length - 1],
          includedInPrice: !prevState[prevState.length - 1].includedInPrice
        }
      ]
    })
  }

  function handleUploadImage(uploadedFile) {
    setImages( prevState => {
      let updatedImages = [ ...prevState ]
      const imageIndex = updatedImages.findIndex( image => image.uid === uploadedFile.uploadUid )
      if (imageIndex >= 0) {
        updatedImages.splice(imageIndex, 1, { ...uploadedFile, blob: null })
      }

      return updatedImages
    })
  }

  return (
    <>
      { showModal &&
        <Modal
          visible={ showModal }
          closeModal={ handleCloseModal }
          mode="large"
        >
          <h3>{ Website.translations.createTax }</h3>
          <div className='m-t-40 m-b-40'>
            <FormInput
              label={ Website.translations.columns.title }
              value={ newTaxes[newTaxes.length - 1].title }
              onChange={ handleTitleChange }
            />
            <FormInput
              label={ Website.translations.columns.rate}
              value={ newTaxes[newTaxes.length - 1].rate }
              onChange={ handleRateChange }
            />
            <div className='flex-box flex-row gap-4'>
              <label className='fs-6 fw-semibold form-label mt-3'>
                { Website.translations.columns.includedInPrice }
              </label>
              <input
                type="checkbox"
                checked={ newTaxes[newTaxes.length -1].includedInPrice }
                onChange={ handleIncludedInPriceChange }
              />
            </div>
          </div>
          { newTaxes[newTaxes.length - 1].title !== '' &&
            <div className='flex-box items-center content-end'>
              <button className="btn btn-primary" onClick={ handleSaveNewTax }>
                { Website.translations.save }
              </button>
            </div>
          }
        </Modal>
      }

      <div className="col-md-12">
        <div className="card border-hover-primary">
          <div className="card-body p-9">
            <MediaGallery
              multiple
              mediaTypes={ ['image'] }
              files={ images }
              setFiles={ setImages }
              onUploadMedia={ handleUploadImage }
              mediaPath={ mediaPath }
              mediaUploader={ mediaUploader }
              chooseMediaTitle={ Website.translations.media.images }
            />
          </div>
        </div>
      </div>

      <div className="col-md-12 m-t-30">
        <div className="card border-hover-primary">
          <div className="card-body p-9">
            <FormInput
              label={ Website.translations.columns.title }
              value={ translations[selectedLanguage]?.title || '' }
              onChange={ e => handleTranslationChange('title', e) }
            />

            <div className={ 'flex-box flex-column'}>
              <label className='fs-6 fw-semibold form-label mt-3'>
                { Website.translations.columns.description }
              </label>
              <TextEditor
                key={ renderKey }
                content={ translations[selectedLanguage]?.description || '' }
                setContent={ html => handleTranslationChange('description', html) }
              />
            </div>

            <div className={ 'flex-box flex-column'}>
              <label className='fs-6 fw-semibold form-label mt-3'>
                { Website.translations.ticketTerms }
              </label>
              <TextEditor
                key={ renderKey + 1 }
                content={ translations[selectedLanguage]?.terms || '' }
                setContent={ html => handleTranslationChange('terms', html) }
              />
            </div>

          </div>
        </div>
      </div>

      <div className="col-md-12 m-t-30">
        <div className="card border-hover-primary">
          <div className="card-body p-9">
            <FormInput
              label={ Website.translations.columns.price }
              value={ fields.price }
              onChange={ e => handleChange('price', e) }
            />

            <FormInput
              label={ Website.translations.columns.lowThreshold }
              value={ fields.lowThreshold }
              onChange={ e => handleChange('lowThreshold', e) }
            />
          </div>
        </div>
      </div>

      <div className="col-md-12 m-t-30">
        <div className="card border-hover-primary">
          <div className="card-body p-9">
            { renderTaxes() }
          </div>
        </div>
      </div>

      <div className="col-md-12 m-t-30">
        <div className="card border-hover-primary">
          <div className="card-body p-9">
            <div className='flex-box flex-row gap-4 m-b-20'>
              <label className='fs-6 fw-semibold form-label mt-3'>
                { Website.translations.columns.published }
              </label>
              <input
                type="checkbox"
                checked={ fields.published }
                onChange={ e => handleChange('published', e) }
              />
            </div>

            { stockType === 'Stock::SimpleQuantity' &&
              <div className='flex-box items-center content-space-between m-b-20'>
                <div style={{ flexBasis: 0, flexGrow: '0.9' }}>
                  { Website.translations.ticketSkipDateSettingText }
                </div>
                <div>
                  <input
                    type="checkbox"
                    checked={ fields.skipDatePick }
                    onChange={ e => handleChange('skipDatePick', e) }
                  />
                </div>
              </div>
            }

            { locations.length > 0 &&
              <FormInput
                label={ Website.translations.location }
                value={ usedLocation || '-' }
                onChange={ handleLocationChange }
                type='select'
                multiple={ false }
                selectWithDefault={ true }
                selectEntities={ locations }
                keyAttr='id'
                valueAttr='id'
                outputFunc={ item => (item.translations.find(translation => translation.locale === selectedLanguage) || item.translations[0]).title }
              />
            }

            { locationGroups.length > 0 &&
              <FormInput
                label={ Website.translations.locationGroup }
                value={ usedLocationGroup || '-' }
                onChange={ handleLocationGroupChange }
                type='select'
                multiple={ false }
                selectWithDefault={ true }
                selectEntities={ locationGroups }
                keyAttr='id'
                valueAttr='id'
                outputFunc={ item => (item.translations.find(translation => translation.locale === selectedLanguage) || item.translations[0]).title }
              />
            }

          </div>
        </div>
      </div>
    </>
  )
}
