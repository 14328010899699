import React, { useState } from 'react'
import { DndContext, DragOverlay, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, sortableKeyboardCoordinates, SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers'
import { CSS } from '@dnd-kit/utilities'

export function SortableItem({ item, itemRenderer, showDragOverlay }) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: item.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    position: 'relative'
  }

  return (
    <div ref={ setNodeRef } style={ style }>
      { itemRenderer(attributes, listeners, item) }

      { showDragOverlay && isDragging &&
        <div style={{
          position: 'absolute',
          top: 0, right: 0, bottom: 0, left: 0,
          width: '100%', height: '100%',
          backgroundColor: '#eee',
          zIndex: 9
        }}>
        </div>
      }
    </div>
  )
}

export default function VerticalDraggableItems({ items, itemRenderer, setItems, showDragOverlay }) {
  const [activeId, setActiveId] = useState(null)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  function getDragOverlay() {
    if (!activeId) {
      return null
    }

    const item = items.find( item => item.id === activeId )
    if (!item) {
      return
    }

    return itemRenderer({}, {}, item)
  }

  function handleDragEnd(event) {
    const { active, over } = event
    const { id } = active
    let overId = over ? over.id : null

    let updatedItems = items
    const activeIndex = updatedItems.findIndex( item => item.id === id )
    const overIndex = updatedItems.findIndex( item => item.id === overId )

    let newIndex = overIndex >= 0 ? overIndex : 0
    if (newIndex >= updatedItems.length) {
      newIndex = updatedItems.length - 1
    }

    if (activeIndex !== overIndex && overId && overId !== id) {
      updatedItems = arrayMove(items, activeIndex, newIndex)
      setItems(updatedItems)
    }

    setActiveId(null)
  }

  return (
    <DndContext
      screenReaderInstructions={ false }
      sensors={ sensors }
      onDragStart={ event => setActiveId(event.active.id) }
      onDragEnd={ handleDragEnd }
      strategy={ closestCenter }
      modifiers={[ restrictToVerticalAxis, restrictToParentElement ]}
    >
      <SortableContext
        id='root'
        items={ items.map( item => item.id ) }
        strategy={ verticalListSortingStrategy }
      >
        { items.map( (item, index) => (
          <SortableItem
            key={ item.id }
            item={ item }
            itemRenderer={ (a, b, c) => itemRenderer(a, b, c, index) }
          />
        ))}
      </SortableContext>
      { showDragOverlay &&
        <DragOverlay>
          { getDragOverlay() }
        </DragOverlay>
      }
    </DndContext>
  )
}









