import React, { useEffect, useState } from 'react'
import CreateButton from '../shared/CreateButton'
import Modal from '../shared/Modal'
import TextEditor from '../shared/TextEditor'
import SubmitButton from '../shared/SubmitButton'
import FormInput from '../shared/FormInput'
import DynamicEntityIndex from '../shared/DynamicEntityIndex'
import LanguageHandler from '../shared/LanguageHandler'

export default function Index({ 
  appProps, initialTotalPages, itemsPath, languages, selectedColumns, spaces
}) {
  const [showModal, setShowModal] = useState(false)
  const [spaceFields, setSpaceFields] = useState({
    width: 1280,
    height: 720,
  })
  const [usedLanguages, setUsedLanguages]       = useState([languages.find(language => language.code === appProps.currentLocale )])
  const [selectedLanguage, setSelectedLanguage] = useState(usedLanguages[0].code)
  const [translations, setTranslations]         = useState({ [selectedLanguage]: { title: '', description: '' }})
  const [renderKey, setRenderKey]               = useState(1)

  useEffect(() => {
    setRenderKey(Math.random())
  }, [selectedLanguage])
  
  const { flashMessage } = appProps

  const actions = [ 
    { url: item => `${itemsPath}/${item.apiUid}/edit`, icon: 'fa-pencil' }
  ]

  const translatableContent = [
    { items: translations, setItems: setTranslations, initialValues: { title: '', description: '' } },
  ]

  const handleTranslationChange = (input, key) => {
    const _translations = { ...translations }

    if (key === 'description') {
      _translations[selectedLanguage][key] = input
    } else {
      _translations[selectedLanguage][key] = input.target.value
    }

    setTranslations(_translations)
  }

  const extraRemoveLanguageCallback = locale => {
    setTranslations(prevState => {
      const nextState = { ...prevState }
      delete nextState[locale]
      return nextState
    })
  }

  const handleSpaceFieldChange = (e, key) => {
    setSpaceFields(prevState => ({
      ...prevState,
      [key]: e.target.value
    }))
  }

  const renderSpace = (space, columns) => {
    return (
      <>
      { columns.map( column => {
        const key = column.key

        if (key === 'active') {
          return (
            <div key={ column.key } className='item flex-1'>
              { space.active ? <i className='fa-light fa-circle-check'/> : <i className='fa-light fa-dash'/> }
            </div>
          )
        } else {
          return (
            <div key={ column.key } className='item flex-1'>
              <div>
                { space[key] || '-' }
              </div>
            </div>
          )
        }
      })}
    </>
    )
  }

  const handleSave = () => {
    const fd = new FormData()

    Object.keys(translations).forEach((locale, index) => {
      fd.append(`space[translations_attributes][${ index }][title]`, translations[locale].title || '')
      fd.append(`space[translations_attributes][${ index }][description]`, translations[locale].description || '')
      fd.append(`space[translations_attributes][${ index }][locale]`, locale)
    })

    fd.append(`space[width]`, spaceFields.width)
    fd.append(`space[height]`, spaceFields.height)

    Rails.ajax({
      type: 'POST',
      url: itemsPath,
      dataType: 'json',
      data: fd,
      success: res => {
        if (res.redirect_url) {
          location.href = res.redirect_url
        }
      },
      error: res => {
        flashMessage.show(res.error, 'error')
      }
    })
  }

  return (
    <>
      <CreateButton
        pageTitle={ Website.translations.spaces }
        count={ spaces.length }
        canCreate={ true }
        setShowModal={ setShowModal }
      />

      <DynamicEntityIndex
        appProps={ appProps }
        initialItems={ spaces }
        initialTotalPages={ initialTotalPages }
        selectAll={ true }
        disableCustomization={ true }
        disableSearch={ true }
        entity={ "spaces" }
        tabs={ [] }
        tableHeadings={ selectedColumns }
        itemRenderer={ renderSpace }
        itemsPath={ itemsPath }
        deletePath={ itemsPath }
        actions={ actions }
        noResourcesTitle={ Website.translations.noSpaces }
        columnTranslations={ Website.translations.columns }
      />

      { showModal && 
        <Modal
          visible={ showModal }
          closeModal={ () => setShowModal(false) }
          mode="xlarge"
        >
          <LanguageHandler
            languages={ languages }
            usedLanguages={ usedLanguages }
            setUsedLanguages={ setUsedLanguages }
            selectedLanguage={ selectedLanguage }
            setSelectedLanguage={ setSelectedLanguage }
            translatableContent={ translatableContent }
            extraRemoveLanguageCallback={ extraRemoveLanguageCallback }
          />

          <FormInput
            label={ Website.translations.columns.title }
            value={ translations[selectedLanguage]?.title || '' }
            onChange={ e => handleTranslationChange(e, 'title') }
          />

          <div className={ 'flex-box flex-column'}>
            <label className='fs-6 fw-semibold form-label mt-3'>
              { Website.translations.columns.description }
            </label>
            <TextEditor
              key={ renderKey }
              content={ translations[selectedLanguage]?.description || '' }
              setContent={ html => handleTranslationChange(html, 'description') }
            />
          </div>

          <FormInput
            label={ Website.translations.width }
            value={ spaceFields.width }
            onChange={ e => handleSpaceFieldChange(e, 'width') }
          />
          <FormInput
            label={ Website.translations.height }
            value={ spaceFields.height }
            onChange={ e => handleSpaceFieldChange(e, 'height') }
          />

          <div className='flex-box content-end'>
            <SubmitButton
              value={ Website.translations.create }
              className='btn btn-primary'
              onSubmit={ handleSave }
            />
          </div>
        </Modal>
      }
    </>
  )
}