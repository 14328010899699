import React, { useState } from 'react'
import Modal from '../shared/Modal'
import FormInput from '../shared/FormInput'
import ModalAlert from '../shared/ModalAlert'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from '@material-ui/pickers'
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import VerticalDraggableItems from '../shared/VerticalDraggableItems'
import EmptyGrid from '../shared/EmptyGrid'

export default function CustomSpaceStockItems({ 
  appProps, stockItems, stockFilterFunc, updateStockItems, space, defaultPrice, options, selectedLanguage, spaceSelectedColumns
}) {
  const initialZonePrices = space.zones.map(zone => ({ 
    zoneId: zone.id, 
    price: defaultPrice, 
    numberOfSeats: zone.mapping.number_of_available_seats,
  }))

  const initialZoneOptionPrices = space.zones.map(zone => options.map(option => ({ 
    zoneId: zone.id,
    price: option.price 
  })))

  const [showEditModal, setShowEditModal]     = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [itemToBeDeleted, setItemToBeDeleted] = useState(null)
  const [fields, setFields]                   = useState({
    id:       null,
    stockable: {
      id: null,
      date: moment(new Date()),
      slotFrom: moment('2000-01-01 12:00','YYYY-MM-DD h:mm a'),
      slotTo:   moment('2000-01-01 12:00','YYYY-MM-DD h:mm a'),
      type: 'Stock::CustomSpace',
      zonePrices: initialZonePrices,
      zoneOptionPrices: initialZoneOptionPrices,
      blockedZones: []
    },
    _destroy: null
  })

  function editActionOnClick(item) {
    const { stockable } = item

    setFields({
      id: item.id,
      stockable: stockable
    })
    setShowEditModal(true)
  }

  function deleteActionOnClick(item) {

    setShowDeleteModal(true)
    setItemToBeDeleted(item)
  }

  const handleDelete = _e => {
    const { id, stockable } = itemToBeDeleted

    const index = stockItems.findIndex(item => item.id === id)
    updateStockItems([
      ...stockItems.slice(0, index),
      {
        id: id,
        stockable: stockable,
        _destroy: true,
      },
      ...stockItems.slice(index + 1)
    ])
    setFields({
      id: null,
      stockable: {
        id: null,
        date: moment(new Date()),
        slotFrom: moment('2000-01-01 12:00','YYYY-MM-DD h:mm a'),
        slotTo: moment('2000-01-01 12:00','YYYY-MM-DD h:mm a'),
        type: 'Stock::CustomSpace',
        zonePrices: initialZonePrices,
        zoneOptionPrices: initialZoneOptionPrices,
        blockedZones: []
      },
      _destroy: null
    })
    setShowDeleteModal(false)
  }

  function renderStockItem(item, columns) {
    return (
      <>
        { columns.map( column => {
          const key = column.key
          let output
          if (['slotFrom', 'slotTo'].includes(key)) {
            output = typeof item.stockable[key] === 'object' ? moment(item.stockable[key]).format('h:mm a') : moment(new Date(`2000-01-01 ${ item.stockable[key] }`)).format('h:mm a')
          } else {
            output = moment(item.stockable[key]).format('DD/MM')
          }
          return (
            <div key={ key } className='item flex-1'>
              { output }
            </div>
          )
        })}
      </>
    )
  }

  const itemRenderer = (draggableAttributes, draggableListeners, item) => {
    return (
      <div className='flex-box' style={{ height: '60px', alignItems: 'center' }}>
        <div className='flex-box items-center' style={{ flexGrow: 0, flexShrink: 0, flexBasis: '10%' }}>
          <div className='m-r-20'>
            <i className='fa-light fa-grip-dots' style={{ cursor: 'grab' }} { ...draggableAttributes } { ...draggableListeners } />
          </div>
        </div>

        { renderStockItem(item, spaceSelectedColumns) }

        <div 
          className='flex-box items-center'
          style={{ flexGrow: 0, flexShrink: 0, flexBasis: '10%', gap: '10px' }}
        >
          <div 
            onClick={ _e => editActionOnClick(item) } 
            style={{ cursor: 'pointer' }}
            className='flex-1 flex-box content-center'
          >
            <i className='fa-solid fa-pencil' />
          </div>

          <div 
            onClick={ _e => deleteActionOnClick(item) } 
            style={{ cursor: 'pointer' }}
            className='flex-1 flex-box content-center'
          >
            <i className='fa-solid fa-trash' />
          </div>
        </div>
      </div>
    )
  }

  const handleSave = () => {
    const { id, stockable, _destroy } = fields
    const { slotFrom, slotTo, date, type, zonePrices, zoneOptionPrices, blockedZones } = stockable
    if (id) {
      const index = stockItems.findIndex(item => item.id === id)
      updateStockItems([
        ...stockItems.slice(0, index),
        {
          id: id,
          stockable: {
            id: stockable.id,
            date: date,
            slotFrom: slotFrom,
            slotTo: slotTo,
            type: type,
            zonePrices: zonePrices,
            zoneOptionPrices: zoneOptionPrices,
            blockedZones: blockedZones
          },
          _destroy: _destroy
        },
        ...stockItems.slice(index + 1)
      ])
    } else {
      updateStockItems([
        ...stockItems,
        {
          id: stockItems.reduce((acc, curr) => curr.id <= acc ? curr.id - 1 : acc, -1),
          stockable: {
            date: date,
            slotFrom: slotFrom,
            slotTo: slotTo,
            type: type,
            zonePrices: zonePrices,
            zoneOptionPrices: zoneOptionPrices,
            blockedZones: blockedZones
          }
        }
      ])
    }

    setShowEditModal(false)
    setFields({
      id: null,
      stockable: {
        id: null,
        date: moment(new Date()),
        slotFrom: moment('2000-01-01 12:00','YYYY-MM-DD h:mm a'),
        slotTo: moment('2000-01-01 12:00','YYYY-MM-DD h:mm a'),
        type: 'Stock::CustomSpace',
        zonePrices: initialZonePrices,
        zoneOptionPrices: initialZoneOptionPrices,
        blockedZones: []
      },
      _destroy: null
    })
  }

  const handleZonePriceChange = (e, index) => setFields(prevState => ({
    ...prevState,
    stockable: {
      ...prevState.stockable,
      zonePrices: [
        ...prevState.stockable.zonePrices.slice(0, index),
        { ...prevState.stockable.zonePrices[index], price: e.target.value },
        ...prevState.stockable.zonePrices.slice(index + 1)
      ]
    }
  }))

  const handleZoneOptionPriceChange = (e, index, optionIndex) => setFields(prevState => ({
    ...prevState,
    stockable: {
      ...prevState.stockable,
      zoneOptionPrices: [
        ...prevState.stockable.zoneOptionPrices.slice(0, index),
        [ 
          ...prevState.stockable.zoneOptionPrices[index].slice(0, optionIndex),
          {
            ...prevState.stockable.zoneOptionPrices[index][optionIndex],
            price: e.target.value 
          },
          ...prevState.stockable.zoneOptionPrices[index].slice(optionIndex + 1),
        ],
        ...prevState.stockable.zoneOptionPrices.slice(index + 1)
      ]
    }
  }))

  const handleToggleBlockedZone = zoneId => setFields(prevState => ({
    ...prevState,
    stockable: {
      ...prevState.stockable,
      blockedZones: prevState.stockable.blockedZones.includes(zoneId) 
        ? [...prevState.stockable.blockedZones.slice(0, prevState.stockable.blockedZones.indexOf(zoneId)), ...prevState.stockable.blockedZones.slice(prevState.stockable.blockedZones.indexOf(zoneId) + 1)]
        : [...prevState.stockable.blockedZones, zoneId]
    }
  }))

  const handleDateChange = newDate => setFields(prevState => ({ ...prevState, stockable: { ...prevState.stockable, date: newDate } }))

  const handleTimeChange = (time, key) => setFields(prevState => ({ ...prevState, stockable: { ...prevState.stockable, [key]: time } }))

  const handleCloseModal = _ => {
    setShowEditModal(false)

    setFields({
      id: null,
      stockable: {
        id: null,
        date: moment(new Date()),
        slotFrom: moment('2000-01-01 12:00','YYYY-MM-DD h:mm a'),
        slotTo: moment('2000-01-01 12:00','YYYY-MM-DD h:mm a'),
        type: 'Stock::CustomSpace',
        zonePrices: initialZonePrices,
        zoneOptionPrices: initialZoneOptionPrices,
        blockedZones: []
      },
      _destroy: null
    })
  }

  const renderEditModal = () => (
    <Modal
      visible={ showEditModal }
      closeModal={ handleCloseModal }
      mode="large"
      action={ handleSave }
      actionText={ Website.translations.ok }
    >
      <div className='m-b-40'>
        <h3 className='m-b-25'>{ Website.translations.timeSlot }</h3>
        <div className='p-l-20'>
          <MuiPickersUtilsProvider
            utils={ MomentUtils }
            locale={ 'en' }
          >
            <div className='flex-box' style={{ gap: '10px' }}>
              <DatePicker
                label = { Website.translations.date }
                value={ fields.stockable.date }
                onChange={ handleDateChange }
              />
              <TimePicker
                label={ Website.translations.from }
                timezone="Europe/Athens"
                value={ typeof fields.stockable.slotFrom === 'object' ? moment(fields.stockable.slotFrom) : moment(new Date(`2000-01-01 ${ fields.stockable.slotFrom }`)) }
                onChange={ time => handleTimeChange(time, 'slotFrom') }
              />
              <TimePicker
                label={ Website.translations.to }
                timezone="Europe/Athens"
                value={ typeof fields.stockable.slotTo === 'object' ? moment(fields.stockable.slotTo) : moment(new Date(`2000-01-01 ${ fields.stockable.slotTo }`)) }
                onChange={ time => handleTimeChange(time, 'slotTo') }
              />
            </div>
          </MuiPickersUtilsProvider>
        </div>
      </div>

      <div>
        <h3 className='m-b-25'>{ Website.translations.pricePerZone }</h3>
        { space.zones.map((zone, index) => (
          <div key={ index } className='p-l-20'>
            <div className='m-b-20'>
              <div className='flex-box items-center content-space-between'>
                <div style={{ fontSize: '1.05rem', fontWeight: 500}}>
                  { `${ zone.title }, ${ Website.translations.numberOfSeats } ${ zone.mapping.number_of_available_seats }` }
                </div>

                <div className="flex-box items-center content-space-between" style={{ gap: '10px' }}>
                  <label className='fs-6 fw-semibold form-label mt-3 flex-box items-center'>
                    <div>
                      { Website.translations.blockZone }
                    </div>
                    <div className='tooltip-button'>
                      <i className='fa fa-circle-exclamation' />
                      <div className='tooltip'>
                        { Website.translations.blockZoneTooltip }
                      </div>
                    </div>
                  </label>
                  <Toggle
                    icons={{ unchecked: null }}
                    checked={ fields.stockable.blockedZones.includes(zone.id) }
                    onChange={ _e => handleToggleBlockedZone(zone.id) }
                  />
                </div>
              </div>
              { options.length === 0 
                ? <FormInput
                    label={ Website.translations.columns.price }
                    value={ fields.stockable.zonePrices[index].price }
                    onChange={ e => handleZonePriceChange(e, index) }
                  />
                : <div className='p-l-20'>
                    { options.map((option, optionIndex) => (
                      <div key={ optionIndex }>
                        <FormInput
                          label={ option.translations.find(t => t.locale === selectedLanguage)?.title }
                          value={ fields.stockable.zoneOptionPrices[index][optionIndex]?.price }
                          onChange={ e => handleZoneOptionPriceChange(e, index, optionIndex) }
                        />
                      </div>
                    ))}
                  </div>
              }
            </div>
          </div>
        ))}
      </div>
    </Modal>
  )

  return (
    <>
      { showEditModal && renderEditModal() }

      <div className='flex-box items-center content-space-between m-b-30'>
        <h3>{ Website.translations.stock }</h3>
        <button className="btn btn-primary" onClick={ () => setShowEditModal(true) } >
          { Website.translations.createStockItem }
        </button>
      </div>

      { stockItems.filter(stockFilterFunc).length > 0 
        ? <div className='card'>
            <div className='card-content entity-index'>
              <div className='m-t-20' style={{ minHeight: 200 }}>
                <div>
                  <div className='flex-box content-space-between'>
                    <div style={{ flexGrow: 0, flexShrink: 0, flexBasis: '10%' }}></div>
                    { spaceSelectedColumns.map((tableHeading, index) => (
                      <div key={ index } className='bold flex-1'>
                        { Website.translations.columns[tableHeading.label] }
                      </div>
                    ))}
                    <div style={{ flexGrow: 0, flexShrink: 0, flexBasis: '10%' }}></div>
                  </div>
                  <VerticalDraggableItems
                    items={ stockItems.filter(stockFilterFunc) }
                    itemRenderer={ itemRenderer }
                    setItems={ updateStockItems }
                    showDragOverlay={ false }
                  />
                </div>
              </div>
            </div>
          </div> 
        : <>
            <EmptyGrid
              noResourcesTitle={ Website.translations.noStockItems }
            />
          </>
      }

      { showDeleteModal &&
        <ModalAlert
          alert={ Website.translations.areYouSure }
          onSelect={ handleDelete }
          onClose={ () => setShowDeleteModal(false) }
        />
      }
    </>
  )
}
