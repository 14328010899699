import React, { useState, useEffect, useRef } from 'react'
import SaveFixedButton from '../shared/SaveFixedButton'
import FormInput from '../shared/FormInput'
import CreateButton from '../shared/CreateButton'

export default function EditTerminal({ appProps, terminal, initialLocationGroups, initialLocations, initialTickets, updatePath, retrieveDataPath }) {
  const [saveButtonRef, setSaveButtonRef]           = useState(null)
  const [fields, setFields]                         = useState({ 
                                                        tid: terminal.tid, 
                                                        sn: terminal.sn,
                                                        deviceName: terminal.deviceName || '',
                                                        appVersion: terminal.appVersion || '',
                                                        codeVersion: terminal.codeVersion || ''
                                                      })
  const [locationGroups, setLocationGroups]         = useState(initialLocationGroups)
  const [locations, setLocations]                   = useState(initialLocations)
  const [tickets, setTickets]                       = useState(initialTickets)
  const [usedLocationGroups, setUsedLocationGroups] = useState(terminal.locationGroups)
  const [usedLocations, setUsedLocations]           = useState(terminal.locations)
  const [usedTickets, setUsedTickets]               = useState(terminal.tickets)
  const [showSelect, setShowSelect]                 = useState(terminal.showSelect)

  const initialFetch = !usedLocationGroups.length && !usedLocations.length && !usedTickets.length
  const ref = useRef(initialFetch)

  useEffect(() => {
    if (ref.current) {
      ref.current = false
    } else {
      fetchDataForSelect({ locationGroups: usedLocationGroups, locations: usedLocations })
    }
  }, [usedLocationGroups, usedLocations])

  function fetchDataForSelect({ locationGroups, locations } = {}) {
    const fd = new FormData()
    if (locationGroups.length) {
      fd.append('location_groups', JSON.stringify(locationGroups))
    }
    if (locations.length) {
      fd.append('locations', JSON.stringify(locations))
    }

    Rails.ajax({
      type: 'POST',
      url: retrieveDataPath,
      data: fd,
      dataType: 'json',
      success: res => {
        if (res.locations) {
          ref.current = true
          setLocations(res.locations)
          setUsedLocations(prevState => prevState.filter(id => res.locations.findIndex(item => item.id === id) !== -1))
        }
        if (res.tickets) {
          setTickets(res.tickets)
          setUsedTickets(prevState => prevState.filter(id => res.tickets.findIndex(item => item.id === id) !== -1))
        }
      }
    })
  }

  const handleChange = (key, e) => {
    setFields(prevState => ({
      ...prevState,
      [key]: e.target.value
    }))
    saveButtonRef.setSaveableStatus()
  }

  const handleLocationGroupsChange = e  => {
    setUsedLocationGroups(e.target.value)
    saveButtonRef.setSaveableStatus()
  }

  const handleLocationsChange = e => {
    setUsedLocations(e.target.value)
    saveButtonRef.setSaveableStatus()
  }

  const handleTicketsChange = e => {
    setUsedTickets(e.target.value)
    saveButtonRef.setSaveableStatus()
  }

  const handleCheckboxClick = e => {
    if (!e.target.checked) {
      saveButtonRef.setSaveableStatus();
    }

    setShowSelect(prevState => !prevState)
  }

  const save = _e => {
    const fd = new FormData()
    fd.append('terminal[tid]', fields.tid)
    fd.append('terminal[sn]', fields.sn)
    fd.append('terminal[device_name]', fields.deviceName)
    fd.append('terminal[app_version]', fields.appVersion)
    fd.append('terminal[code_version]', fields.codeVersion)

    if (showSelect) {
      let attributesIndex = 0

      usedTickets.forEach((ticket) => {
        fd.append(`terminal[terminal_entities_attributes]${attributesIndex}[entity_type]`, 'Ticket')
        fd.append(`terminal[terminal_entities_attributes]${attributesIndex}[entity_id]`, ticket)
        attributesIndex++
      })
      usedLocations.forEach((location) => {
        fd.append(`terminal[terminal_entities_attributes]${attributesIndex}[entity_type]`, 'Location')
        fd.append(`terminal[terminal_entities_attributes]${attributesIndex}[entity_id]`, location)
        attributesIndex++
      })
      usedLocationGroups.forEach((locationGroup) => {
        fd.append(`terminal[terminal_entities_attributes]${attributesIndex}[entity_type]`, 'LocationGroup')
        fd.append(`terminal[terminal_entities_attributes]${attributesIndex}[entity_id]`, locationGroup)
        attributesIndex++
      })
    }
    saveButtonRef.setSavingStatus()

    Rails.ajax({
      type: 'PATCH',
      url: updatePath,
      dataType: 'json',
      data: fd,
      success: res => {
        saveButtonRef.setSuccessStatus()
        if (res.redirect_url) {
          location.href = res.redirect_url
        }
      },
      error: res => {
        saveButtonRef.setErrorStatusWithMessage(res.error)
      }
    })
  }

  return (
    <>
      <CreateButton
        pageTitle={ terminal.sn }
        breadcrumbs={ appProps.breadcrumbs }
      />

      <div className='card'>
        <FormInput
          label={ Website.translations.columns.tid }
          value={ fields.tid }
          onChange={ e => handleChange('tid', e) }
        />
        <FormInput
          label={ Website.translations.columns.serialNumber }
          value={ fields.sn }
          onChange={ e => handleChange('sn', e) }
        />
        <FormInput
          label={ Website.translations.columns.deviceName }
          value={ fields.deviceName }
          onChange={ e => handleChange('deviceName', e) }
        />
        <FormInput
          label={ Website.translations.columns.appVersion }
          value={ fields.appVersion }
          onChange={ e => handleChange('appVersion', e) }
        />
        <FormInput
          label={ Website.translations.columns.codeVersion }
          value={ fields.codeVersion }
          onChange={ e => handleChange('codeVersion', e) }
        />
      </div>

      <div className='card'>
        <div className="card-header border-0">
          <div className="card-title m-0">
            { Website.translations.availableTicketsSelect }
          </div>
        </div>
        <div className='card-body'>
          <div className='flex-box'>
            <input
              type="checkbox"
              checked={ showSelect }
              onChange={ handleCheckboxClick }
            />
            <div className="small m-l-20">
              { Website.translations.availableTicketsDesc }
            </div>
          </div>
        </div>
      </div>

      { showSelect &&
        <>
          { locationGroups && locationGroups.length > 0 &&
            <FormInput
              label={ Website.translations.locationGroups }
              value={ usedLocationGroups }
              onChange={ handleLocationGroupsChange }
              type='select'
              multiple={ true }
              selectEntities={ locationGroups }
              keyAttr='id'
              valueAttr='id'
              outputAttr='title'
            />
          }
          { locations && locations.length > 0 &&
            <FormInput
              label={ Website.translations.locations }
              value={ usedLocations }
              onChange={ handleLocationsChange }
              type='select'
              multiple={ true }
              selectEntities={ locations }
              keyAttr='id'
              valueAttr='id'
              outputAttr='title'
            />
          }

          { tickets && tickets.length > 0 &&
            <FormInput
              label={ Website.translations.tickets }
              value={ usedTickets }
              onChange={ handleTicketsChange }
              type='select'
              multiple={ true }
              selectEntities={ tickets }
              keyAttr='id'
              valueAttr='id'
              outputAttr='title'
            />
          }

          { !locationGroups?.length && !locations?.length && !tickets?.length &&
            <div>
              { Website.translations.noTerminalSelectData }
            </div>
          }
        </>
      }

      <SaveFixedButton
        onSave={ save }
        entity={ Website.translations.terminal }
        ref={ ref => setSaveButtonRef(ref) }
      />
    </>
  )
}
