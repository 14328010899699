import React from 'react'
import FormInput from '../shared/FormInput'

export default function MerchantSubaccountCreateForm({ subaccountFields, handleFieldChange, keySubaccountDetails, subaccountSettings, handleSettingChange }) {

  const renderSetting = (keyDetail) => {
    const key = keyDetail.key
    const value = subaccountSettings.find(item => item.key === key).value
    const label = key.replace('_', ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')

    const formattedDetail = Object.entries(keyDetail.input_options).reduce((accumulator, currentElement) => {
      accumulator.push({ value: currentElement[0], output: currentElement[1] })
      return accumulator
    }, [])

    return (
      <div key={key}>
        { keyDetail.input_type === 'text' &&
          <FormInput
            label={ label }
            value={ value || '' }
            onChange={ e => handleSettingChange(key, e) }
          />
        }
        { keyDetail.input_type === 'select' &&
          <FormInput
            label={ label }
            value={ value }
            onChange={ e => handleSettingChange(key, e) }
            type='select'
            multiple={ false }
            selectEntities={ formattedDetail }
            keyAttr='value'
            valueAttr='value'
            outputAttr='output'
          />
        }
      </div>
    )
  }

  return (
    <>
      <FormInput
        label={ Website.translations.columns.merchantIdName }
        value={ subaccountFields.internalName }
        onChange={ e => handleFieldChange('internalName', e) }
      />
      <FormInput
        label={ Website.translations.columns.mid }
        value={ subaccountFields.mid }
        onChange={ e => handleFieldChange('mid', e) }
      />
      <FormInput
        label={ Website.translations.superadminEmail }
        value={ subaccountFields.email }
        onChange={ e => handleFieldChange('email', e) }
      />
      <div className='m-t-20'>
        <div>{ Website.translations.merchantSubaccountSettings }</div>
        <div>{ keySubaccountDetails.map(renderSetting) }</div>
      </div>
    </>
  )
}