import React, { useState } from "react"

export default function ForgotPassword({ appProps, formPath, title, subtitle, rememberable }) {
  const [email, setEmail] = useState('')

  const { flashMessage } = appProps

  function handleSubmit() {
    let fd = new FormData()
    fd.append('administrator[email]', email)
    const redirectUrl = window.location.href.includes('letmein') ? '/letmein/login' : '/admin/login'
    
    Rails.ajax({
      type: 'POST',
      url: formPath,
      dataType: 'json',
      data: fd,
      success: res => {
        flashMessage.show(res.success, 'success')
        setTimeout( ()=>{
          window.location.href = `${redirectUrl}`
        }, 1500)
      },
      error: res => {
        flashMessage.show(res.error, 'error')
      }
    })
  }

  return (
    <div id='kt_forgot_password_form' className="form w-100">
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3">{ title }</h1>
        <div className="text-gray-500 fw-semibold fs-6">{ subtitle }</div>
      </div>
      <div className="fv-row mb-8">
        <label htmlFor="email">{ Website.translations.email }</label>
        <input type='email' className='form-control bg-transparent' onChange={ (e)=> setEmail(e.target.value) } />
      </div>
      <div className="d-grid mb-10">
        <button type="submit" id="kt_sign_in_submit" className= "btn btn-primary" onClick={ ()=> handleSubmit() }>
          { Website.translations.resetPasswordButton }
        </button>
      </div>
    </div>
  )
}
