import React, { useState } from 'react'
import CreateButton  from '../shared/CreateButton'
import ReceiptDetails from '../receipts/ReceiptDetails'
import ModalAlert from '../shared/ModalAlert'

export default function Order({ appProps, order, orderType, initialOrderQrs, hasRelatedApiIntegration, relatedReceipt, relatedCreditReceipt, regenerateReceiptPath, cancelOrderPath, validationPath, canEdit }) {
  const [creditReceipt, setCreditReceipt] = useState(relatedCreditReceipt)
  const [currentOrder, setCurrentOrder] = useState(order)
  const [orderQrs, setOrderQrs] = useState(initialOrderQrs)
  const [showAlertModal, setShowAlertModal] = useState(false)
  const [qrForValidation, setQrForValidation] = useState(null)
  const [showValidationModal, setShowValidationModal] = useState(false)

  const { flashMessage } = appProps

  function validateTicket(qrValue, validateAnyway=false) {
    const fd = new FormData()
    fd.append('qr', qrValue)
    fd.append('order_api_uid', order.apiUid)
    if(validateAnyway){
      fd.append('validate_anyway', validateAnyway)
    }

    Rails.ajax({
      type: 'POST',
      url: validationPath,
      dataType: 'json',
      data: fd,
      success: res => {
        if(res.qrCode && !res.invalidDate){
          let tempQrs = [...orderQrs]
          let tempQr = tempQrs.find(qr => qr.qrCode == qrValue)
          tempQr.hasBeenValidated = true
          tempQr.validatedAt = res.qrCode.validatedAt
          tempQr.validator = res.qrCode.validator
          setOrderQrs(tempQrs)
          setQrForValidation(null)
          setShowValidationModal(false)
        } else if(res.invalidDate){
          setQrForValidation(qrValue)
          setShowValidationModal(true)
        }
      },
      error: res => {
        flashMessage.show(res.error, 'error')
        setQrForValidation(null)
        setShowValidationModal(false)
      }
    })
  }

  const renderItems = () => {
    let jsx

    if (orderType === 'Order') {
      jsx = (
        <>
          <div className='flex-box items-center content-space-between fw-bold m-b-20'>
            <div className='flex-1'>{ Website.translations.columns.title }</div>
            <div className='flex-1'>{ Website.translations.columns.quantity }</div>
            <div className='flex-1'>{ Website.translations.columns.price }</div>
          </div>
          { renderOrderItems() }
        </>
      )
    } else if (orderType === 'CustomSpaceOrder') {
      jsx = (
        <>
          <div className='flex-box items-center content-space-between fw-bold m-b-20'>
            <div className='flex-1'>{ Website.translations.columns.title }</div>
            <div className='flex-1'>{ Website.translations.zoneName }</div>
            <div className='flex-1'>{ Website.translations.seat }</div>
            <div className='flex-1'>{ Website.translations.columns.price }</div>
          </div>
          { renderCustomSpaceOrderItems() }
        </>
      )
    } else if (orderType === 'FerryOrder') {
      jsx = (
        <>
          <div className='flex-box items-center content-space-between fw-bold m-b-20'>
            <div className='flex-1'>Trip Id</div>
            <div className='flex-1'>{ Website.translations.ticket }</div>
            <div className='flex-1'>{ Website.translations.ticketNumber }</div>
            <div className='flex-1'>{ Website.translations.columns.price }</div>
          </div>
          { renderFerryOrderItems() }
        </>
      )
    }

    return jsx
  }

  const renderOrderItems = () => {
    return currentOrder.lineItems.map((item, index) => {
      const lineItemOutput =
          <div className='p-t-10 p-b-10 p-l-10 p-r-10 flex-box items-center content-space-between' style={ item.lineItemOptions.length > 0 ? { background: '#f9f9f9' } : {} }>
            <div className='flex-1'>{ item.sellableTitle }</div>
            <div className='flex-1'>{ item.quantity }</div>
            <div className='flex-1'>{`${ item.lineItemOptions.length === 0 ? item.totalPrice : '' }` }</div>
          </div>

      return (
        <div key={index} className='m-b-10'>
          <div>
            { lineItemOutput }
            { item.lineItemOptions.length > 0 && item.lineItemOptions.map((option, index) => {
              return (
                <React.Fragment key={ index }>
                  <div className='p-t-10 p-b-10 p-l-10 p-r-10 flex-box items-center content-space-between'
                       style={{ background: '#f9f9f9' }}>
                    <div className='flex-1'>{ option.ticketOptionTitle }</div>
                    <div className='flex-1'>{ option.quantity }</div>
                    <div className='flex-1'>{ option.totalPrice }</div>
                  </div>
                  { hasRelatedApiIntegration && option.customFields.length > 0 &&
                    <div  key={ index }
                          className='flex-box content-space-between flex-column m-t-5'
                          style={{ backgroundColor: '#f7f7f7', padding: '15px 10px', borderTop: '1px dashed #575757', borderBottom: '1px dashed #575757' }}>
                      <div className='fw-bold'>
                        { Website.translations.apiIntegrationItemInfo }
                      </div>
                      { renderLineItemOptionCustomFields(option) }
                    </div>
                  }
                </React.Fragment>
              )
            })}
          </div>
        </div>
      )
    })
  }

  const renderCustomSpaceOrderItems = () => {
    return currentOrder.lineItems.map((item, index) => (
        <div key={index} className='m-b-10'>
          <div>
            <div className='p-t-10 p-b-10 p-l-10 p-r-10 flex-box items-center content-space-between' style={ (item.lineItemOptions || []).length > 0 ? { background: '#f9f9f9' } : {} }>
              <div className='flex-1'>{ `${ item.sellableTitle }${ item.optionTitle ? ` (${ item.optionTitle })`: ''}` }</div>
              <div className='flex-1'>{ item.zoneTitle }</div>
              <div className='flex-1'>{ `${ item.seatDetails.row + 1 }-${ item.seatDetails.seat + 1 }` }</div>
              <div className='flex-1'>{ item.unitPrice }</div>
            </div>
          </div>
        </div>
      )
    )
  }

  const renderFerryOrderItems = () => {
    return currentOrder.lineItems.map((item, index) => (
      <div key={ index } className='m-b-10'>
        <div>
          <div className='p-t-10 p-b-10 p-l-10 p-r-10 flex-box items-center content-space-between'>
            <div className='flex-1'>{ item.customFields.find(el => el.key === 'trip_id')?.title }</div>
            <div className='flex-1'>{ calculateFerryLineItemTicketColumnText(item) }</div>
            <div className='flex-1'>{ `${ item.ticketLetter }${ item.ticketNumber }` }</div>
            <div className='flex-1'>{ item.price }</div>
          </div>
        </div>
      </div>
    ))
  }

  const calculateFerryLineItemTicketColumnText = item => {
    let text = `${ item.entityType }, ${ item.customFields.find(el => el.key === 'type')?.title }${ item.entityType === 'passenger' ? ', ' + item.customFields.find(el => el.key === 'class')?.title : '' }`

    if (item.customFields.find(el => el.key === 'loyalty_card')) {
      text += `, ${ Website.translations.loyaltyCard }: ${ item.customFields.find(el => el.key === 'loyalty_card')?.title }`
    }

    if (item.customFields.find(el => el.key === 'discount_code')) {
      text += `, ${ Website.translations.discountCode }: ${ item.customFields.find(el => el.key === 'discount_code')?.title }`
    }

    if (item.customFields.find(el => el.key === 'special_care')) {
      text += `, ${ Website.translations.specialCare }`
    }

    return text
  }

  const renderLineItemOptionCustomFields = option => {
    const formattedCustomFields = option.customFields.reduce((acc, customField) => {
      const lastSubArray = acc[acc.length - 1]
      if (lastSubArray.length === 0 || lastSubArray[0].key.slice(-1) === customField.key.slice(-1)) {
        lastSubArray.push(customField)
      } else {
        acc.push([customField])
      }
      return acc
    }, [[]])

    return (
      <>
        { formattedCustomFields.map((customFieldArray, index) =>
          <div className='flex-box content-space-between items-center m-b-10' key={index}>
            { customFieldArray.map((customField, idx) =>
              <div key={ idx } className='flex-1 flex-box flex-column items-start content-start'>
                <div className='fw-bold'>{`${ Website.translations[customField.key.slice(0, -2)] }`}</div>
                { customField.key.includes('barcode') ?
                  <img src={`data:image/png;base64, ${customField.title}`} style={{ objectFit: 'contain', width: 50, height: 50 }} />
                  :
                  <div>{ customField.title }</div>
                }
              </div>
            )}
          </div>
        )}
      </>
    )
  }

  const handleCancel = _e => {
    setShowAlertModal(false)
    const { apiUid } = currentOrder

    const fd = new FormData()
    fd.append('order_api_uid', apiUid)

    Rails.ajax({
      type: 'POST',
      url: cancelOrderPath,
      dataType: 'json',
      data: fd,
      success: res => {
        setCurrentOrder(res.order)
        setCreditReceipt(res.creditReceipt)

        flashMessage.show(res.success, 'success')
      },
      error: res => {
        flashMessage.show(res.error, 'error')
      }
    })
  }

  return (
    <>
      <CreateButton
        pageTitle={ `${ Website.translations.order } ${ currentOrder.number }` }
        breadcrumbs={ appProps.breadcrumbs }
      />

      <div>
        <div className='card m-b-30'>
          <div className="card-header border-0">
            <div className="flex-box items-center content-space-between" style={{ width: '100%' }}>
              <div className="card-title m-0">
                { Website.translations.orderDetails }
              </div>

              { canEdit && orderType !== 'FerryOrder' && currentOrder.status !== 'cancelled' &&
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={ _e => setShowAlertModal(true) }
                  >
                    { Website.translations.cancelOrder }
                  </button>
                </div>
              }
            </div>
          </div>
          <div className="card-body">
            <div className='flex-box items-center content-space-between m-b-10'>
              <div className='fw-bold'>{ Website.translations.columns.number }</div>
              <div>{ currentOrder.number }</div>
            </div>
            { orderType === 'FerryOrder' &&
              <div className='flex-box items-center content-space-between m-b-10'>
                <div className='fw-bold'>{ Website.translations.columns.bookingReference }</div>
                <div>{ currentOrder.bookingReference }</div>
              </div>
            }
            <div className='flex-box items-center content-space-between m-b-10'>
              <div className='fw-bold'>{ Website.translations.columns.total }</div>
              <div>{ orderType === 'FerryOrder' ? currentOrder.totalPrice : currentOrder.total }</div>
            </div>
            <div className='flex-box items-center content-space-between m-b-10'>
              <div className='fw-bold'>{ Website.translations.columns.status }</div>
              <div style={ currentOrder.status === 'cancelled' ? { color: 'red' } : {}}>{ currentOrder.status ? Website.translations[currentOrder.status] : Website.translations.pending }</div>
            </div>
            <div className='flex-box items-center content-space-between m-b-10'>
              <div className='fw-bold'>{ Website.translations.columns.createdAt }</div>
              <div>{ currentOrder.createdAt }</div>
            </div>
            { currentOrder.completedAt &&
              <div className='flex-box items-center content-space-between m-b-10'>
                <div className='fw-bold'>{ Website.translations.columns.completedAt }</div>
                <div>{ currentOrder.completedAt }</div>
              </div>
            }
            <div className='flex-box items-center content-space-between'>
              <div className='fw-bold'>{ Website.translations.columns.tid }</div>
              <div>{ currentOrder.terminalId }</div>
            </div>
          </div>
        </div>

        <div className='card m-b-30'>
          <div className="card-header border-0">
            <div className="card-title m-0">
              { Website.translations.orderItems }
            </div>
          </div>
          <div className="card-body">
            <div className='flex-box flex-column'>
              { renderItems() }
            </div>
          </div>
        </div>

        { orderQrs.length > 0 && ['Order', 'CustomSpaceOrder'].includes(orderType) &&
          <div className='card m-b-30'>
            <div className="card-header border-0">
              <div className="card-title m-0">
                { Website.translations.orderQrs }
              </div>
            </div>
            <div className="card-body">
              <div className='flex-box flex-column'>
                { orderQrs.map(qr => {
                  return (
                    <div className='flex-box items-center content-space-between p-t-20 p-l-10 p-r-30'>
                      <div>
                        { qr.qrCode }
                      </div>

                      <div>
                        { qr.hasBeenValidated ?
                          <div style={{ color: '#FFA500' }}>
                            { Website.translations.validated }
                          </div>
                          :
                          (
                            appProps.canManageValidations && order.status == 'completed' &&
                            <button
                              className="btn btn-primary"
                              onClick={() => validateTicket(qr.qrCode)}
                            >
                              { Website.translations.validate }
                            </button>
                          )
                        }
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        }

        { relatedReceipt != null &&
          <ReceiptDetails
            appProps={ appProps }
            receipt={ relatedReceipt }
            regeneratePath={ regenerateReceiptPath }
          />
        }

        { creditReceipt != null &&
          <ReceiptDetails
            appProps={ appProps }
            receipt={ creditReceipt }
          />
        }

        { orderType !== 'FerryOrder' &&
          <div className='card m-b-30'>
            <div className="card-header border-0">
              <div className="card-title m-0">
                { Website.translations.customerDetails }
              </div>
            </div>
            <div className="card-body">
              <div className='flex-box flex-column'>
                <div className='flex-box items-center content-space-between m-b-10'>
                  <div className='fw-bold'>{ Website.translations.columns.email }</div>
                  <div>{ currentOrder.email || '-' }</div>
                </div>
                <div className='flex-box items-center content-space-between m-b-10'>
                  <div className='fw-bold'>{ Website.translations.columns.phone }</div>
                  <div>{ currentOrder.phone || '-' }</div>
                </div>
              </div>
            </div>
          </div>
        }

        { orderType !== 'FerryOrder' && currentOrder.customFields.filter(field => field.key !== 'jwt').length > 0 &&
          <div className='card m-b-30'>
            <div className="card-header border-0">
              <div className="card-title m-0">
                { Website.translations.customFields }
              </div>
            </div>
            <div className="card-body">
              <div className='flex-box flex-column'>
                { currentOrder.customFields.filter(field => field.key !== 'jwt').map((item, index) => (
                  <React.Fragment key={index}>
                    <div className='flex-box items-center content-space-between m-b-10'>
                      <div className='fw-bold'>{ Website.translations[item.key] }</div>
                      <div>{ item.title }</div>
                    </div>
                    { !!item.description &&
                      <div className='flex-box items-center content-space-between m-b-10'>
                        <div>{ item.description }</div>
                      </div>
                    }
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        }

        { (currentOrder.apiIntegrationErrorLogs || []).length > 0 &&
          <div className='card m-b-30'>
            <div className="card-header border-0">
              <div className="card-title m-0">
                { Website.translations.apiIntegrationErrorLogs }
              </div>
            </div>
            <div className="card-body">
              <div className='flex-box flex-column'>
                { currentOrder.apiIntegrationErrorLogs.map((log, index) => (
                  <div key={ index } className='flex-box items-center content-space-between m-b-10'>
                    <div className='fw-bold'>{ `${ log.apiTitle } ${ log.key }` }</div>
                    <div>{ log.value }</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        }

      </div>

      { showAlertModal &&
        <ModalAlert
          alert={ Website.translations.cancelOrderAlert }
          onSelect={ handleCancel }
          onClose={ () => setShowAlertModal(false) }
        />
      }

      { showValidationModal && qrForValidation &&
        <ModalAlert
          alert={ Website.translations.invalidDate }
          onSelect={ () => validateTicket(qrForValidation, true) }
          onClose={ () => { setQrForValidation(null); setShowValidationModal(false) }}
        />
      }
    </>
  )
}
