import React, { useState } from 'react'
import FormInput from '../shared/FormInput'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import SaveFixedButton from '../shared/SaveFixedButton'
import CreateButton from '../shared/CreateButton'

export default function EditSeason({ appProps, currentSeason, updatePath }) {
  const { monthFrom, dayFrom, monthTo, dayTo } = currentSeason
  const dateFrom = monthFrom && dayFrom ? new Date(`${monthFrom}-${dayFrom}-${new Date().getFullYear()}`) : new Date()
  const dateTo = monthTo && dayTo ? new Date(`${monthTo}-${dayTo}-${new Date().getFullYear()}`) : new Date()

  const [season, setSeason] = useState(currentSeason)
  const [fields, setFields] = useState({
    title: season.title || '',
    dateFrom: dateFrom,
    dateTo: dateTo
  })
  const [saveButtonRef, setSaveButtonRef] = useState(null)

  const handleChange = (key, e) => {
    setFields(prevState => ({
      ...prevState,
      [key]: e.target.value
    }))
    saveButtonRef.setSaveableStatus()
  }

  const save = () => {
    const { title, dateFrom, dateTo } = fields
    const fd = new FormData()
    fd.append('season[title]', title)
    fd.append('season[month_from]', dateFrom.getMonth() + 1)
    fd.append('season[day_from]', dateFrom.getDate())
    fd.append('season[month_to]', dateTo.getMonth() + 1)
    fd.append('season[day_to]', dateTo.getDate())

    saveButtonRef.setSavingStatus()

    Rails.ajax({
      type: 'PATCH',
      url: updatePath,
      dataType: 'json',
      data: fd,
      success: res => {
        saveButtonRef.setSuccessStatus()
        setSeason(res.season)
      },
      error: res => {
        saveButtonRef.setErrorStatusWithMessage(res.error)
      }
    })
  }

  return (
    <>
      <CreateButton
        pageTitle={ currentSeason.title }
        breadcrumbs={ appProps.breadcrumbs }
      />
      <div className='card'>
        <div>{ Website.translations.seasonDescription }</div>
        <FormInput
          label={ Website.translations.seasonName }
          value={ fields.title }
          onChange={ e => handleChange('title', e) }
        />

        <div className='flex-box flex-column'>
          <label className='fs-6 fw-semibold form-label mt-3'>
            { Website.translations.timePeriods }
          </label>
          <div className='flex-box content-space-between' style={{ width: '30%' }}>
            <MuiPickersUtilsProvider
              utils={ MomentUtils }
              locale={ appProps.currentLocale }
            >
              <DatePicker
                label={ Website.translations.from }
                value={ fields.dateFrom }
                onChange={ newValue => handleChange('dateFrom', { target: { value: newValue.toDate() }})}
              />

              <DatePicker
                label={ Website.translations.to }
                value={ fields.dateTo }
                onChange={ newValue => handleChange('dateTo', { target: { value: newValue.toDate() }})}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <SaveFixedButton
          onSave={ save }
          entity={ Website.translations.season }
          ref={ ref => setSaveButtonRef(ref) }
        />
      </div>
    </>
  )
}
