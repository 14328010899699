import React, { useEffect } from 'react'

export default function TokenizedShow({ appProps, order, merchantLogo, additionalInformation, additionalPrintingInformation, showOnReceipt }) {
  const { lineItems } = order
  const { lineItemOptions } = lineItems[0]

  function printOrder() {
    window.print();
    return false;
  }

  useEffect( () => {
    setTimeout(() => {
      printOrder()
    }, 1000)
  },[])

  function additionalInfo(){
    if (additionalInformation && additionalInformation[0] && showOnReceipt){
      return additionalInformation.find(t => t.locale === appProps.currentLocale)?.title;
    }
    return null
  }

  function additionalPrintingInfo(){
    if (additionalPrintingInformation){
      return additionalPrintingInformation.find(t => t.locale === appProps.currentLocale)?.title;
    }
    return null
  }

  const renderLineItems = () => {
    let output = []
    const barcodeFields = lineItems[0].customFields.filter(field => field.key.startsWith('barcode'))
    const qrCodes = lineItems[0].qrCodes

    for (let i = 0; i < lineItems[0].quantity; i++) {
      const relevantBarcode = barcodeFields.find(field => field.key === `barcode_${i}`)
      output.push(
        <div className="flex-box flex-column simple-ticket-wrapper">
          { merchantLogo &&
            <>
            <div className='ticket-logotype'>
              <img src={ merchantLogo } />
            </div>
            {
              additionalInfo() &&
              <div className='ticket-information-item'>
                { additionalInfo() }
              </div>
            }
            </>
          }
          <div className="flex-box flex-column">
            <div>
              { lineItems[0].sellableTitle }
            </div>
            <div>
              { lineItems[0].unitPrice }
            </div>

            {
              (lineItems[0].selectedTimeFrom && lineItems[0].selectedTimeTo) ?
              <div>
                { `${ lineItems[0].selectedDate }, ${ lineItems[0].selectedTimeFrom } - ${ lineItems[0].selectedTimeTo }` }
              </div>
              :
              <div>
                { `${ lineItems[0].selectedDate }` }
              </div>
            }

          </div>

          { barcodeFields.length > 0 &&
            renderBarcode(relevantBarcode)
          }

          { qrCodes.length > 0 && qrCodes[i] &&
            renderBarcode(qrCodes[i])
          }

          {
            additionalPrintingInfo() &&
            <div className='ticket-information-item' style={{ marginTop: 30, fontSize: 17 }}>
              { additionalPrintingInfo() }
            </div>
          }
        </div>
      )
    }

    return output
  }

  const renderLineItemOptions = () => {
    return (
      lineItemOptions.map(option => {
        let output = []
        const barcodeFields = option.customFields.filter(field => field.key.startsWith('barcode'))

        for (let i = 0; i < option.quantity; i++) {
          const relevantBarcode = barcodeFields.find(field => field.key === `barcode_${i}`)
          const qrCodes = option.qrCodes
          output.push(
            <div className='flex-box flex-column options-ticket-wrapper' style={ (lineItemOptions.length > 0 && lineItemOptions[0].quantity > 1) ? { flex: 'initial' } : { flex: 1 } }>
              { merchantLogo &&
                <>
                <div className='ticket-logotype'>
                  <img src={ merchantLogo } />
                </div>
                {
                  additionalInfo() &&
                  <div className='ticket-information-item'>
                    { additionalInfo() }
                  </div>
                }
                </>
              }
              <div className='ticket-title'>
                { lineItems[0].sellableTitle }
              </div>

              <div className="flex-box flex-column ticket-information">
                <div className='ticket-information-item'>
                  { option.ticketOptionTitle }
                </div>
                <div className='ticket-information-item'>
                  { option.unitPrice }
                </div>

                {
                  (lineItems[0].selectedTimeFrom && lineItems[0].selectedTimeTo) ?
                  <div className='ticket-information-item'>
                    { `${ lineItems[0].selectedDate }, ${ lineItems[0].selectedTimeFrom } - ${ lineItems[0].selectedTimeTo }` }
                  </div>
                  :
                  <div className='ticket-information-item'>
                    { `${ lineItems[0].selectedDate }` }
                  </div>
                }

              </div>

              { barcodeFields.length > 0 &&
                renderBarcode(relevantBarcode)
              }

              { qrCodes.length > 0 && qrCodes[i] &&
                renderBarcode(qrCodes[i])
              }

              {
                additionalPrintingInfo() &&
                <div className='ticket-information-item' style={{ marginTop: 30, fontSize: 17 }}>
                  { additionalPrintingInfo() }
                </div>
              }
            </div>
          )
        }

        return output
      })
    )
  }

  const renderBarcode = barcode => ( // and qr code
    <div className="flex-box flex-column">
      <div>
        <img style={{ width: '100%', height: 100, objectFit: 'contain' }} src={ `data:image/png;base64,${ barcode.title }` } />
      </div>
      <div style={{ textAlign: 'center' }}>
        { barcode.readableBarcode }
      </div>
    </div>
  )

  return (
    <div className='tokenized-link-wrapper flex-box flex-column items-center'>
      <div className='ticket-container flex-box flex-column items-center flex-1'>
        <div className='ticket-screen-title flex-box flex-column items-center'>
          <div>{ Website.translations.yourTickets }</div>
          <div className='ticket-screen-subtitle'>{ `${ Website.translations.columns.number }: ${ order.number }` }</div>
        </div>
        { (lineItemOptions.length === 0)
          ? <div className='flex-box flex-wrap items-center tickets-list' style={{ width: '100%' }}>{ renderLineItems() }</div>
          : <div className='flex-box flex-wrap items-center tickets-list' style={{ width: '100%' }}>{ renderLineItemOptions() }</div>
        }
      </div>

      <div className="print-tickets-wrapper pointer flex-box items-center content-center" onClick={ ()=> printOrder() }>
        <i className='fa fa-cloud-arrow-down' />
      </div>
    </div>
  )
}
