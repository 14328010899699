import React, { useState } from 'react'
import DynamicEntityIndex from '../shared/DynamicEntityIndex'
import CreateButton from '../shared/CreateButton'

const tableHeadings = [
  { key: 'source', label: 'source' },
  { key: 'fileName', label: 'fileName' },
  { key: 'mediaType', label: 'mediaType' },
  { key: 'size', label: 'size' },
  { key: 'createdAt', label: 'createdAt' }
]

const columnTranslations = {
  source: Website.translations.media.file,
  fileName: Website.translations.media.fileName,
  mediaType: Website.translations.media.type,
  size: Website.translations.media.size
}

export default function Index({ appProps, initialFiles, initialTotalPages, itemsPath, deletePath }) {

  const { flashMessage } = appProps
  const actions = [
    { url: item => item.source, onClick: (event, item) => previewItem(event, item), icon: 'fa-eye' },
    { url: item => item.source, onClick: (event, item) => copyToClipboard(event, item), icon: 'fa-copy' }
  ]

  function copyToClipboard(event, item) {
    event.preventDefault()
    const el = document.createElement('textarea')
    el.visibility = 'hidden'
    el.value = item.source
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    flashMessage.show(Website.translations.copied, 'success')
  }

  function previewItem(event, item) {
    event.preventDefault()
    window.open(`${item.source}`, '_blank')
  }

  function itemRenderer(item, columns) {
    return (
      <>
        { columns.map( column => (
          <div key={ column.key } className='item flex-1'>
            { column.label === 'source' ?
              <img
                className='table-image'
                src={ item.source }
                alt={ item.fileName }
              />
            :
              item[column.key] || '-'
            }
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      <CreateButton
        pageTitle={ Website.translations.media.files }
        breadcrumbs={ appProps.breadcrumbs }
      />
      <DynamicEntityIndex
        appProps={ appProps }
        initialItems={ initialFiles }
        initialTotalPages={ initialTotalPages }
        disableCustomization
        entity='media'
        tableHeadings={ tableHeadings }
        itemRenderer={ itemRenderer }
        itemsPath={ itemsPath }
        deletePath={ deletePath }
        noResourcesTitle={ Website.translations.media.noFiles }
        actions={ actions }
        columnTranslations={ columnTranslations }
      />
    </>
  )
}
