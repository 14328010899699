import React, { useState } from 'react'
import Modal from '../Modal'
import { MenuItem, Select } from '@material-ui/core'

export default function AddTranslationLanguageModal(props){
  const [selectedLang, setSelectedLang] = useState('')
  const languagePoolCodes = props.languagePool.map(l => l.code)

  function handleSelect() {
    if (selectedLang) {
      props.handleLanguageSelected(selectedLang)
    }
  }

  return(
    <Modal
      mode='small'
      visible={ props.isOpen }
      closeModal={ props.onClose }
      cancelAction={ props.onClose }
      cancelActionText={ Website.translations.closeAction }
      action={ handleSelect }
      actionText={ Website.translations.addAction }
      disableAction={ !selectedLang }
    >
      <div className='input-field'>
        <label>
          { Website.translations.languageSelectPlaceholder }
        </label>

        <Select
          MenuProps={{ style: { zIndex: 99999 } }}
          onChange={ event => setSelectedLang(event.target.value) }
          value={ languagePoolCodes.includes(selectedLang) ? selectedLang : '' }
        >
          { props.languagePool.map( (language, index) => (
            <MenuItem value={ language.code } key={ index }>
              <div className='flex-box'>
                { language.presentation }
              </div>
            </MenuItem>
          ))}
        </Select>
      </div>
    </Modal>
  )
}