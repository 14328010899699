import React from 'react'

export default function EntityTile({ appProps, tiles }) {
  return (
    <div className='flex-box flex-wrap content-space-between'>
      { tiles.map( (tile, index)=> {
        if(tile.enabled){
          return (
            <div key={ index } className="col-md-6 col-xl-6" style={{ padding: 10 }}>
              <a href={ tile.path } className="card border-hover-primary">
                <div className="card-header border-0 pt-9">
                  <div className="card-title m-0">
                    <div className="symbol symbol-50px w-50px bg-light">
                      <i style={{ fontSize: 50 }} className={`fa-solid fa-${tile.icon} `} />
                    </div>
                  </div>
                </div>
                <div className="card-body p-9">
                  <div className="fs-3 fw-bold text-dark">{ tile.title }</div>
                  <p className="text-gray-400 fw-semibold fs-5 mt-1 mb-7">{ tile.description }</p>
                  <div className="d-flex flex-wrap mb-5">
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
                      <div className="fs-6 text-gray-800 fw-bold">{ tile.count }</div>
                      <div className="fw-semibold text-gray-400">{ tile.title }</div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          )
        }
      })}
    </div>
  )
}
