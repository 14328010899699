import React, { useState } from "react"

export default function EditPassword({ appProps, formPath, title, subtitle, rememberable, editPasswordType }) {
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')

  const { flashMessage } = appProps

  function handleSubmit() {
    let fd = new FormData()
    fd.append('administrator[password]', password)
    fd.append('administrator[password_confirmation]', passwordConfirm)
    fd.append(`administrator[${editPasswordType}_token]`, window.location.search.split('=')[1])

    Rails.ajax({
      type: 'PATCH',
      url: formPath,
      dataType: 'json',
      data: fd,
      success: res => {
        flashMessage.show(res.success, 'success')
        if (res.redirectUrl) {
          setTimeout(() => {
            location.href = res.redirectUrl
          }, 1500)
        }
      },
      error: res => {
        flashMessage.show(res.error, 'error')
      }
    })
  }

  return (
    <div id='kt_edit_password_form' className="form w-100">
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3">{ title }</h1>
        <div className="text-gray-500 fw-semibold fs-6">{ subtitle }</div>
      </div>
      <div className="fv-row mb-8">
        <label htmlFor="password">{ Website.translations.password }</label>
        <input
          type='password'
          placeholder={ Website.translations.password }
          className='form-control bg-transparent'
          value={ password }
          onChange={ (e)=> setPassword(e.target.value) }
        />
      </div>
      <div className="fv-row mb-8">
        <label htmlFor="password">{ Website.translations.passwordConfirm }</label>
        <input
          type='password'
          placeholder={ Website.translations.passwordConfirm }
          className='form-control bg-transparent'
          value={ passwordConfirm }
          onChange={ (e)=> setPasswordConfirm(e.target.value) }
        />
      </div>
      <div className="d-grid mb-10">
        <button type="submit" id="kt_sign_in_submit" className= "btn btn-primary" onClick={ ()=> handleSubmit() }>
          { Website.translations.confirm }
        </button>
      </div>
    </div>
  )
}
