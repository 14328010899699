import React, { useState, useEffect, useRef } from 'react'

export default function DropMenu({ item, dropMenuItems, selectedItem, setSelectedItem }) {
  const [isSelected, _setIsSelected] = useState(selectedItem ? ((item.id && item.id === selectedItem) || (!item.id && item.uid && item.uid === selectedItem)) : false)
  const isSelectedRef = useRef(isSelected)
  const didMountRef = useRef(false)

  useEffect( ()=> {
    document.addEventListener('keyup', event => {
      if (event.key === 'Escape') {
        if (isSelectedRef.current) {
          setIsSelected(false)
        }
      }
    })
  }, [])

  useEffect( () => {
    if (didMountRef.current && selectedItem) {
      if ((item.id && selectedItem !== item.id) || (!item.id && item.uid && selectedItem !== item.uid)) {
        setIsSelected(false)
      }
    } else {
      didMountRef.current = true
    }
  }, [selectedItem])

  function setIsSelected(state) {
    if (state && setSelectedItem) {
      setSelectedItem(item.id || item.uid)
    }
    _setIsSelected(state)
    isSelectedRef.current = state
  }

  return (
    <div className='quick-drop-menu-wrapper flex-box content-center'>
      <div
        className={ `quick-drop-menu-wrapper-overlay ${ isSelected ? 'opened' : 'closed' }` }
        onClick={ () => setIsSelected(false) }
      />
      <div className='link quick-drop-menu' onClick={ () => setIsSelected(!isSelected) }>
        <i className='fa-solid fa-ellipsis-vertical'/>
      </div>

      <div className={ `drop-menu ${ isSelected ? 'opened' : 'closed' } animated` }>
        { dropMenuItems && dropMenuItems.map( (dropMenuItem, index) => {
          if (!dropMenuItem.hidden || (dropMenuItem.hidden && !dropMenuItem.hidden(item))) {
            return (
              <a
                key={ index }
                href={ dropMenuItem.url ? dropMenuItem.url(item) : '' }
                target={ dropMenuItem.target || '_self' }
                onClick={ event => dropMenuItem.onClick ? dropMenuItem.onClick(event, item) : {} }
              >
                { dropMenuItem.icon &&
                  <i className={ `fa-solid ${ dropMenuItem.icon }` }/>
                }
                <div className='flex-1'>
                  { dropMenuItem.title }
                </div>
              </a>
            )
          } else {
            return null
          }
        })}
      </div>
    </div>
  )
}