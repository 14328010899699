import React from "react"

export default class EmptyGrid extends React.Component {

  render() {
    const { iconName, noResourcesTitle, redirectLocation, onReload, disableReload } = this.props

    function handleReload(event) {
      if (onReload) {
        event.preventDefault()
        onReload()
      }
    }

    return (
      <div className="flex-box flex-column items-center content-center">
        <div className="col s12 center-align m-t-20">
          <i style={{ fontSize: 80 }} className={`fa-solid ${iconName}`}></i>
          <h5>{noResourcesTitle}</h5>
        </div>

        { !disableReload &&
          <div className='redirect-back flex-box items-center content-center'>
            <a
              className='btn btn-primary'
              href={ redirectLocation || window.location.href }
              onClick={ handleReload }
            >
              <i style={{ margin: 0 }} className={`fa-solid fa-arrow-rotate-right`}></i>
            </a>
          </div>
        }
      </div>
    )
  }
}
