import React, { useState, useEffect, useRef, lazy, Suspense } from 'react'
import Header from './shared/Header'
import Footer from './shared/Footer'
import Sidenav from './shared/Sidenav'
import FlashMessage from './shared/FlashMessage'

let PageContent = () => <div />

export default function App(props) {
  const { appProps, componentPath } = props
  const [currentAppProps, _setCurrentAppProps] = useState(appProps)
  const [renderKey, setRenderKey] = useState(0)

  const currentAppPropsRef = useRef(currentAppProps)
  const flashMessageRef = useRef(null)

  const updatedAppProps = {
    ...currentAppPropsRef.current,
    setCurrentAppProps: setCurrentAppProps,
    flashMessage: flashMessageRef && flashMessageRef.current
  }

  useEffect( () => {
    PageContent = lazy( () => import(`./${ componentPath }`) )
    setTimeout( () => setRenderKey(Math.random()), 500)
  },[])

  function setCurrentAppProps(state) {
    _setCurrentAppProps( prevState => {
      const updatedState = { ...prevState, ...state }
      currentAppPropsRef.current = updatedState
      return updatedState
    })
  }

  return (
    <>
      <div
        id="kt_app_body"
        className="app-default"
        style={{ minHeight: '100vh', height: '100%', paddingTop: 30 }}
        data-bs-theme="light"
        data-kt-app-layout="dark-sidebar"
        data-kt-app-header-fixed="true"
        data-kt-app-sidebar-enabled="true"
        data-kt-app-sidebar-fixed="true"
        data-kt-app-sidebar-hoverable="true"
        data-kt-app-sidebar-push-header="true"
        data-kt-app-sidebar-push-toolbar="true"
        data-kt-app-sidebar-push-footer="true"
        data-kt-app-toolbar-enabled="true"
        data-kt-scrolltop="on"
      >
        <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
      		<div className="app-page flex-column flex-column-fluid" id="kt_app_page">
            <Header appProps={ updatedAppProps }/>

            <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
              <Sidenav appProps={ appProps } />

              <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                <div className="d-flex flex-column flex-column-fluid">
                  <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                      <Suspense key={ `${ renderKey }` } fallback={ <div/> }>
                        <PageContent
                          { ...props }
                          appProps={ updatedAppProps }
                        />
                      </Suspense>
                    </div>
                  </div>
                </div>
              </div>

              <Footer appProps={ updatedAppProps }/>
            </div>
          </div>
        </div>
      </div>

      <FlashMessage ref={ flashMessageRef }/>
    </>
  )
}
