import React, { useEffect, useState, useRef } from 'react'
import CreateButton from '../shared/CreateButton'
import Modal from '../shared/Modal'
import LanguageHandler from '../shared/LanguageHandler'
import FormInput from '../shared/FormInput'
import SubmitButton from '../shared/SubmitButton'
import DeleteSelected from '../shared/DeleteSelected'
import VerticalDraggableItems from '../shared/VerticalDraggableItems'
import EmptyGrid from '../shared/EmptyGrid'

export default function LocationGroups({
  appProps, initialGroups, itemsPath, deletePath, updatePositionsPath,
  disableDelete, canCreate, canEdit, selectedColumns, languages
}) {
  const [groups, setGroups]                       = useState(initialGroups)
  const [showModal, setShowModal]                 = useState(false)
  const [usedLanguages, setUsedLanguages]         = useState([languages.find(language => language.code === appProps.currentLocale )])
  const [selectedLanguage, setSelectedLanguage]   = useState(usedLanguages[0].code)
  const [translations, setTranslations]           = useState({ [selectedLanguage]: { title: '' }})
  const [selectedItems, setSelectedItems]         = useState([])
  const skipUpdatePositions = useRef(true)

  const { flashMessage } = appProps

  useEffect(() => {
    if (skipUpdatePositions.current) {
      skipUpdatePositions.current = false
      return;
    }

    const fd = new FormData()
    fd.append('location_groups', JSON.stringify(groups.map((group, index) => ({ id: group.id, position: index }))))

    Rails.ajax({
      type: 'PATCH',
      url: updatePositionsPath,
      dataType: 'json',
      data: fd,
      success: res => {
        flashMessage.show(res.success, 'success')
      },
      error: res => {
        skipUpdatePositions.current = true
        setGroups(res.groups)
        flashMessage.show(res.error, 'error')
      }
    })
  }, [groups])

  const onDelete = () => {
    setGroups(groups.filter( group => !selectedItems.includes(group.id) ))
    setSelectedItems([])
  }

  const onSelect = (checked, itemId) => {
    if (checked) {
      setSelectedItems([...selectedItems, itemId])
    } else {
      setSelectedItems(selectedItems.filter( item => item !== itemId ))
    }
  }

  const handleTitleChange = e => {
    const newTranslation = { [selectedLanguage]: { title: e.target.value } }
    setTranslations(prevState => ({ ...prevState, ...newTranslation }))
  }

  const extraRemoveLanguageCallback = locale => {
    setTranslations(prevState => {
      const nextState = { ...prevState }
      delete nextState[locale]
      return nextState
    })
  }

  const handleSubmit = e => {
    e.preventDefault()

    const fd = new FormData()
    Object.keys(translations).forEach((locale, index) => {
      fd.append(`location_group[translations_attributes][${ index }][title]`, translations[locale].title || '')
      fd.append(`location_group[translations_attributes][${ index }][locale]`, locale)
    })

    Rails.ajax({
      type: 'POST',
      url: itemsPath,
      dataType: 'json',
      data: fd,
      success: res => {
        if (res.redirect_url) {
          location.href = res.redirect_url
        }
      },
      error: res => {
        flashMessage.show(res.error, 'error')
      }
    })
  }

  function renderGroup(group, columns) {
    return (
      <>
        { columns.map( column => {
          const key = column.key
          return (
            <div key={ column.key } className='item' style={{ flexGrow: 9 }}>
              <div>
                { group[key] }
              </div>
            </div>
          )
        })}
      </>
    )
  }

  const itemRenderer = (draggableAttributes, draggableListeners, item) => {
    return (
      <div className='flex-box' style={{ height: '60px', alignItems: 'center' }}>
        <div className='flex-box items-center' style={{ flexGrow: 0, flexShrink: 0, flexBasis: '10%' }}>
          <div className='m-r-20'>
            <i className='fa-light fa-grip-dots' style={{ cursor: 'grab' }} { ...draggableAttributes } { ...draggableListeners } />
          </div>
          <label>
            <input
              type='checkbox'
              checked={ !!selectedItems.find( selectedItem => selectedItem === item.id ) }
              onChange={ event => onSelect(event.target.checked, item.id) }
              style={ !(!disableDelete && !item.disableDelete) ? { visibility: 'hidden' } : {} }
              disabled={ !(!disableDelete && !item.disableDelete) }
            />
            <span/>
          </label>
        </div>

        { renderGroup(item, selectedColumns) }

        <div style={{ flexGrow: 0, flexShrink: 0, flexBasis: '10%' }}>
          { canEdit && 
            <a href={ `${itemsPath}/${item.apiUid}/edit` }>
              <i className='fa-solid fa-pencil' />
            </a>
          }
        </div>
      </div>
    )
  }

  return (
    <>
      { showModal &&
        <Modal
          visible={ showModal }
          closeModal={() => setShowModal(false)}
          mode="small"
        >
          <LanguageHandler
            languages={ languages }
            usedLanguages={ usedLanguages }
            setUsedLanguages={ setUsedLanguages }
            selectedLanguage={ selectedLanguage }
            setSelectedLanguage={ setSelectedLanguage }
            translatableContent={ [] }
            extraRemoveLanguageCallback={ extraRemoveLanguageCallback }
          />
          <FormInput
            label={ Website.translations.columns.title }
            value={translations[selectedLanguage]?.title || ''}
            onChange={handleTitleChange}
          />
          <div className='flex-box content-end'>
            <SubmitButton
              value={ Website.translations.save }
              className='btn btn-primary'
              onSubmit={ handleSubmit }
            />
          </div>
        </Modal>
      }

      <CreateButton
        pageTitle={ Website.translations.locationGroups }
        breadcrumbs={ appProps.breadcrumbs }
        canCreate={ canCreate }
        setShowModal={ setShowModal }
      />

      { groups.length > 0 
        ? <div className='card'>
            <div className='card-content entity-index'>
              <div className='flex-box flex-wrap items-center content-space-between entity-index-heading'>
                { selectedItems.length > 0 &&
                  <div className='m-r-15'>
                    <DeleteSelected
                      appProps={ appProps }
                      entity="location_groups"
                      selectedItems={ selectedItems }
                      deletePath={ deletePath }
                      onDelete={ onDelete }
                    />
                  </div>
                }
              </div>

              <div className='m-t-20' style={{ minHeight: 200 }}>
                <div>
                  <div className='flex-box'>
                  <div className='heading delete-all-wrapper flex-box items-center' style={{ flexGrow: 0, flexShrink: 0, flexBasis: '10%' }}>
                    <label className='m-l-30'>
                      <button type='button'>
                        <input 
                          type='checkbox'
                          checked={ selectedItems.length === groups.length }
                          onChange={ event => setSelectedItems(event.target.checked ? groups.map( item => item.id ) : []) }
                        />
                        <span />
                      </button>
                    </label>
                  </div>

                  { selectedColumns.map( (tableHeading, index) => (
                    <div key={ index } className='bold'>
                      { Website.translations.columns[tableHeading.label] }
                    </div>
                  ))}
                  <div style={{ flexGrow: 0, flexShrink: 0, flexBasis: '10%' }}></div>
                  </div>
                  <VerticalDraggableItems
                    items={ groups }
                    itemRenderer={ itemRenderer }
                    setItems={ setGroups }
                    showDragOverlay={ false }
                  />
                </div>
              </div>
            </div>
          </div> 
        : <>
            <EmptyGrid
              noResourcesTitle={ Website.translations.noLocationGroups }
            />
          </>
      }
    </>
  )
}