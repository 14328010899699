import React from 'react'
import ReactDOM from 'react-dom'
import FlashMessages from './FlashMessages'

export default class MediaUploader extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      files: [],
      progress: 0
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.files.length && this.state.files.length) {
      this.uploadMedia()
    }
  }

  pushFilesToUpload(files) {
    this.setState({ files: [...this.state.files, ...files] })
  }

  isUploading(mediaSourceId = null) {
    if (mediaSourceId) {
      return !!this.state.files.find( (file, index) => file.mediaSourceId === mediaSourceId && index >= this.state.progress )
    }

    return !!this.state.files.length
  }

  uploadMedia(progress = this.state.progress) {
    const file = this.state.files[progress]

    if (file) {
      this.createMediaFile(file, progress)
    }
  }

  createMediaFile(file, progress) {
    const fd = new FormData()
    fd.append('file', file.blob)

    Rails.ajax({
      type: 'POST',
      url: this.props.mediaPath,
      dataType: 'json',
      data: fd,
      success: result => {
        file.onUpload({ ...file, ...result.media, uploadUid: file.uid })
      },
      error: result => {
        let target = document.getElementById('flash-message-ajax-container')
        if (result.error && target) {
          ReactDOM.render(<FlashMessages flashMessage={{ failure: result.error }}/>, target)
        }
      },
      complete: result => {
        if (progress < (this.state.files.length - 1)) {
          this.setState({ progress: progress + 1 })
          this.uploadMedia(progress + 1)
        } else {
          this.setState({ files: [], progress: 0 })
        }
      }
    })
  }

  render() {
    const { files, progress } = this.state

    if (files.length) {
      return (
        <div className='media-uploader-wrapper'>
          <div className='media-uploader-title'>
            { Website.translations.media.uploading } { progress + 1 }/{ files.length }
          </div>

          <div className='media-uploader-bar'>
            <div
              className='media-uploader-progress background-color-scheme'
              style={{ width: `${ ((progress + 1) / files.length) * 100 }%` }}
            />
          </div>
        </div>
      )

    } else {
      return null
    }
  }
}
