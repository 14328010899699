import React from 'react'
import { getPaginationOptions } from './utils'

export default function Pagination({ currentPage, totalPages, changePage }) {
  const paginationOptions = getPaginationOptions(currentPage, totalPages)

  return (
    <div className='page-numbers-wrapper flex-box items-center content-center m-t-30'>
      { paginationOptions.map( page => (
        <button
          key={ page }
          type='button'
          className={ `btn ${ page === currentPage ? 'btn-primary' : 'reverted' } page-numbers` }
          onClick={ () => {
            if (page > 0) changePage(page)
          }}
        >
          { page > 0 ? page : '...' }
        </button>
      ))}
    </div>
  )
}
