import React, { useState } from 'react'
import FormInput from '../shared/FormInput'
import SaveFixedButton from '../shared/SaveFixedButton'
import CreateButton from '../shared/CreateButton'

export default function EditAdministrators({ appProps, administrator, administratorRoles, canInviteAdmin, updatePath }) {
  const currentRole = administratorRoles.find(role => role.key === administrator.role)
  const [role, setRole]                   = useState(currentRole)
  const [saveButtonRef, setSaveButtonRef] = useState(null)
  const [emailField, setEmailField]       = useState(administrator.email)

  const handleChange = e => {
    setRole(administratorRoles.find(role => role.id === e.target.value))
    saveButtonRef.setSaveableStatus()
  }

  const save = _e => {
    const fd = new FormData()
    fd.append('administrator_role_id', role.id)
    if (emailField !== administrator.email) {
      fd.append('new_administrator_email', emailField)
    }
    saveButtonRef.setSavingStatus()

    Rails.ajax({
      type: 'PATCH',
      url: updatePath,
      dataType: 'json',
      data: fd,
      success: res => {
        saveButtonRef.setSuccessStatus()
        if (res.redirect_url) {
          location.href = res.redirect_url
        }
      },
      error: res => {
        saveButtonRef.setErrorStatusWithMessage(res.error)
      }
    })
  }

  return (
    <>
      <CreateButton
        pageTitle={ administrator.email }
        breadcrumbs={ appProps.breadcrumbs }
      />

      <div className='card'>
        <div className='flex-box flex-column'>
          <span className='fs-6 fw-semibold form-label mt-3'>
            { Website.translations.columns.email }
          </span>
          { administrator.invitationPending && canInviteAdmin
            ? <input
                type='text'
                className='form-control form-control-solid'
                value={ emailField }
                onChange={ e => { setEmailField(e.target.value); saveButtonRef.setSaveableStatus() } }
              />
            : <div>{ administrator.email }</div>          
          }
        </div>
        <FormInput
          label={ Website.translations.columns.role }
          value={ role.id }
          onChange={ handleChange }
          type='select'
          multiple={ false }
          selectWithDefault={ false }
          selectEntities={ administratorRoles.map(role => ({ ...role, key: role.key.replace('_', ' ')}) ) }
          keyAttr='id'
          valueAttr='id'
          outputAttr='key'
        />
      </div>

      <SaveFixedButton
        onSave={ save }
        entity={ Website.translations.administrator }
        ref={ ref => setSaveButtonRef(ref) }
      />
    </>
  )
}
