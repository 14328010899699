import React, { useState, useEffect, useCallback } from 'react'
import { ChromePicker } from 'react-color'

export default function ColorPicker({ color, setColor }) {
  const [currentColor, setCurrentColor] = useState(color)
  const [showPicker, setShowPicker] = useState(false)

  useEffect( () => {
    setCurrentColor(color)
  }, [color])

  useEffect( () => {
    if (showPicker) {
      document.addEventListener('keydown', handleKeyDown, true)
    }
  }, [showPicker])

  const handleKeyDown = useCallback( (event) => {
    if (event.key === 'Escape') {
      handleClose()
    }
  }, [showPicker])

  function convertFormat(updatedColor) {
    return `rgba(${ updatedColor.rgb.r }, ${ updatedColor.rgb.g }, ${ updatedColor.rgb.b }, ${ updatedColor.rgb.a })`
  }

  function handleClose(updatedColor = color) {
    document.removeEventListener('keydown', handleKeyDown, true)
    setShowPicker(false)
    setCurrentColor(updatedColor)
  }

  function handleClear() {
    setColor('')
    handleClose('')
  }

  function handleSave() {
    setColor(currentColor)
    handleClose(currentColor)
  }

  return (
    <div className='color-picker-wrapper'>
      <div
        className='color-picker-box'
        style={{ backgroundColor: color }}
        onClick={ () => setShowPicker(!showPicker) }
      />

      { showPicker &&
        <div className='color-picker-options-wrapper flex-box flex-column'>
          <ChromePicker
            color={ currentColor }
            onChange={ updatedColor => setCurrentColor(convertFormat(updatedColor)) }
            onChangeComplete={ updatedColor => setCurrentColor(convertFormat(updatedColor)) }
          />

          <div className='flex-box content-space-between'>
            <button
              type='button'
              className='btn btn-primary reverted flex-box content-center flex-1'
              onClick={ handleClear }
            >
              { Website.translations.clear }
            </button>

            <button
              type='button'
              className='btn btn-primary flex-box content-center flex-1'
              onClick={ handleSave }
            >
              { Website.translations.save }
            </button>
          </div>
        </div>
      }
    </div>
  )
}
