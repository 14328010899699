import React, { useState } from 'react'
import SaveFixedButton from '../shared/SaveFixedButton'
import Modal from '../shared/Modal'
import FormInput from '../shared/FormInput'
import CreateButton from '../shared/CreateButton'

export default function EditTax({ appProps, tax, updatePath, deletePath, canDelete }) {
  const [saveButtonRef, setSaveButtonRef]     = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [title, setTitle]                     = useState(tax.title)
  const [rate, setRate]                       = useState(tax.rate)
  const [includedInPrice, setIncludedInPrice] = useState(tax.includedInPrice)

  const selectEntities = [
    { value: 'included', output: Website.translations.included },
    { value: 'not included', output: Website.translations.notIncluded }
  ]

  const handleRateChange = e => {
    let { value } = e.target
    const regex =  /^(0(\.\d{0,2})?|1(\.0{0,2})?)?$/
    if (!regex.test(+value)) {
      return
    }
    setRate(value)
    saveButtonRef.setSaveableStatus()
  }

  const save = _e => {
    const fd = new FormData()
    fd.append('tax[title]', title)
    fd.append('tax[rate]', rate)
    fd.append('tax[included_in_price]', includedInPrice)
    saveButtonRef.setSavingStatus()

    Rails.ajax({
      type: 'PATCH',
      url: updatePath,
      dataType: 'json',
      data: fd,
      success: res => {
        setTitle(res.tax.title)
        setRate(res.tax.rate)
        setIncludedInPrice(res.tax.includedInPrice)
        saveButtonRef.setSuccessStatus()
      },
      error: res => {
        saveButtonRef.setErrorStatusWithMessage(res.error)
      }
    })
  }

  const handleDelete = () => {
    const fd = new FormData()
    fd.append('taxes', [tax.id])

    Rails.ajax({
      type: 'DELETE',
      url: deletePath,
      dataType: 'json',
      data: fd,
      success: res => {
        if (res.redirect_url) {
          location.href = res.redirect_url
        }
      },
    })
  }

  const renderDeleteModal = () => (
    <Modal
      visible={ showDeleteModal }
      closeModal={() => setShowDeleteModal(false)}
      mode="small"
    >
      <h3>
        { Website.translations.areYouSure }
      </h3>
      <div className='flex-box content-end m-t-30'>
        <button className='btn' onClick={() => setShowDeleteModal(false)} >
          { Website.translations.goBack}
        </button>
        <button className='btn btn-primary m-l-10' onClick={handleDelete} >
          { Website.translations.continue}
        </button>
      </div>
    </Modal>
  )

  return (
    <>
      { showDeleteModal && renderDeleteModal() }
      <CreateButton
        pageTitle={ tax.title }
        breadcrumbs={ appProps.breadcrumbs }
      />
      <div className='card'>
        <FormInput
          label={ Website.translations.columns.title }
          value={ title }
          onChange={ e => { saveButtonRef.setSaveableStatus(); setTitle(e.target.value) } }
        />
        <FormInput
          label={ Website.translations.columns.rate}
          value={ rate }
          onChange={ handleRateChange }
        />
        <div className='flex-box flex-row gap-4'>
          <label className='fs-6 fw-semibold form-label mt-3'>
            { Website.translations.columns.includedInPrice }
          </label>
          <input
            type="checkbox"
            checked={ includedInPrice }
            onChange={ _e => { saveButtonRef.setSaveableStatus(); setIncludedInPrice(prevState => !prevState ) } }
          />
        </div>
        { canDelete &&
          <div className='m-t-30 flex-box content-end'>
            <button className='btn btn-primary' onClick={() => setShowDeleteModal(true)} >
              { Website.translations.delete }
            </button>
          </div>
        }
      </div>

      <SaveFixedButton
        onSave={ save }
        entity={ Website.translations.terminal }
        ref={ ref => setSaveButtonRef(ref) }
      />
    </>
  )
}
