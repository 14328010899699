import React, { useState, useEffect } from 'react'
import DynamicEntityIndex from '../shared/DynamicEntityIndex'
import CreateButton from '../shared/CreateButton'
import Modal from '../shared/Modal'
import LanguageHandler from '../shared/LanguageHandler'
import FormInput from '../shared/FormInput'
import SubmitButton from '../shared/SubmitButton'
import ColorPicker from '../shared/ColorPicker'

export default function StockRules({
  appProps, initialRules, initialTotalPages, itemsPath, deletePath,
  disableDelete, canCreate, canEdit, selectedColumns, languages
}) {
  const [rules, setRules] = useState(initialRules)
  const highestThresholdValue = rules.reduce((acc, rule) => {
    if (rule.upperThreshold > acc) {
      acc = rule.upperThreshold
    }

    return acc
  }, 0)
  const [totalPages, setTotalPages] = useState(initialTotalPages)
  const [showModal, setShowModal] = useState(false)
  const [modalMode, setModalMode] = useState('create')
  const [usedLanguages, setUsedLanguages] = useState([languages.find(language => language.code === appProps.currentLocale )])
  const [selectedLanguage, setSelectedLanguage] = useState(usedLanguages[0].code)
  const [translations, setTranslations] = useState({ [selectedLanguage]: { title: '' } })
  const [lowerThreshold, setLowerThreshold] = useState(rules.length === 0 ? 0 : highestThresholdValue + 1)
  const [upperThreshold, setUpperThreshold] = useState(rules.length === 0 ? 0 : highestThresholdValue + 1)
  const [currentRule, setCurrentRule]       = useState(null)
  const [badgeColorCode, setBadgeColorCode] = useState('#FFFFFF')
  const [textColorCode, setTextColorCode]   = useState('#000000')

  const { flashMessage } = appProps

  const actions = []
  if (canEdit) {
    actions.push({ 
      onClick: (e, rule) => { 
        e.preventDefault() 
        setCurrentRule(rule) 
        setValues(rule) 
        setModalMode('edit') 
        setShowModal(true)
      }, 
      icon: 'fa-pencil' 
    })
  }

  useEffect(() => {
    setValues()
  }, [rules])

  const handleTitleChange = e => {
    setTranslations(prevState => ({ ...prevState, [selectedLanguage]: { ...(prevState[selectedLanguage] || {}), title: e.target.value } }))
  }

  const handleLowerThresholdChange = e => {
    let value = Number.isNaN(parseInt(e.target.value.slice(-1))) ? e.target.value.slice(0, -1) : e.target.value
    value = (value < 0 || value.length === 0) ? 0 : parseInt(value)

    if (value > upperThreshold) {
      setUpperThreshold(value.toString())
    }
    setLowerThreshold(value.toString())
  }

  const handleUpperThresholdChange = e => {
    let value = Number.isNaN(parseInt(e.target.value.slice(-1))) ? e.target.value.slice(0, -1) : e.target.value
    value = (value < 0 || value.length === 0) ? 0 : parseInt(value)

    if (value < lowerThreshold) {
      setLowerThreshold(value.toString())
    }
    setUpperThreshold(value.toString())
  }

  const extraRemoveLanguageCallback = locale => {
    setTranslations(prevState => {
      const nextState = { ...prevState }
      nextState[locale]._destroy = true
      
      return nextState
    })
  }

  const setValues = (rule = null) => {
    if (!rule) {
      const highestThresholdValue = rules.reduce((acc, rule) => {
        if (rule.upperThreshold > acc) {
          acc = rule.upperThreshold
        }

        return acc
      }, 0)

      const langs = [languages.find(language => language.code === appProps.currentLocale )]
      setBadgeColorCode('#FFFFFF')
      setTextColorCode('#000000')
      setLowerThreshold(rules.length === 0 ? 0 : highestThresholdValue + 1)
      setUpperThreshold(rules.length === 0 ? 0 : highestThresholdValue + 1)
      setTranslations({ [langs[0].code]: { title: '' } })
      setUsedLanguages(langs)
      setSelectedLanguage(langs[0].code)
    } else {
      const langs = languages.filter(language => rule.translations.find(t => t.locale === language.code))
      setBadgeColorCode(rule.badgeColorCode)
      setTextColorCode(rule.textColorCode)
      setLowerThreshold(rule.lowerThreshold)
      setUpperThreshold(rule.upperThreshold)
      setTranslations(rule.translations.reduce((acc, t) => {
        acc[t.locale] = { title: t.title, id: t.id }
        
        return acc
      }, {}))
      setUsedLanguages(langs)
      setSelectedLanguage(langs[0].code)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    const mode = modalMode

    const fd = new FormData()
    Object.keys(translations).forEach((locale, index) => {
      fd.append(`stock_rule[translations_attributes][${ index }][title]`, translations[locale].title || '')
      fd.append(`stock_rule[translations_attributes][${ index }][locale]`, locale)

      if (translations[locale].id) {
        fd.append(`stock_rule[translations_attributes][${ index }][id]`, translations[locale].id)
      }

      if (translations[locale]._destroy) {
        fd.append(`stock_rule[translations_attributes][${ index }][_destroy]`, translations[locale]._destroy)
      }
    })

    fd.append('stock_rule[lower_threshold]', lowerThreshold)
    fd.append('stock_rule[upper_threshold]', upperThreshold)
    fd.append('stock_rule[badge_color_code]', badgeColorCode)
    fd.append('stock_rule[text_color_code]', textColorCode)

    Rails.ajax({
      type: mode === 'create' ? 'POST' : 'PATCH',
      url: mode === 'create' ? itemsPath : `${ itemsPath }/${ currentRule.apiUid }`,
      dataType: 'json',
      data: fd,
      success: res => {
        if (mode === 'create') {
          setRules(res.items)
          setTotalPages(res.totalPages)
        } else {
          setRules(prevState => {
            const relevantRuleIndex = prevState.findIndex(rule => rule.id === res.stock_rule.id)
            return [
              ...prevState.slice(0, relevantRuleIndex),
              res.stock_rule,
              ...prevState.slice(relevantRuleIndex + 1)
            ]
          })
        }

        setShowModal(false)
        setModalMode('create')
        flashMessage.show(res.success, 'success')
      },
      error: res => {
        flashMessage.show(res.error, 'error')
      }
    })
  }

  function renderRule(rule, columns) {
    return (
      <>
        { columns.map( column => {
          const key = column.key

          return (
            <div key={ column.key } className='item flex-1'>
              {
                ['badgeColorCode', 'textColorCode'].includes(key)
                ? <div className='color-picker-wrapper'><div className='color-picker-box' style={{ backgroundColor: rule[key], cursor: 'auto' }}></div></div>
                : <div>
                    { rule[key] }
                  </div>
              }
            </div>
          )
        })}
      </>
    )
  }

  return (
    <>
      { showModal &&
        <Modal
          visible={ showModal }
          closeModal={() => { setShowModal(false); setValues(); setModalMode('create') } }
          mode="xlarge"
        >
          <LanguageHandler
            languages={ languages }
            usedLanguages={ usedLanguages }
            setUsedLanguages={ setUsedLanguages }
            selectedLanguage={ selectedLanguage }
            setSelectedLanguage={ setSelectedLanguage }
            translatableContent={ [] }
            extraRemoveLanguageCallback={ extraRemoveLanguageCallback }
          />
          <FormInput
            label={ Website.translations.columns.title }
            value={ translations[selectedLanguage]?.title || '' }
            onChange={ handleTitleChange }
          />
          <FormInput
            label={ Website.translations.columns.lowerThreshold }
            value={ lowerThreshold || 0 }
            onChange={ e => { handleLowerThresholdChange(e) } }
            type='number'
          />
          <FormInput
            label={ Website.translations.columns.upperThreshold }
            value={ upperThreshold || 0 }
            onChange={ handleUpperThresholdChange }
            type='number'
          />

          <div className='flex-box flex-column'>
            <label className='fs-6 fw-semibold form-label mt-3'>
              { Website.translations.columns.badgeColorCode }
            </label>
            <ColorPicker
              color= { badgeColorCode || '' }
              setColor={ color => setBadgeColorCode(color) }
            />
          </div>

          <div className='flex-box flex-column'>
            <label className='fs-6 fw-semibold form-label mt-3'>
              { Website.translations.columns.textColorCode }
            </label>
            <ColorPicker
              color= { textColorCode || '' }
              setColor={ color => setTextColorCode(color) }
            />
          </div>

          <div className='flex-box content-end'>
            <SubmitButton
              value={ Website.translations.save }
              className='btn btn-primary'
              onSubmit={ handleSubmit }
            />
          </div>
        </Modal>
      }

      <CreateButton
        pageTitle={ Website.translations.stockRules }
        breadcrumbs={ appProps.breadcrumbs }
        canCreate={ canCreate }
        setShowModal={ setShowModal }
      />

      <DynamicEntityIndex
        appProps={ appProps }
        initialItems={ rules }
        setInitialItems={ setRules }
        initialTotalPages={ totalPages }
        selectAll={ true }
        disableDelete={ disableDelete }
        disableCustomization={ true }
        disableSearch={ true }
        entity={ 'stock_rules' }
        tabs={ [] }
        tableHeadings={ selectedColumns }
        itemRenderer={ renderRule }
        itemsPath={ itemsPath }
        deletePath={ deletePath }
        noResourcesTitle={ Website.translations.noStockRules }
        actions={ actions }
        columnTranslations={ Website.translations.columns }
      />
    </>
  )

}