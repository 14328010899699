import React, { useState } from 'react'
import CreateButton from '../shared/CreateButton'
import DynamicEntityIndex from '../shared/DynamicEntityIndex'
import Modal from '../shared/Modal'
import FormInput from '../shared/FormInput'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'

export default function Index({
  appProps, initialSeasons, initialTotalPages, disableDelete, selectedColumns,
  itemsPath, deletePath, canCreate, canEdit
}) {
  const [seasons, setSeasons] = useState(initialSeasons)
  const [newSeason, setNewSeason] = useState({ title: '', dateFrom: new Date(), dateTo: new Date() })
  const [showModal, setShowModal] = useState(false)

  const { flashMessage } = appProps

  const actions = []
  if (canEdit) {
    actions.push({ url: item => `${ itemsPath }/${ item.apiUid }/edit`, icon: 'fa-pencil' })
  }

  const handleChange = (key, e) => {
    setNewSeason(prevState => ({
      ...prevState,
      [key]: e.target.value
    }))
  }

  const handleSave = () => {
    setShowModal(false)
    const fd = new FormData()
    const { title, dateFrom, dateTo } = newSeason

    fd.append('season[title]', title)
    fd.append('season[month_from]', dateFrom.getMonth() + 1)
    fd.append('season[day_from]', dateFrom.getDate())
    fd.append('season[month_to]', dateTo.getMonth() + 1)
    fd.append('season[day_to]', dateTo.getDate())

    Rails.ajax({
      type: 'POST',
      url: itemsPath,
      dataType: 'json',
      data: fd,
      success: res => {
        setSeasons([...seasons, res.season])
        setNewSeason({ title: '', dateFrom: new Date(), dateTo: new Date() })
        flashMessage.show(res.success, 'success')
      },
      error: res => {
        flashMessage.show(res.error, 'error')
      }
    })
  }

  function renderSeason(season, columns) {
    return (
      <>
      { columns.map( column => {
        const key = column.key
        return (
          <div key={ column.key } className='item flex-1'>
            { season[key] }
          </div>
        )
      })}
      </>
    )
  }

  return (
    <>
      { showModal &&
        <Modal
          visible={ showModal }
          closeModal={() => setShowModal(false)}
          mode="medium"
        >
          <h3>{ Website.translations.createSeason }</h3>
          <FormInput
            label={ Website.translations.seasonName }
            value={ newSeason.title }
            onChange={ e => handleChange('title', e) }
          />

          <div className='flex-box flex-column m-t-30'>
            <label className='fs-6 fw-semibold form-label mt-3'>
              { Website.translations.timePeriods }
            </label>
            <div className='flex-box content-space-between' style={{ width: '50%' }}>
              <MuiPickersUtilsProvider
                utils={ MomentUtils }
                locale={ appProps.currentLocale }
              >
                <DatePicker
                  label={ Website.translations.from }
                  value={ newSeason.dateFrom }
                  onChange={ newValue => handleChange('dateFrom', { target: { value: newValue.toDate() }})}
                />

                <DatePicker
                  label={ Website.translations.to }
                  value={ newSeason.dateTo }
                  onChange={ newValue => handleChange('dateTo', { target: { value: newValue.toDate() }})}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className='flex-box items-center m-t-30 content-end'>
            <button className="btn" onClick={ () => setShowModal(false) }>
              { Website.translations.closeAction }
            </button>
            <button className="btn btn-primary" onClick={ handleSave }>
              { Website.translations.save }
            </button>
          </div>
        </Modal>
      }

      <CreateButton
        pageTitle={ Website.translations.seasons }
        breadcrumbs={ appProps.breadcrumbs }
        canCreate={ canCreate }
        setShowModal={ setShowModal }
      />

      <DynamicEntityIndex
        appProps={ appProps }
        initialItems={ seasons }
        setInitialItems={ setSeasons }
        initialTotalPages={ initialTotalPages }
        selectAll={ true }
        disableCustomization={ true }
        disableDelete={ disableDelete }
        entity={ "seasons" }
        tabs={ [] }
        tableHeadings={ selectedColumns }
        itemRenderer={ renderSeason }
        itemsPath={ itemsPath }
        deletePath={ deletePath }
        noResourcesTitle={ Website.translations.noSeasons }
        actions={ actions }
        columnTranslations={ Website.translations.columns }
      />
    </>
  )
}
