import React from 'react'
import { MenuItem, Select } from '@material-ui/core'

export default function LanguageSelect({ usedLanguages, onChange, currentValue, copyContent }){
  return (
    <div className='input-field m-r-10'>
      <Select
        MenuProps={{ style: { zIndex: 99999 } }}
        onChange={ onChange }
        value={ copyContent ? 'empty' : currentValue }
      >
        { copyContent &&
          <MenuItem value={ 'empty' }>
            <i className='fa-solid fa-copy m-r-5'/>
            { Website.translations.copyContent }
          </MenuItem>
        }

        { usedLanguages.map((language, index) => (
          <MenuItem value={ language.code } key={ index }>
            <div className='flex-box'>
              { language.presentation }
            </div>
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}