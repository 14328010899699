import React, { useState, useRef } from 'react'
import ModalAlert from './ModalAlert'

export default function DeleteSelected({
  appProps, entity, selectedItems, deletePath, onDelete, customDeleteAlert,
  onError
}) {
  const [showAlert, setShowAlert] = useState(false)

  const { flashMessage } = appProps

  function onCancel() {
    setShowAlert(false)
  }

  function deleteItems() {
    setShowAlert(false)

    let fd = new FormData()
    selectedItems.forEach( item => fd.append(`${ entity }[]`, item ))

    Rails.ajax({
      type: 'DELETE',
      url: deletePath,
      data: fd,
      dataType: 'json',
      success: res => {
        flashMessage.show(Website.translations.deletionStarted, 'success')
        setTimeout(onDelete, 1500)
      },
      error: res => {
        if (onError) {
          onError(res)

        } else {
          flashMessage.show(res.error, 'error')
          setTimeout( () => {
            window.location.reload()
          }, 1500)
        }
      }
    })
  }

  return (
    <div className='m-l-10'>
      <button
        className='btn btn-primary'
        onClick={ () => setShowAlert(true) }
      >
        { Website.translations.deleteSelected }
      </button>

      { showAlert && customDeleteAlert && customDeleteAlert(selectedItems, deleteItems, onCancel) }
      { showAlert && !customDeleteAlert &&
        <ModalAlert
          alert={ Website.translations.deleteAlert }
          onSelect={ deleteItems }
          onClose={ onCancel }
        />
      }
    </div>
  )
}
